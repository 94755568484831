import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Ordering(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Ordering</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/londis")}>
          <img src={health} alt="londis" />
          <div className="completion">
            <h2>Londis</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/booker")}>
          <img src={health} alt="booker" />
          <div className="completion">
            <h2>Booker</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/costwald")}>
          <img src={health} alt="costwald" />
          <div className="completion">
            <h2>Costwald</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/vapes")}>
          <img src={health} alt="vapes" />
          <div className="completion">
            <h2>Vapes</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/cumbernauld-cakes")}>
          <img src={health} alt="cumbernauld cakes" />
          <div className="completion">
            <h2>Cumbernauld Cakes</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/imported-goods")}>
          <img src={health} alt="imported goods" />
          <div className="completion">
            <h2>Imported Goods</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/packaging")}>
          <img src={health} alt="packaging/consumbales" />
          <div className="completion">
            <h2>Packaging / Consumables</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/dessert-bar")}>
          <img src={health} alt="dessert bar" />
          <div className="completion">
            <h2>Dessert Bar</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/hoagies")}>
          <img src={health} alt="hoagies" />
          <div className="completion">
            <h2>Hoagies</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/ordering/internal")}>
          <img src={health} alt="hoagies" />
          <div className="completion">
            <h2>Internal Orders</h2>
          </div>
        </div>

      </div>
      <Footer />
    </main>
  );
}
