import Header from "../../Header";
import "../module.scss";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function FuelHome(props){
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>FUEL</h3>
      <div className="modules">
        <div className="module tills" onClick={() => navigate("")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/fuel_cards.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Fuel Cards</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/key-fuel")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/key-cards.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Keyfuel Cards</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/authorisation")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/auth.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Fuel Authorisation</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/suspicious-transaction")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/suspicious.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Suspicious Fuel Transactions</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/drive-off")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/drive-off.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Drive Off Prevention</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/nmop")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/nmop.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>No Means of Payment Procedure</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/tanks")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/tank_layout.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Tank Layout &amp; Wet Stocks</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/ullage")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/VOLUME.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Capacity / Volume</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/pump-stop")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/electric_pump_stop.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Electrical Pump Stop</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/fuel/spillages")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/fuel_spillages.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Fuel Spillages</h2>
          </div>
        </div>



      </div>
      <Footer />
    </main>
  );
}