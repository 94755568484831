import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function BakingHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Baking</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/baking/waffles")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Waffles</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/crepes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Crepes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/pancakes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Pancakes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/fudge-puppies")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Fudge Puppies</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/hot-cakes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Hot Cakes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/hot-food")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Hot Food</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/cookie-dough")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cookie Dough</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/baking/hot-drinks")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Hot Drinks</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/baking/iced-drinks")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Iced Drinks</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/baking/frappuccino")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Frappuccino</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/baking/candyfloss")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Candyfloss</h2>
            </div>
          </div> 
        </div>
        <Footer />
      </main>
    );
}