import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";



export default function DateMaintenance(props) {
    const navigate = useNavigate();
 
  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Date Maintenance (Retail)</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/retail/date-maintenance/rotation")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/RETURNS.png" alt="date rotation" />
          <div className="completion">
            <h2>Date Rotation</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/date-maintenance/lists")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/critical.png" alt="critical lists" />
          <div className="completion">
            <h2>Critical Lists</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/date-maintenance/wastage")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/general.png" alt="wastage" />
          <div className="completion">
            <h2>Wastage</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/date-maintenance/reductions")}>
          <img src={health} alt="reductions" />
          <div className="completion">
            <h2>Reductions</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
