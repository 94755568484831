import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function DecoratingHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Decorating</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/churros")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Churros</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/waffles")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Waffles</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/fudge-puppies")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Fudge Puppies</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/pancakes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Pancakes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/crepes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Crepes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/cookie-dough")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cookie Dough</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/decorating/cake-slices")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cake Slices</h2>
            </div>
          </div> 
        </div>
        <Footer />
      </main>
    );

}