import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function CustomerService(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Customer Service Retail")
  const [child, setChild] = useState("DEALING WITH CUSTOMERS")

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex(x => x.name === parent)
          const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);



  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Customer service completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }


  return (
    <section className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Customer Service</h3>
      {!completed ? (
        <>
              {intro ? (
        <div className="unit">
          <p>
            The Company has Customer Service Policy in which all employees must
            adhere to at all times. This company policy outlines our standards
            when it comes to customer service. We are a customer service-based
            company and this must be at the front of all employees’ thoughts.
            Our overall aim to for the business to be ambitious and reputable
            Petrol Station Retailer. We are responsible for providing
            outstanding customer service, ensuring our customers shopping needs
            are exceeded and building lasting relationships. Our ultimate goals
            are to always ensure our customers are provided with a world class
            shopping experience and to continue to grow at an exceptional rate.
          </p>
        </div>
      ) : (
        <></>
      )}
      {first ? (
        <div className="unit">
          <p>
            This policy applies to all of our employees. If you are a site-based
            staff member this also includes when working out on the shop floor.
            When not on shift you are still a representation for the store and
            the company
          </p>
        </div>
      ) : (
        <></>
      )}
      {second ? (
        <div className="unit">
          <p>The following rules always apply:</p>
          <ul>
            <li>Do not ignore the customer.</li>
            <li>
              If you are not able to serve a customer straight away then
              apologise and inform the customer you will be with them as soon as
              possible.
            </li>
            <li>
              Adopt the 2-metre rule – this means that if a customer is two
              metres away from you and you make eye contact then you must
              acknowledge them. This can simply be a smile or ask them if they
              need any assistance.
            </li>
            <li>
              If you are standing behind the till point and not a cashier then
              expect the customer to want to be served. Acknowledge them and
              inform them that the next cashier will be with them as soon as
              possible. As a result, do not stand behind the till point if not
              serving/ being trained.
            </li>
            <li>
              If a customer enquires about a product, the member of staff should
              take them and show them where the item is. They should then make
              sure that the customer is full satisfied by asking ‘Is there
              anything else I can help you with?’
            </li>
            <li>
              If an item appears to be out of stock, you should always check the
              back area before telling the customer this item is not available
              at this time. This should then add to the stock list immediately.
              Do not delay this as this is when employee can forget to double
              back and add this to the shopping list.
            </li>
            <li>The customer should have your full attention at all times.</li>
            <li>
              Employees should not be chatting amongst themselves whilst a
              customer is in the shop/ at the till point. Even if you are not
              serving.
            </li>
            <li>
              No member of staff should be behind the till area whilst not on
              shift
            </li>
            <li>
              No food or drink should be consumed at the till point or on the
              shop floor.
            </li>
            <li>
              All food and drink should be consumed away from all customer
              areas. This includes bottles or water.
            </li>
            <li>
              Staff should not be reading papers or magazines at the counter or
              on the shop floor.
            </li>
            <li>
              The site should be clean and kept to a high standard. No mess
              should be left around the till point as this is what customer look
              at the most whilst waiting for their transaction to be processed.
            </li>
            <li>
              All media enquiries should only be dealt with by management or
              head office. Therefore, if you are asked then you should politely
              inform them that this a request you can pass on, take their
              details accordingly.
            </li>
            <li>
              All customers should be greeted when the enter the shop/ arrive at
              the time point.
            </li>
            <li>
              All customers must receive a farewell message. This can simply be
              ‘Goodbye have a nice day’.
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!second ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core/customer-service")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>

        </>
      ):(
        <div className="completed">
        <img src={complete} />
        <h2>This unit has been completed.</h2>
        <p>Do you want to do it again?</p>
        <div className="moveButtons" id="confetti">
          <Button
            className="moveButton"
            onClick={() => setCompleted(false)}
            variant="outlined"
            color="error"
          >
            Yes
          </Button>
          <Button
            className="moveButton"
            onClick={() => navigate("/core/customer-service")}
            variant="outlined"
            color="success"
          >
            No
          </Button>
        </div>
      </div>

      )}

      <Footer />
    </section>
  );
}
