import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import axios from "axios";
import ReportIcon from "@mui/icons-material/Report";
import GrassIcon from "@mui/icons-material/Grass";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import {
  GlutenIcon,
  FishIcon,
  CrustaceanIcon,
  EggIcon,
  SoyaIcon,
  PeanutIcon,
  MilkIcon,
  NutsIcon,
  CeleryIcon,
  MustardIcon,
  SesameIcon,
  SulphiteIcon,
  LupinIcon,
  MolluscIcon,
} from "react-allergens";
import AllergenQuiz from "../../../quizes/allergen/AllergenQuiz";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Allergens(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("H&S");
  const [child, setChild] = useState("ALLERGENS");

  const [passed, setPassed] = useState(false);

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true){
      setFifth(false)
      setSixth(true)
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true){
      setSixth(false)
      setFifth(true)
    }
  }

  function handlePassed() {
    setPassed(true);
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Allergen Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Allergens</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The purpose of this training is to enable your customers to make
                informed decisions about the food and drink they buy and consume
                by way of the information provided to them by you. The
                principles that you apply to food is the same for the drinks
                that you sell. Information should be available to your customers
                should they request it, by using a sign that can be clearly seen
                at the point where food and drink is ordered.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <div className="row">
                <ReportIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
                <p>
                  2% of the UK adult population and 5-8% of children in the UK
                  have a food allergy; this figure does not include those with
                  food intolerances. In addition, it is estimated that 1 in 100
                  people have coeliac disease, an auto-immune condition which
                  causes damage to the gut lining when gluten is consumed.
                </p>
              </div>
              <div className="row">
                <ReportIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
                <p>
                  An allergic reaction can be produced by a tiny amount of a
                  food ingredient that a person is sensitive to (for example a
                  teaspoon of milk powder, a fragment of peanut or just one or
                  two sesame seeds).
                </p>
              </div>
              <div className="row">
                <ReportIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
                <p>
                  In the UK, around ten people die from allergic reactions to
                  food every year.
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>What is a food allergy?</h4>
              <p>
                Food that is safe for one person to eat can be hazardous to
                somebody else. Some people experience allergic reactions to
                certain foods.
              </p>
              <p>
                This can apply to drinks just as it does to food. Drinks can
                sometimes be forgotten and create a risk to the management of
                allergens.
              </p>
              <p>
                It is important that everyone who prepares or handles food and
                drinks, in any way, is fully aware of the foods that can trigger
                reactions to ensure customers do not eat a food or ingredient
                that they are trying to avoid or will cause them harm.
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>
                The incident of allergies of all types has significantly
                increased in recent years. It is believed that this is due to a
                combination of factors:
              </p>
              <div className="row">
                <PeopleAltIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
                <p>Genetics</p>
              </div>
              <div className="row">
                <GrassIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Environmental</p>
              </div>
              <div className="row">
                <FastfoodIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Dietary</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <p>
                The Food Safety Act and the Food Information for Consumers
                Regulation (FIC) set out requirements to make food safe and
                support consumers with food allergies so they can make informed
                choices about what to eat.
              </p>
              <p>
                Businesses must advise consumers correctly. Team members need to
                be able to answer questions regarding ‘allergenic ingredients’
                contained in the foods and drinks they serve or sell.
              </p>
              <p>
                It is important that the customer or guest always makes the
                final decision on what they order or buy to eat or drink.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <h4>
                There is 14 allergens that must be labelled or indicated as
                being present in foods:
              </h4>
              <div className="row" style={{marginBottom: "-10%"}}>
                <GlutenIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }} >Cereals containing gluten</p>
              </div>
              <div className="row"  style={{marginBottom: "-10%"}}>
                <EggIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Egg</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <CrustaceanIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Crustaceans</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <FishIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Fish</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <PeanutIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Peanuts</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <SoyaIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Soy/SoyBean</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <MilkIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Milk</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <NutsIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Nuts</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <CeleryIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Celery</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <MustardIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Mustard</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <SesameIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Sesame</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <SulphiteIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Sulphur Dioxide/Sulphites</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <LupinIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Lupin</p>
              </div>

              <div className="row"  style={{marginBottom: "-10%"}}>
                <MolluscIcon
                  className="allergens"
                  innerColor="#fff"
                  outerColor="#bf1a1a"
                  width="50px"
                />
                <p style={{ marginTop: "2vh" }}>Molluscs</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <>
            <AllergenQuiz handlePassed={handlePassed}/>
            </>
          ):(
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!sixth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      {passed ? (
                        <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit 
                      </Button>
                      ) : (
                        <></>
                      )}
                      
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core/hs")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/hs")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
