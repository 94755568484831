import Header from "../../Header";
import "../module.scss";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function TillsHome(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>TILLS</h3>
      <div className="modules">
        <div className="module tills" onClick={() => navigate("/core/tills/float")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/FLOAT.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Float Counting</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/core/tills/open-till")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/opening_till.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Opening Till</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/core/tills/no-scans")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/no_scans.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>No Scans</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/core/tills/refunds")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/RETURNS.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Refunds</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/core/tills/scanning")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/SCANNING.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Scaning</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/core/tills/closing-till")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/closing.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Closing Till</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}