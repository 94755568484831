import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Knife(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("H&S");
  const [child, setChild] = useState("KNIFE SAFETY");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Knife Policy`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
        console.log(res);
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
    // console.log("clicked")
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Knife Safety</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The following document forms as part of our current Health,
                Safety, Welfare and Hygiene Policy. Upon signing below, you are
                demonstrating your knowledge of what your responsibilities are
                as an employee when it comes to the use, maintenance, repair and
                safety of knives in the workplace. Safe Systems of Work will be
                rigid and adhered to by the company, its employees and others
                under its control. Safety knives will be provided free of charge
                to all staff required to use such knives and the use of all
                other knives is banned in the workplace. Maintenance and
                replacement of safety knives, etc. will be the responsibility of
                the Managing Director. Employees and others under the Company’s
                control have a legal obligation to report any faults in
                equipment or breaches in health and safety arrangements to the
                HR Team.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                The Company will train all staff in the use of safety knives and
                the arrangements for replacement or repair. The site Manager
                will undertake the training and ensure that all staff are
                adequately trained and competent before using safety knives and
                will keep documented records of all training received.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!first ? (
              <>
                <Button
                  onClick={() => prev()}
                  variant="outlined"
                  color="error"
                  className="moveButton"
                >
                  Prev
                </Button>
                <Button
                  className="moveButton"
                  onClick={() => next()}
                  variant="outlined"
                  color="error"
                >
                  Next
                </Button>
              </>
            ) : (
              <div className="unit">
                <div className="moveButtons" id="confetti">
                  <Button
                    className="moveButton"
                    onClick={() => prev()}
                    variant="outlined"
                    color="error"
                  >
                    Prev
                  </Button>
                  <Button
                    className="moveButton"
                    onClick={() => handleSubmit()}
                    // style={{ backgroundColor: "#03A64A" }}
                    variant="outlined"
                    color="success"
                  >
                    Submit
                  </Button>
                </div>
                {unit ? (
                  <>
                    <br />
                    <div className="unit">
                      <h6>Congratulations! you finished this module</h6>
                      <p>Please let your manager know to sign the policy with them.</p>
                      <Button
                        onClick={() => navigate("/core/hs")}
                        variant="contained"
                        color="success"
                        sx={{alignSelf: "center"}}
                      >
                        Go back to units
                      </Button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/hs")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </section>
  );
}
