import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../images/RT_TRAINING.png'
import '../styles/login.scss'
import * as Sentry from "@sentry/react";

export default function Login() {
  const [staffId, setStaffId] = useState("");
  const navigate = useNavigate();
  const [logFail, setLogFail] = useState(false)

  const submitId = async () => {
    const transaction = Sentry.startTransaction({
      name: `Login`,
      sampled: true,
    });
    axios.get(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${staffId.toString()}`).then((res) => {
      if (res.data) {
        setStaffId("");
        localStorage.setItem("staff", JSON.stringify(res.data));
        navigate("/home")
      } else {
        setLogFail(true)
        setStaffId("");
        setTimeout(() => {
          setLogFail(false)
        }, 2500)
      }
    }).catch((err) => {
      Sentry.captureException(err)
    });
    transaction.finish();
  };

  const handleEnter = (e) => {
    if (e.keyCode === 13) {
      submitId();
    }
  };

  const guestAccess = () => {
    localStorage.removeItem('staff');
    navigate("/home")
  }

  return (
    <section id="login">
      <img src={logo} className="logo" />
      <h2>Staff login</h2>
      <div className="box">
        {logFail ? (
          <h3>No matching employee found. Please try again</h3>
        ) : (
          <></>
        )}
        <input placeholder="Staff ID..." type="text" onChange={(e) => { setStaffId(e.target.value) }} value={staffId} onKeyDown={handleEnter} />
        <button style={{ border: "2px solid black" }} onClick={() => { submitId() }}>STAFF ACCESS</button>
      </div>
      <button style={{ border: "2px solid black" }} onClick={() => { guestAccess() }} id="guest">GUEST ACCESS</button>
      </section>
  )
}