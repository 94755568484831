import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import Unicorn from "./images/crepe-unicorn.webp";
import Mermaid from "./images/crepe-mermaid.webp";
import Oreo from "./images/crepe-oreo.webp";
import Brownie from "./images/crepe-brownie.webp";
import ChocHeaven from "./images/crepe-chocHeaven.webp";
import WhiteMess from "./images/crepe-whiteMess.webp";
import Peanut from "./images/crepe-Poverload.webp";
import Banoffee from "./images/crepe-banoffee.webp";
import Ferrero from "./images/crepe-ambasador.webp";
import Nutella from "./images/crepe-nutellaLover.webp";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function DecoCrepes(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false);
  const [eighth, setEighth] = useState(false);
  const [ninth, setNinth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Baking/Making Products");
  const [child, setChild] = useState("CREPE");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    } else if (sixth === true) {
      setSixth(false);
      setSeventh(true);
    } else if (seventh === true) {
      setSeventh(false);
      setEighth(true);
    } else if (eighth === true) {
      setEighth(false);
      setNinth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    } else if (seventh === true) {
      setSixth(true);
      setSeventh(false);
    } else if (eighth === true) {
      setSeventh(true);
      setEighth(false);
    } else if (ninth === true) {
      setEighth(true);
      setNinth(false);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Crepe making completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Crepes</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>Unicorn</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot White Chocolate Sauce</li>
                <li>Fizzers</li>
                <li>Parma Violets</li>
                <li>Unicorn Sprinkles</li>
                <li>Pink &amp; Blue Sherbet</li>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Icing Sugar</li>
                <li>Candy Floss</li>
                <li>Unicorn Horn</li>
                <li>Whipped Cream</li>
                <li>Cotton Candy Ice Cream</li>
                <li>Wafer</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>white chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  1 packet of <b>fizzers</b> and <b>parma violets</b> sprinkled
                  along with <b>unicorn sprinkles</b> and{" "}
                  <b>pink &amp; blue sherbet</b>
                </li>
                <li>
                  Drizzle <b>milk chocolate sauce</b> on to crepe (x3)
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
                <li>
                  Place a piece of <b>pink candy floss</b> in middle of crepe
                </li>
                <li>
                  Place <b>unicorn horn</b> in centre of candy floss
                </li>
                <li>
                  Spray <b>whipped cream</b> into side tub
                </li>
                <li>
                  Serve with a scoop of <b>cotton candy ice cream</b> in white
                  jar topped with a <b>wafer</b>
                </li>
              </ol>
              <img
                src={Unicorn}
                alt="unicorn waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>Mermaid</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Milk Chocolate Sauce</li>
                <li>White Chocolate Sauce</li>
                <li>Marshmallow</li>
                <li>Smarties</li>
                <li>Rainbow Sprinkles</li>
                <li>Pink &amp; Blue Sherbet</li>
                <li>Mermaid Tail</li>
                <li>Icing Sugar</li>
                <li>Candy Floss</li>
                <li>Whipped Cream</li>
                <li>Cotton Candy Ice Cream</li>
                <li>Wafer</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>milk chocolate sauce</b> on greaseproof paper
                </li>

                <li>
                  Drizzle <b>white chocolate sauce</b> on to crepe (x3)
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
                <li>
                  Place <b>pink candy floss</b> in centre of crepe
                </li>
                <li>
                  Sprinkle marshmallow, smarties, rainbow sprinkles and blue
                  &amp; pink sherbet over top of crepe
                </li>
                <li>
                  Place <b>mermaid tail</b> in centre of candy floss
                </li>
                <li>
                  Spray <b>whipped cream</b> into side tub
                </li>
                <li>
                  Served with a scoop of <b>cotton candy ice cream</b> in white
                  jar topped with a <b>wafer</b>
                </li>
              </ol>
              <img
                src={Mermaid}
                alt="mermaid waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>Oreo</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Hot White Chocolate Sauce</li>
                <li>Milk Chocolate Flakes</li>
                <li>4 Oreos</li>
                <li>Icing Sugar</li>
                <li>Whipped Cream</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Crush 3 <b>oreos</b> and sprinkle over the top of the crepe
                </li>
                <li>
                  Place 1 <b>oreo</b> on top of the crepe
                </li>
                <li>
                  Drizzle <b>hot white chocolate sauce</b> (x3)
                </li>
                <li>
                  Sprinkle <b>milk chocolate flakes</b> over top
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
                <li>
                  Spray <b>whipped cream</b> into side tub
                </li>
                <li>
                  Sprinkle more crushed <b>oreo</b> over the top of the plate
                </li>
              </ol>
              <img src={Oreo} alt="oreo waffles" className="tubbbees-img" />
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>Brownie</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Brownie</li>
                <li>Icing Sugar</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Cut <b>brownie</b> in to 9 pieces
                </li>
                <li>
                  Place 2 in each quarter section and 1 in the centre on top of
                  the crepe
                </li>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> over the crepe (x3)
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
              </ol>
              <img
                src={Brownie}
                alt="brownie waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>Chocolate Heaven</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Hot White Chocolate Sauce</li>
                <li>Smarties</li>
                <li>Icing Sugar</li>
                <li>Milk Chocolate Curls</li>
                <li>White Chocolate Curls</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Sprinkle <b>smarties</b> over the top of crepe
                </li>
                <li>
                  Drizzle <b>1 hot white</b> and{" "}
                  <b>2 hot milk chocolate sauce</b> over the crepe
                </li>
                <li>
                  <b>White and milk chocolate curls</b> sprinkled over the top
                  of crepe
                </li>
                <li>
                  <b>Icing sugar</b> speckled on top
                </li>
              </ol>
              <img
                src={ChocHeaven}
                alt="chocolate heaven waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <h4>White Mess</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Hot White Chocolate Sauce</li>
                <li>Full Bueno</li>
                <li>Icing Sugar</li>
                <li>White Chocolate Curls</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Break up full <b>Bueno</b> sections and spread over crepe
                </li>
                <li>
                  Drizzle <b>hot white chocolate sauce</b> over the crepe
                </li>
                <li>
                  Sprinkle <b>white chocolate curls</b>
                </li>
                <li>
                  Speckle <b>icing sugar</b> over top
                </li>
              </ol>
              <img
                src={WhiteMess}
                alt="white mess waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <div className="unit">
              <h4>Peanut</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Hot White Chocolate Sauce</li>
                <li>Full Bueno</li>
                <li>Icing Sugar</li>
                <li>White Chocolate Curls</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Break up full <b>Bueno</b> sections and spread over crepe
                </li>
                <li>
                  Drizzle <b>hot white chocolate sauce</b> over the crepe
                </li>
                <li>
                  Sprinkle <b>white chocolate curls</b>
                </li>
                <li>
                  Speckle <b>icing sugar</b> over top
                </li>
              </ol>
              <img src={Peanut} alt="peanut waffles" className="tubbbees-img" />
            </div>
          ) : (
            <></>
          )}
          {seventh ? (
            <div className="unit">
              <h4>Banoffee</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Toffee Sauce</li>
                <li>Banana</li>
                <li>Toffee Pieces</li>
                <li>Icing Sugar</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>toffee sauce</b> on greaseproof paper
                </li>
                <li>
                  <b>4 slices of banana</b> in each quarter section and 1 in the
                  centre
                </li>
                <li>
                  <b>Toffee sauce</b> drizzled on top (x3)
                </li>
                <li>
                  Sprinkle <b>toffee pieces</b>
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
              </ol>
              <img
                src={Banoffee}
                alt="banoffee waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {eighth ? (
            <div className="unit">
              <h4>Ferrero</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>4 Ferrero</li>
                <li>Hot Nutella</li>
                <li>Nut Pieces</li>
                <li>Icing Sugar</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Place 1 crushed <b>Ferrero</b> in each section (x4)
                </li>
                <li>
                  Drizzle <b>Hot Nutella</b> over top of crepe
                </li>
                <li>
                  Sprinkle <b>nut pieces</b> over top
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
              </ol>
              <img
                src={Ferrero}
                alt="ferrero waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          {ninth ? (
            <div className="unit">
              <h4>Nutella</h4>
              <p>Ingredients:</p>
              <ul>
                <li>Hot Milk Chocolate Sauce</li>
                <li>Strawberries</li>
                <li>Hot Nutella</li>
                <li>Icing Sugar</li>
              </ul>
              <p>Method:</p>
              <ol>
                <li>
                  Drizzle <b>hot milk chocolate sauce</b> on greaseproof paper
                </li>
                <li>
                  Place sliced <b>strawberries</b> on top of crepe
                </li>
                <li>
                  Drizzle <b>Hot Nutella</b> over top of crepe
                </li>
                <li>
                  Speckle <b>icing sugar</b>
                </li>
              </ol>
              <img
                src={Nutella}
                alt="nutella waffles"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!ninth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/tubbees/baking")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/tubbees/baking")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
