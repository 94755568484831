import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Systems(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Systems</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/systems/cctv")}>
          <img src={health} alt="cctv" />
          <div className="completion">
            <h2>CCTV</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/systems/rota")}>
          <img src={health} alt="rota" />
          <div className="completion">
            <h2>Rota</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/systems/alcohol")}>
          <img src={health} alt="alcohol" />
          <div className="completion">
            <h2>Alcohol</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/systems/next-cloud")}>
          <img src={health} alt="next cloud" />
          <div className="completion">
            <h2>Next Cloud</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/systems/staff-training")}>
          <img src={health} alt="staff training" />
          <div className="completion">
            <h2>Staff Training</h2>
          </div>
        </div><div className="module healthAndSafety" onClick={() => navigate("/manager/systems/paypoint")}>
          <img src={health} alt="paypoint" />
          <div className="completion">
            <h2>Paypoint</h2>
          </div>
        </div><div className="module healthAndSafety" onClick={() => navigate("/manager/systems/lottery")}>
          <img src={health} alt="national lottery" />
          <div className="completion">
            <h2>National Lottery</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
