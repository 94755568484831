import { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/home.scss";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import axios from "axios";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import SendIcon from "@mui/icons-material/Send";

const emailStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "30vw",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
  "@media(max-width: 600px)": {
    width: "80vw",
    height: "40vh",
  },
};

export default function Home(props) {
  const navigate = useNavigate();
  const [staffFound, setStaffFound] = useState(localStorage.getItem("staff"));
  const [role, setRole] = useState("");
  const [site, setSite] = useState("");
  const [staffId, setStaffId] = useState("");
  const [reload, setReload] = useState(false);
  const [email, setEmail] = useState("");
  const [emailAlert, setEmailAlert] = useState(false);
  const [message, setMessage] = useState("");
  const [openEmail, setOpenEmail] = useState(false);
  const handleOpenEmail = () => setOpenEmail(true);
  const handleCloseEmail = () => {
    setOpenEmail(false);
  };

  useEffect(() => {
    var userId = "guest";
    let no = Math.random();
    if (staffFound) {
      var data = JSON.parse(staffFound);
      userId = data.staffTrainingId;
      setRole(data.role);
      setSite(data.site);
      setStaffId(data.staffTrainingId);
      if (!data.email) {
        handleOpenEmail();
      }
    }
    props.id(`${userId} - ${no}`);
  }, []);

  const rel = () => {
    setReload(!reload);
  };

  const addEmail = () => {
    var emailUpdate = {
      staffTrainingId: staffId,
      email: email,
    };
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      axios
        .post(
          "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/updateEmail",
          emailUpdate
        )
        .then((res) => {
          handleCloseEmail();
        })
        .catch((err) => {
          console.log(err);
          setMessage("Could not update, please try again");
          setEmailAlert(true);
          setTimeout(() => {
            setEmailAlert(false);
          }, 2500);
        });
    } else {
      setMessage("Email does not have the right format, please try again");
      setEmailAlert(true);
      document.querySelector("input").focus();
      setTimeout(() => {
        setEmailAlert(false);
      }, 2500);
    }
  };

  return (
    <main className="main">
      <Header stop={props.stop} />
      <div className="trainingModules">
        <div className="module">
          <h1>Modules</h1>
        </div>
        {staffFound ? (
          <>
            <div
              className="module"
              onClick={() => navigate("/core")}
              id="core"
            ></div>
            {role == "IT" || site == "Head Office" ? (
              <div
                className="module"
                onClick={() => navigate("/nitro")}
                id="nitro"
              ></div>
            ) : (
              <>
                <div
                  className="module"
                  onClick={() => navigate("/alcohol")}
                  id="alcohol"
                ></div>
                <div
                  className="module"
                  id="retail"
                  onClick={() => navigate("/retail")}
                ></div>
                <div
                  className="module"
                  onClick={() => navigate("/fuel")}
                  id="fuel"
                ></div>
                <div
                  className="module"
                  onClick={() => navigate("/it")}
                  id="nitro"
                ></div>
                <div
                  className="module"
                  id="tubbees"
                  onClick={() => navigate("/tubbees")}
                ></div>
                <div
                  className="module"
                  id="hoagies"
                  onClick={() => navigate("/hoagies")}
                ></div>

                <div className="module" id="subway"></div>
              </>
            )}
            {role === "Manager" ? (
              <div
                className="module"
                onClick={() => navigate("/manager")}
                id="manager"
              ></div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <>
            <div className="module" id="core"></div>
            <div className="module">
              <h2>tills</h2>
              <p>- coming soon -</p>
            </div>
          </>
        )}
      </div>
      <Modal
        open={openEmail}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={emailStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h1 style={{ color: "#000" }}>Add Email</h1>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <Stack spacing={3} justifyContent="center" alignItems="center">
              {emailAlert ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {message}
                </Alert>
              ) : (
                <></>
              )}
              <h2>Please type email:</h2>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@gmail.com"
                style={{ width: "80%" }}
              />
              <Button
                variant="outlined"
                color="success"
                onClick={() => addEmail()}
                sx={{ marginTop: "3%" }}
                endIcon={<SendIcon />}
              >
                send
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>

      <Footer />
    </main>
  );
}
