import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function IceCreamHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Ice Cream / Smoothies</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies/scooping")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Scooping</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies/sundaes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Sundaes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies/milkshakes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Milkshakes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies/smoothies")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Smoothies</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies/freakshakes")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>FreakShakes</h2>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );

}