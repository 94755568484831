import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function SlushHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Slush</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/slush/machines")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Turning Machines On</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/making-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Making Mix</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/drip-trays")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Drip Trays</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/cleaning-slush")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cleaning Slushes</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/top-up-slush")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Slush Top Up</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/cooling-machines")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cooling Machines</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/slush/de-icing")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>De-Icing</h2>
            </div>
          </div> 
  
        </div>
        <Footer />
      </main>
    );
  
}