import {useState} from "react";
import '../styles/alcohol.css'

export default function AlcoholReview(props) {
    const [showAnswer,setShowAnswer]=useState(false)

    return(
        <div className="reviewBox">
            <p style={{backgroundColor:props.colour}} onClick={()=>setShowAnswer(!showAnswer)}>{props.question}</p>
            {showAnswer?<><span><b>Your Answer: </b>{props.answer}</span>{props.colour==="red"?<><hr/><span><b>Correct Answer:</b> {props.correct}</span></>:<></>}</>:<></>}
        </div>
    )

}