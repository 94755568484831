import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function VapesPolicy(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Paypoint")
    const [child, setChild] = useState("GENERAL POLICY")

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
          });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
            transaction.finish();
    }, []);


    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Paypoint General Policy`,
            sampled: true,
          });
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
            transaction.finish();
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop} />
            <h3>General PayPoint Policy</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <p>
                                The Company has a strict Pay Point Policy in which all employees must adhere to at all times. This company
                                policy outlines what employees' responsibilities are when it comes to cash control.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <p>The following rules always apply:</p>
                            <ul>
                                <li>
                                    CASH ONLY.
                                </li>
                                <li>
                                    Confirm the transaction with the customer.
                                </li>
                                <li>
                                    Take the money and put in till prior to using the pay point terminal.
                                </li>
                                <li>
                                    Again, confirm transaction before confirming on the terminal.
                                </li>
                                <li>
                                    Do not turn your back to the customer during this transaction.
                                </li>
                                <li>If a customer asks for £20 for example and you process £40 then you will be held accountable for this
                                    mistake</li>
                                <li>
                                    PayPoint sales are linked to your Till Code which is processed when you complete the Till Agreement.
                                    All discrepancies will be the responsibility of the employee.
                                </li>
                                <li>No telephone transactions are allowed</li>
                                <li>
                                    Paypoint or Western Union will never call you to do anything over the phone.
                                </li>
                                <li>
                                    Anyone who does call, including ask you to test the terminal will be a hoax call.
                                </li>
                                <li>
                                    All PayPoint sales are cash only. No credit or debit cards.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!first ? (
                                    <>
                                        <Button onClick={() => prev()} variant="outlined" color="error" className="moveButton">
                                            Prev
                                        </Button>
                                        <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/retail/paypoint")}
                                                        variant="contained"
                                                        color="success"
                                                        sx={{alignSelf: "center"}}
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/retail/paypoint")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>

            )}

            <Footer />
        </section>
    );
}
