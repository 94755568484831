import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function DateMaintenanceTubbeesHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Date Maintenance</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance/day-dots")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Day Dots</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance/filling-day-dots")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Filling Day Dot Sheet</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance/date-checking")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Checking Dates</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance/wastage-sheet")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Wastage Sheet</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance/slush-syrup")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Slush Syrup Maintenance</h2>
            </div>
          </div>
  
        </div>
        <Footer />
      </main>
    );

}