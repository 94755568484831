import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import unicorn from "./images/waffle-unicorn.webp";
import mermaid from "./images/waffle-mermaid.webp";
import oreo from "./images/waffle-oreo.webp";
import brownie from "./images/waffle-brownie.webp";
import heaven from "./images/waffle-chocHeaven.webp";
import mess from "./images/waffle-whiteChocMess.webp";
import peanut from "./images/waffle-Poverload.webp";
import ferrero from './images/waffle-ambasador.webp'
import banoffee from './images/waffle-banoffee.webp'
import nutella from './images/waffle-nutella.webp'

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function DecoWaffles(props){
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);
    const [fifth, setFifth] = useState(false);
    const [sixth, setSixth] = useState(false);
    const [seventh, setSeventh] = useState(false);
    const [eigth, setEigth] = useState(false);
    const [nine, setNine] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Baking/Making Products");
    const [child, setChild] = useState("WAFFLE");
  
  
    useEffect(() => {
      var data = localStorage.getItem("staff");
      data = JSON.parse(data);
      const transaction = Sentry.startTransaction({
        name: `Get Staff Details`,
        sampled: true,
      });
      axios
        .get(
          `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
        )
        .then((res) => {
          if (res.data) {
            localStorage.removeItem("staff");
            localStorage.setItem("staff", JSON.stringify(res.data));
            // console.log(res.data);
            setEmployee(res.data);
            const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
            const childCat = res.data.tasks[parentCat].taskData.findIndex(
              (x) => x.task === child
            );
            if (res.data.tasks[parentCat].taskData[childCat].competent) {
              setCompleted(true);
            }
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      transaction.finish();
    }, []);
  
    function next() {
      if (intro === true) {
        setIntro(false);
        setFirst(true);
      } else if (first === true) {
        setFirst(false);
        setSecond(true);
      } else if (second === true) {
        setSecond(false);
        setThird(true);
      } else if (third === true) {
        setThird(false);
        setFourth(true);
      } else if (fourth === true) {
        setFourth(false);
        setFifth(true);
      } else if (fifth === true) {
        setFifth(false);
        setSixth(true);
      } else if (sixth === true) {
        setSixth(false);
        setSeventh(true);
      } else if (seventh === true) {
        setSeventh(false);
        setEigth(true);
      }else if (eigth === true){
        setEigth(false)
        setNine(true)
      }
    }
  
    function prev() {
      if (first === true) {
        setFirst(false);
        setIntro(true);
      } else if (second === true) {
        setSecond(false);
        setFirst(true);
      } else if (third === true) {
        setThird(false);
        setSecond(true);
      } else if (fourth === true) {
        setFourth(false);
        setThird(true);
      } else if (fifth === true) {
        setFifth(false);
        setFourth(true);
      } else if (sixth === true) {
        setSixth(false);
        setFifth(true);
      } else if (seventh === true) {
        setSeventh(false);
        setSixth(true);
      } else if (eigth === true) {
        setEigth(false);
        setSeventh(true);
      }else if (nine === true){
        setNine(false)
        setEigth(false)
      }
    }
  
    function handleSubmit() {
      var data = {
        staffTrainingId: employee.staffTrainingId,
        parent: parent,
        child: child,
      };
      const transaction = Sentry.startTransaction({
        name: `Submit Waffle making completion`,
        sampled: true,
      });
      axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
        .then((res) => {
          party.confetti(document.getElementById("confetti"), confettiSettings);
          setUnit(true);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      transaction.finish();
    }
  
    return(
        <section className="moduleHome">
        <Header stop={props.stop} />
        <h3>Waffles</h3>
        {!completed ? (
          <>
            {intro ? (
              <div className="unit">
                <h4>Unicorn</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot White Chocolate Sauce</li>
                  <li>Fizzers</li>
                  <li>Parma Violets</li>
                  <li>Unicorn Sprinkles</li>
                  <li>Pink &amp; Blue Sherbet</li>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Icing Sugar</li>
                  <li>Candy Floss</li>
                  <li>Unicorn Horn</li>
                  <li>Whipped Cream</li>
                  <li>Cotton Candy Ice Cream</li>
                  <li>Wafer</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle Hot White Chocolate sauce onto greaseproof paper
                  </li>
                  <li>
                    Sprinkle 1 packet of Fizzers, Parma Violets, Unicorn Sprinkles
                    and Pink &amp; Blue Sherbet
                  </li>
                  <li>Drizzle hot milk chocolate sauce on waffle (x3)</li>
                  <li>Icing Sugar speckled</li>
                  <li>Pink candy floss in middle of waffle</li>
                  <li>Unicorn horn placed in centre of candy floss</li>
                  <li>
                    Whipped cream sprayed to 4 sides and pink &amp; blue sherbet
                    on top of whipped cream
                  </li>
                  <li>
                    Served with a scoop of cotton candy ice cream in white jar
                    topped with a wafer and unicorn sprinkles
                  </li>
                </ol>
                <img
                  src={unicorn}
                  alt="unicorn waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {first ? (
              <div className="unit">
                <h4>Mermaid</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Marshmallows</li>
                  <li>Smarties</li>
                  <li>Rainbow Sprinkles</li>
                  <li>Blue &amp; Pink Sherbet</li>
                  <li>Hot White Chocolate Sauce</li>
                  <li>Icing Sugar</li>
                  <li>Pink Candyfloss</li>
                  <li>Mermaid Tail</li>
                  <li>Whipped Cream</li>
                  <li>Cotton Candy Ice Cream</li>
                  <li>Wafer</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>
                    Marshmallow, Smarties, Rainbow Sprinkles and Blue &amp; Pink
                    Sherbet sprinkled on top
                  </li>
                  <li>Drizzle Hot White Chocolate Sauce on to waffle (x3)</li>
                  <li>Icing sugar speckled</li>
                  <li>Pink candyfloss in middle of waffle</li>
                  <li>Mermaid tail placed in centre of candy floss</li>
                  <li>Whipped cream sprayed to 4 sides</li>
                  <li>
                    Served with a scoop of cotton candy ice cream in white jar
                    topped with a wafer
                  </li>
                </ol>
                <img
                  src={mermaid}
                  alt="mermaid waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {second ? (
              <div className="unit">
                <h4>Oreo</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate</li>
                  <li>4 Oreos</li>
                  <li>Hot White Chocolate</li>
                  <li>Milk Chocolate Flakes</li>
                  <li>Icing Sugar</li>
                  <li>Whipped Cream</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle hot milk chocolate on grease proof paper</li>
                  <li>4 Oreos crushed onto waffle</li>
                  <li>Hot white chocolate sauce drizzled on top</li>
                  <li>Milk chocolate flakes sprinkled on top</li>
                  <li>Icing sugar speckled</li>
                  <li>Whipped cream sprayed to side tub</li>
                  <li>Crushed Oreo pieces sprinkled</li>
                </ol>
                <img src={oreo} alt="Oreo waffles" className="tubbbees-img" />
              </div>
            ) : (
              <></>
            )}
            {third ? (
              <div className="unit">
                <h4>Brownie</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate</li>
                  <li>Brownie</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method</p>
                <ol>
                  <li>Drizzle hot milk chocolate on grease proof</li>
                  <li>Cut brownie in to 9 pieces</li>
                  <li>Place 2 in each quarter section and 1 in the middle</li>
                  <li>Hot Milk Chocolate on waffle (x3)</li>
                  <li>Icing Sugar speckled</li>
                </ol>
                <img
                  src={brownie}
                  alt="Brownie waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {fourth ? (
              <div className="unit">
                <h4>Chocolate Heaven</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot White Chocolate</li>
                  <li>Smarties</li>
                  <li>Hot Milk Chocolate</li>
                  <li>White and Milk Chocolate Curls</li>
                  <li>Icing Sugar</li>
                </ul>
                <ol>
                  <li>Drizzle hot white chocolate onto grease proof</li>
                  <li>Put smarties on waffle</li>
                  <li>1 hot white and 2 hot milk chocolate on waffle</li>
                  <li>White and milk chocolate curls sprinkled on waffle</li>
                  <li>Icing sugar speckled</li>
                </ol>
                <img
                  src={heaven}
                  alt="Chocolate Heaven waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {fifth ? (
              <div className="unit">
                <h4>White Mess</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot White Chocolate</li>
                  <li>Full Bueno</li>
                  <li>Hot White Chocolate</li>
                  <li>White Chocolate Curls</li>
                  <li>Icing Sugar</li>
                </ul>
                <ol>
                  <li>Drizzle hot white chocolate onto plate</li>
                  <li>Break up full Bueno and spread to sections</li>
                  <li>3 Hot white chocolate on to waffle</li>
                  <li>White chocolate curls sprinkled on waffle</li>
                  <li>Icing sugar speckled</li>
                </ol>
                <img
                  src={mess}
                  alt="White Chocolate Mess waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {sixth ? (
              <div className="unit">
                <h4>Peanut Overload</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate</li>
                  <li>Peanut Butter</li>
                  <li>4 Small Peanut Butter Cups</li>
                  <li>1 Large Peanut Butter Cup</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>Place 4 scoops of peanut butter to each section</li>
                  <li>
                    Attach the small peanut butter cups using the peanut butter
                    scoops
                  </li>
                  <li>Place the large peanut butter cup in the centre</li>
                  <li>Drizzle hot milk chocolate on top of the waffle</li>
                  <li>Speckle icing sugar over top</li>
                </ol>
                <img
                  src={peanut}
                  alt="Peanut overload waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {seventh ? (
              <div className="unit">
                <h4>Banoffee</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Toffee Sauce</li>
                  <li>Banana</li>
                  <li>Toffee Pieces</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle toffee sauce onto grease proof paper</li>
                  <li>
                    4 slices of banana in each quarter section and 1 in the centre
                  </li>
                  <li>Drizzle toffee sauce on top (x3)</li>
                  <li>Sprinkle toffee pieces over waffled</li>
                  <li>Speckle icing sugar over the top</li>
                </ol>
                <img
                  src={banoffee}
                  alt="Banoffee waffles"
                  className="tubbbees-img"
                />
  
              </div>
            ) : (
              <></>
            )}
            {eigth ? (
              <div className="unit">
                <h4>Ferrero</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Milk Chocolate Sauce</li>
                  <li>4 Ferrero</li>
                  <li>Hot Nutella</li>
                  <li>Nut Pieces</li>
                  <li>Icing Sugar</li>
                </ul>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>
                    One semi crushed Ferrero in each quarter section (x4) of
                    waffle
                  </li>
                  <li>Drizzle Hot Nutella on top of waffle (x3)</li>
                  <li>Nut pieces sprinkled over top</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={ferrero}
                  alt="Ferrero waffles"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {nine ? (
              <div className="unit">
                <h4>Nutella</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Milk Chocolate Sauce</li>
                  <li>3 Strawberries</li>
                  <li>Hot Nutella</li>
                  <li>Icing Sugar</li>
                </ul>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>Slice 3 strawberries and spread over waffle</li>
                  <li>Drizzle hot Nutella over top of waffle (x3)</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={nutella}
                  alt="Nutella waffles"
                  className="tubbbees-img"
                />
  
              </div>
            ) : (
              <></>
            )}
            <div className="moveButtons">
              {!intro ? (
                <>
                  {!nine ? (
                    <>
                      <Button
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                        className="moveButton"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => next()}
                        variant="outlined"
                        color="error"
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    <div className="unit">
                      <div className="moveButtons" id="confetti">
                        <Button
                          className="moveButton"
                          onClick={() => prev()}
                          variant="outlined"
                          color="error"
                        >
                          Prev
                        </Button>
                        <Button
                          className="moveButton"
                          onClick={() => handleSubmit()}
                          variant="outlined"
                          color="success"
                        >
                          Submit
                        </Button>
                      </div>
                      {unit ? (
                        <>
                          <br />
                          <div className="unit">
                            <h6>Congratulations! you finished this module</h6>
                            <Button
                              onClick={() => navigate("/tubbees/baking")}
                              variant="contained"
                              color="success"
                              sx={{alignSelf: "center"}}
                            >
                              Go back to units
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Button
                  className="moveButton"
                  onClick={() => next()}
                  variant="outlined"
                  color="error"
                >
                  Next
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className="completed">
            <img src={complete} />
            <h2>This unit has been completed.</h2>
            <p>Do you want to do it again?</p>
            <div className="moveButtons" id="confetti">
              <Button
                className="moveButton"
                onClick={() => setCompleted(false)}
                variant="outlined"
                color="error"
              >
                Yes
              </Button>
              <Button
                className="moveButton"
                onClick={() => navigate("/tubbees/baking")}
                variant="outlined"
                color="success"
              >
                No
              </Button>
            </div>
          </div>
        )}
        <Footer />
      </section>
      )

}