import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function VapeCigars(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Vape &amp; Cigars</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/customerService")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/CustomerService.png" alt="customer service" />
          <div className="completion">
            <h2>Customer Service</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/vape")}>
          <img src={health} alt="vape training" />
          <div className="completion">
            <h2>Vape Training</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/returns")}>
          <img src={health} alt="vape returns" />
          <div className="completion">
            <h2>Vape Returns</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/filling")}>
          <img src={health} alt="stock filling" />
          <div className="completion">
            <h2>Stock Filling</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/cigars")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/vapesCigars.png" alt="how to show and sell cigars" />
          <div className="completion">
            <h2>How To Sell/Show Cigars</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/vape-cigars/general-policy")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/general-policy.png" alt="how to show and sell cigars" />
          <div className="completion">
            <h2>General Policy</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
