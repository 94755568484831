import { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import "../styles/account.scss";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Certificate from "./Certificate";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from '@mui/material/Stack';
import SendIcon from '@mui/icons-material/Send';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "30vh",
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "1rem",
  boxShadow: 24,
  p: 4,
};

export default function Accounts(props) {
  const navigate = useNavigate();
  const [staffFound, setStaffFound] = useState(localStorage.getItem("staff"));
  const [staffData, setStaffData] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [email, setEmail] = useState("");
  const [errorAlert, setErrorAlert] = useState(false);
  const [emailAlert, setEmailAlert] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certTotal, setCertTotal] = useState("");
  const [showCertificates, setShowCertificates] = useState(false);
  const [newEmail, setNewEmail] = useState("")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [message, setMessage] = useState("")
  const [newEmailAlert, setNewEmailAlert] = useState(false)

  useEffect(() => {
    if (staffFound) {
      var data = JSON.parse(staffFound);
      setStaffData(data);
      const transaction = Sentry.startTransaction({
        name: `Get Staff Percentage`,
        sampled: true,
      });
      axios
        .get(
          `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/percentage?id=${data.staffTrainingId}`
        )
        .then((res) => {
          setPercentage(res.data);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      transaction.finish();
      axios
        .get(
          `https://nitrous.rt-itservices.co.uk/training/certificates?id=${data.staffTrainingId}`
        )
        .then((res) => {
          setCertTotal(res.data.certificates.length);
          setCertificates(res.data.certificates);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [staffFound]);

  const stop = async () => {
    props.stop();
  };

  function updateEmail() {
    var data = {
      staffTrainingId: staffData.staffTrainingId,
      email: email,
    };
    if (
      data.email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      axios
        .post(
          "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/updateEmail",
          data
        )
        .then((res) => {
          console.log(res.data);
          axios
            .get(
              `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${staffData.staffTrainingId.toString()}`
            )
            .then((res) => {
              if (res.data) {
                localStorage.setItem("staff", JSON.stringify(res.data));
                window.location.reload();
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 2500);
        });
    } else {
      setEmailAlert(true);
      document.querySelector("input").focus();
      setTimeout(() => {
        setEmailAlert(false);
      }, 2500);
    }
  }

  const modifyEmail = () => {
    const data={
      staffTrainingId: staffData.staffTrainingId,
      email: newEmail,
    }
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if(newEmail.match(regex)){
      axios.post("https://backend.training.racetrackpitstop.co.uk/trainingAdmin/changeEmail", data)
      .then((res) => {
        handleClose()
        navigate("/home")
      }).catch((err) => {
        setMessage("Could not update email, please try again")
        setNewEmailAlert(true)
        setTimeout(() => {
          setNewEmailAlert(false)
        },2500)
      })
    } else {
      setMessage("Email has the wrong format, please try again")
      setNewEmailAlert(true)
      setTimeout(() => {
        setNewEmailAlert(false)
      },2500)
    }
  };

  return (
    <>
      <Header stop={props.stop} />
      {staffFound ? (
        <div className="accountDetails">
          {errorAlert ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Email could not be updated <strong>Please try again</strong>
            </Alert>
          ) : (
            <></>
          )}
          {emailAlert ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Email does not have the right format
              <strong>Please try again</strong>
            </Alert>
          ) : (
            <></>
          )}

          <h1>
            <u>Your Account</u>
          </h1>
          <div className="progressBar">
            <CircularProgressbar value={percentage} text={`${percentage}%`} />
          </div>
          <h3>
            Staff ID: <span>{staffData.staffTrainingId}</span>
          </h3>
          <h3>
            Name: <span>{staffData.name}</span>
          </h3>
          <h3>
            Role: <span>{staffData.role}</span>
          </h3>
          <h3>
            Site: <span>{staffData.site}</span>
          </h3>
          <h3>
            Added: <span>{new Date(staffData.addedDate).toLocaleString()}</span>
          </h3>
          <h3>
            Certificates :{" "}
            <span
              onClick={() => {
                setShowCertificates(!showCertificates);
              }}
            >
              {certTotal} 🔻
            </span>
          </h3>

          {showCertificates ? (
            <div style={{ border: "1px solid black" }}>
              {certificates.map(function (d, i) {
                return (
                  <Certificate
                    key={i}
                    data={d}
                    name={staffData.name}
                    role={staffData.role}
                    site={staffData.site}
                  />
                );
              })}
            </div>
          ) : (
            <></>
          )}

          {!staffData.email ? (
            <div className="email">
              <input
                type="text"
                placeholder="Your Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <button onClick={() => updateEmail()}>Update</button>
            </div>
          ) : (
            <h3>
              e-mail:{" "}
              <span>
                {staffData.email}{" "}
                <EditIcon color="error" sx={{ cursor: "pointer" }} onClick={handleOpen}/>
              </span>
            </h3>
          )}
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Change your Email
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                {newEmailAlert ? (
                  <Alert severity="error">{message}</Alert>
                ):(
                  <></>
                )}
                <Stack spacing={3}>
                <p>Your current email: {staffData.email}</p>
                <b>Change to:</b>
                <input type="text" onChange={(e) => setNewEmail(e.target.value)} style={{width: "50%"}}/>
                <Stack direction="row" spacing={2}>
                  <Button color="success" variant="contained" endIcon={<SendIcon />} onClick={() => modifyEmail()}>Send</Button>
                  <Button color="error" variant="outlined" onClick={handleClose}>Cancel</Button>
                </Stack>
                </Stack>
              </Typography>
            </Box>
          </Modal>
        </div>
      ) : (
        <>
          <div className="accountDetails">
            <h1>Guest Account</h1>
            <h3 style={{ textAlign: "center" }}>
              There Is No Details For Guest Accounts
            </h3>
            <button
              onClick={() => {
                navigate("/home");
              }}
            >
              Go Back ↩
            </button>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}
