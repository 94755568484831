import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Accounts from "./components/Accounts";
import Alcohol from "./components/Alcohol";
import Home from "./components/Home";
import Login from "./components/Login";
import CoreHome from "./components/modules/core/CoreHome";
import Delivery from "./components/modules/core/deliveries/Delivery";
import FoodMaintenance from "./components/modules/core/FoodMaintenance";
import HealthAndSafety from "./components/modules/core/h&s/HealthAndSafety";
import RetailMaintenance from "./components/modules/core/RetailMaintenance";
import TillsHome from "./components/modules/core/TillsHome";
import Float from './components/modules/core/tills/Float'
import OpenTill from "./components/modules/core/tills/OpenTill";
import NoScans from "./components/modules/core/tills/NoScans";
import Refunds from "./components/modules/core/tills/Refunds";
import Scanning from "./components/modules/core/tills/Scanning";
import ClosingTill from "./components/modules/core/tills/ClosingTill";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Tracker from '@openreplay/tracker';
import FuelHome from "./components/modules/fuel/FuelHome";
import KeyFuel from "./components/modules/fuel/KeyFuel";
import DriveOff from "./components/modules/fuel/DriveOff";
import Auth from "./components/modules/fuel/Auth";
import Suspicious from "./components/modules/fuel/Suspicious";
import NMOP from "./components/modules/fuel/NMOP";
import Tank from "./components/modules/fuel/Tank";
import Capacity from "./components/modules/fuel/Capacity";
import ManagerHome from "./components/modules/manager/ManagerHome";
import HealthAndSafetyHome from './components/modules/core/HealthAndSafetyHome'
import CsHome from "./components/modules/core/CsHome";
import CustomerService from "./components/modules/core/cs/CustomerService";
import HotSurface from "./components/modules/core/h&s/HotSurface";
import ManualHandling from "./components/modules/core/h&s/ManualHandling";
import Eyewash from "./components/modules/core/h&s/Eyewash";
import Knife from "./components/modules/core/h&s/Knife";
import Spillages from "./components/modules/core/h&s/Spillages";
import Allergens from "./components/modules/core/h&s/Allergens";
import Ordering from "./components/modules/manager/Ordering";
import Critical from "./components/modules/manager/Critical";
import Visitors from "./components/modules/core/cs/Visitors";
import Reps from "./components/modules/core/cs/Reps";
import Returns from "./components/modules/core/cs/Returns";
import DeliveryHome from "./components/modules/core/DeliveryHome";
import SlushDelivery from "./components/modules/core/deliveries/SlushDelivery";
import IceCreamDelivery from "./components/modules/core/deliveries/IceCreamDelivery";
import FrozenDelivery from "./components/modules/core/deliveries/FrozenDelivery";
import DisposablesDelivery from "./components/modules/core/deliveries/DisposablesDelivery";
import IngredientsDelivery from "./components/modules/core/deliveries/IngredientsDelivery";
import CumbernauldCakes from "./components/modules/core/deliveries/CumbernauldCakes";
import LondisDelivery from "./components/modules/core/deliveries/LondisDelivery";
import BookerDelivery from "./components/modules/core/deliveries/BookerDelivery";
import FirstAid from "./components/modules/core/h&s/FirstAid";
import MaintenanceHome from "./components/modules/core/MaintenanceHome";
import Menu from "./components/modules/core/maintenance/Menu";
import Shop from "./components/modules/core/maintenance/Shop";
import Music from "./components/modules/core/maintenance/Music";
import Screens from "./components/modules/core/maintenance/Screens";
import MatchDays from "./components/modules/core/maintenance/MatchDays";
import DeliveryTablets from "./components/modules/core/maintenance/DeliveryTablets";
import TGTG from "./components/modules/core/maintenance/TGTG";
import Systems from "./components/modules/manager/Systems";
import KeyHolder from "./components/modules/manager/KeyHolder";
import Subway from "./components/modules/manager/Subway";
import ManagementTasks from "./components/modules/manager/ManagementTasks";
import RetailHome from "./components/modules/retail/RetailHome";
import FloorMaintenanceHome from "./components/modules/core/FloorMaintenanceHome";
import Brushing from "./components/modules/core/floorMaintenance/Brushing";
import Mopping from "./components/modules/core/floorMaintenance/Mopping";
import WetFloor from "./components/modules/core/floorMaintenance/WetFloor";
import MopWater from "./components/modules/core/floorMaintenance/MopWater";
import CleaningGlass from "./components/modules/core/floorMaintenance/CleaningGlass";
import CleaningShelves from "./components/modules/core/floorMaintenance/CleaningShelves";
import CustomerObs from "./components/modules/core/floorMaintenance/CustomerObs";
import FrontingStock from "./components/modules/core/floorMaintenance/FrontingStock";
import FridgeCounter from "./components/modules/core/floorMaintenance/FridgeCounter";
import DateMaintenance from "./components/modules/retail/DateMaintenance";
import VapeCigars from "./components/modules/retail/VapeCigars";
import StockFilling from "./components/modules/retail/StockFilling";
import Hoagies from "./components/modules/retail/Hoagies";
import Paypoint from "./components/modules/retail/Paypoint";
import CalorFlogas from "./components/modules/retail/CalorFlogas";
import Electrical from "./components/modules/retail/Electrical";
import JetWashes from "./components/modules/retail/JetWashes";
import FloorSpillages from './components/modules/core/floorMaintenance/FloorSpillages'
import ClosingUpHome from "./components/modules/core/ClosingUpHome";
import NormalBin from "./components/modules/core/closingup/NormalBin";
import FoodBin from "./components/modules/core/closingup/FoodBin";
import CleaningTables from "./components/modules/core/closingup/CleaningTables";
import CleaningSeats from "./components/modules/core/closingup/CleaningSeats";
import Barriers from "./components/modules/core/closingup/Barriers";
import DateRotation from "./components/modules/retail/datemaintenance/DateRotation";
import CriticalLists from "./components/modules/retail/datemaintenance/CriticalLists";
import Wastage from "./components/modules/retail/datemaintenance/Wastage";
import Reductions from "./components/modules/retail/datemaintenance/Reductions";
import Cabinets from './components/modules/retail/Electrical/Cabinets'
import Switches from './components/modules/retail/Electrical/Switches'
import Cleaning from './components/modules/retail/hoagies/Cleaning'
import FillingBeans from './components/modules/retail/hoagies/FillingBeans'
import TempChecks from './components/modules/retail/hoagies/TempChecks'
import DateMaintenanceHoagies from "./components/modules/retail/hoagies/DateMaintenanceHoagies";
import Bins from './components/modules/retail/jetwashes/Bins'
import Chemicals from './components/modules/retail/jetwashes/Chemicals'
import Damage from './components/modules/retail/jetwashes/Damage'
import Forecourt from './components/modules/retail/jetwashes/Forecourt'
import Recycle from './components/modules/retail/jetwashes/Recycle'
import Waste from './components/modules/retail/jetwashes/Waste'
import PaypointLogin from './components/modules/retail/paypoint/PaypointLogin'
import ProcessPayment from './components/modules/retail/paypoint/ProcessPayment'
import PaymentMethod from './components/modules/retail/paypoint/PaymentMethods'
import Containers from './components/modules/retail/stockfilling/Containers'
import Damages from './components/modules/retail/stockfilling/Damages'
import Fridge from './components/modules/retail/stockfilling/Fridge'
import SellCigars from './components/modules/retail/vapecigars/SellCigars'
import VapeCustomerService from "./components/modules/retail/vapecigars/VapeCustomerService";
import VapeReturns from './components/modules/retail/vapecigars/VapeReturns'
import VapeStockFilling from "./components/modules/retail/vapecigars/VapeStockFilling";
import VapeTraining from './components/modules/retail/vapecigars/VapeTraining'
import ElectricPump from "./components/modules/fuel/ElectricPump";
import TubbeesHome from './components/modules/tubbees/TubbeesHome'
import SlushHome from './components/modules/tubbees/SlushHome'
import IceCreamHome from './components/modules/tubbees/IceCreamHome'
import KitchenHome from './components/modules/tubbees/KitchenHome'
import DateMaintenanceTubbeesHome from './components/modules/tubbees/DateMaintenanceTubbeesHome'
import ChecksHome from './components/modules/tubbees/ChecksHome'
import DecoratingHome from './components/modules/tubbees/DecoratingHome'
import MixesHome from './components/modules/tubbees/MixesHome'
import CleansHome from './components/modules/tubbees/CleansHome'
import BakingHome from './components/modules/tubbees/BakingHome'
import StockFillingHome from './components/modules/tubbees/StockFillingHome'
import PaperworkHome from './components/modules/tubbees/PaperworkHome'
import BackOffice from './components/modules/manager/critical/BackOffice'
import CleaningRotas from './components/modules/manager/critical/CleaningRotas'
import CriticalLists2 from './components/modules/manager/critical/CriticalLists2'
import CriticalMaintenance from './components/modules/manager/critical/CriticalMaintenance'
import CriticalOrganisation from './components/modules/manager/critical/CriticalOrganisation'
import Merchandising from './components/modules/manager/critical/Merchandising'
import RunningPromotions from './components/modules/manager/critical/RunningPromotions'
import ShopWastage from './components/modules/manager/critical/ShopWastage'
import DealingCustomers from "./components/modules/manager/critical/DealingCustomers";
import Opening from './components/modules/manager/keyholder/Opening'
import Closing from './components/modules/manager/keyholder/Closing'
import Alarm from './components/modules/manager/keyholder/Alarm'
import ManagementRotas from './components/modules/manager/managementtasks/ManagementRotas'
import MessageGroup from './components/modules/manager/managementtasks/MessageGroup'
import ShopProcedures from './components/modules/manager/managementtasks/ShopProcedures'
import StaffHig from './components/modules/manager/managementtasks/StaffHig'
import StaffInduction from './components/modules/manager/managementtasks/StaffInduction'
import StaffInterviews from './components/modules/manager/managementtasks/StaffInterviews'
import StaffProcedures from './components/modules/manager/managementtasks/StaffProcedures'
import OrderBooker from './components/modules/manager/ordering/OrderBooker'
import OrderLondis from './components/modules/manager/ordering/OrderLondis'
import OrderCostwald from './components/modules/manager/ordering/OrderCostwald'
import OrderCumbernauld from './components/modules/manager/ordering/OrderCumbernauld'
import OrderDessertBar from './components/modules/manager/ordering/OrderDessertBar'
import OrderHoagies from './components/modules/manager/ordering/OrderHoagies'
import OrderImported from './components/modules/manager/ordering/OrderImported'
import OrderPackaging from './components/modules/manager/ordering/OrderPackaging'
import OrderVapes from './components/modules/manager/ordering/OrderVapes'
import SubwayOrdering from './components/modules/manager/subway/SubwayOrdering'
import SubwayPresentation from './components/modules/manager/subway/SubwayPresentation'
import SubwayRev from './components/modules/manager/subway/SubwayRev'
import SubwayService from './components/modules/manager/subway/SubwayService'
import SystemAlcohol from './components/modules/manager/systems/SystemAlcohol'
import SystemCCTV from './components/modules/manager/systems/SystemCCTV'
import SystemNextCloud from './components/modules/manager/systems/SystemNextCloud'
import SystemRota from './components/modules/manager/systems/SystemRota'
import SystemTraining from './components/modules/manager/systems/SystemTraining'
import SystemPaypoint from './components/modules/manager/systems/SystemPaypoint'
import SystemLottery from './components/modules/manager/systems/SystemLottery'
import Machines from './components/modules/tubbees/slush/Machines'
import Mix from "./components/modules/tubbees/slush/Mix";
import DripTrays from './components/modules/tubbees/slush/DripTrays';
import SlushCleaning from "./components/modules/tubbees/slush/SlushCleaning";
import SlushTopUp from "./components/modules/tubbees/slush/SlushTopUp";
import CoolingMachines from "./components/modules/tubbees/slush/CoolingMachines";
import DeIcing from "./components/modules/tubbees/slush/DeIcing";
import VapesPolicy from './components/modules/retail/vapecigars/VapesPolicy'
import PaypointGeneralPolicy from './components/modules/retail/paypoint/PaypointGeneralPolicy'
import Scooping from "./components/modules/tubbees/icecream/Scooping";
import Sundaes from "./components/modules/tubbees/icecream/Sundaes";
import Milkshakes from "./components/modules/tubbees/icecream/Milkshakes";
import Smoothies from "./components/modules/tubbees/icecream/Smoothies";
import FreakShakes from "./components/modules/tubbees/icecream/Freakshakes";
import Deposits from './components/modules/retail/calorflogas/Deposits'
import Bottles from './components/modules/retail/calorflogas/Bottles'
import Dishwasher from "./components/modules/tubbees/kitchen/Dishwasher";
import GreaseTrap from "./components/modules/tubbees/kitchen/GreaseTrap";
import GlassWasher from "./components/modules/tubbees/kitchen/GlassWasher";
import Cutlery from "./components/modules/tubbees/kitchen/Cutlery";
import DayDots from "./components/modules/tubbees/dateMaintenance/DayDots";
import FillingDayDots from "./components/modules/tubbees/dateMaintenance/FillinDayDots";
import DateChecking from "./components/modules/tubbees/dateMaintenance/DateChecking";
import SlushSyrup from "./components/modules/tubbees/dateMaintenance/SlushSyrup";
import TempChecksTubbees from "./components/modules/tubbees/checks/TempChecks";
import WhatsApp from "./components/modules/tubbees/checks/WhatsApp";
import Freezer from "./components/modules/tubbees/checks/Freezer";
import DecoChurros from "./components/modules/tubbees/decorating/DecoChurros";
import DecoWaffles from "./components/modules/tubbees/decorating/DecoWaffles";
import DecoFudge from "./components/modules/tubbees/decorating/DecoFudge";
import DecoPancakes from "./components/modules/tubbees/decorating/DecoPancakes";
import DecoCrepes from "./components/modules/tubbees/decorating/DecoCrepes";
import DecoCookie from "./components/modules/tubbees/decorating/DecoCookie";
import DecoCake from "./components/modules/tubbees/decorating/DecoCake";
import ChurroMix from "./components/modules/tubbees/mixes/ChurroMix";
import CrepeMix from "./components/modules/tubbees/mixes/CreppeMix";
import Pancakemix from "./components/modules/tubbees/mixes/PancakeMix";
import NitroMix from "./components/modules/tubbees/mixes/NitroMix";
import SoftServe from "./components/modules/tubbees/cleans/SoftServe";
import SoftSlush from "./components/modules/tubbees/cleans/SoftSlush";
import DeepSlush from "./components/modules/tubbees/cleans/DeepSlush";
import SoftMilkshake from "./components/modules/tubbees/cleans/SoftMilkshake";
import DeepMilkshake from "./components/modules/tubbees/cleans/DeepMilkshake";
import TakeBinOut from "./components/modules/tubbees/cleans/TakeBinOut";
import ContainersClean from "./components/modules/tubbees/cleans/Containers";
import KitchenClean from "./components/modules/tubbees/cleans/KitchenClean";
import ToiletClean from "./components/modules/tubbees/cleans/ToiletClean";
import ToiletMop from "./components/modules/tubbees/cleans/ToiletMop";
import Waffles from "./components/modules/tubbees/baking/Waffles";
import Crepes from "./components/modules/tubbees/baking/Crepes";
import Pancakes from "./components/modules/tubbees/baking/Pancakes";
import HotCakes from "./components/modules/tubbees/baking/HotCakes";
import HotFood from "./components/modules/tubbees/baking/HotFood";
import CookieDough from "./components/modules/tubbees/baking/CookieDough";
import HotDrinks from "./components/modules/tubbees/baking/HotDrinks";
import IcedDrinks from "./components/modules/tubbees/baking/IcedDrinks";
import Frappuccino from "./components/modules/tubbees/baking/Frappuccino";
import CandyFloss from "./components/modules/tubbees/baking/CandyFloss";
import PickMix from "./components/modules/tubbees/stockfilling/PickMix";
import AmericanSection from "./components/modules/tubbees/stockfilling/AmericanSection";
import AmbientRotation from "./components/modules/tubbees/stockfilling/AmbientRotation";
import FreshRotation from "./components/modules/tubbees/stockfilling/FreshRotation";
import Defrosting from "./components/modules/tubbees/stockfilling/Defrosting";
import StockingUp from "./components/modules/tubbees/stockfilling/StockingUp";
import SauceTopUp from "./components/modules/tubbees/stockfilling/SauceTopUp";
import TubbeesDamages from "./components/modules/tubbees/stockfilling/Damages";
import IceCreamCount from "./components/modules/tubbees/paperwork/IceCreamCount";
import SlushCount from "./components/modules/tubbees/paperwork/SlushCount";
import TubbeesAllergens from "./components/modules/tubbees/paperwork/TubbeesAllergens";
import TubbeesWastage from "./components/modules/tubbees/paperwork/TubbeesWastage";
import StockOrdering from "./components/modules/tubbees/paperwork/StockOrdering";
import WastageSheet from "./components/modules/tubbees/dateMaintenance/WastageSheet";
import Internal from "./components/modules/manager/ordering/Internal";
import FuelSpillages from "./components/modules/fuel/FuelSpillages";
import NitroCctv from "./components/modules/nitroS/NitroCctv";
import NitroHome from "./components/modules/nitroS/NitroHome";
import NitroOnsite from "./components/modules/nitroS/NitroOnsite";
import CctvDataHandling from "./components/modules/nitroS/cctv/CctvDataHandling";
import HandTools from "./components/modules/nitroS/onsite/HandTools";
import SiteSafety from "./components/modules/nitroS/onsite/SiteSafety";
import Soldering from "./components/modules/nitroS/onsite/Soldering";
import FudgePuppies from "./components/modules/tubbees/baking/FudgePuppies";
import AlcoholTraining from "./components/AlcoholTraining";
import ShelfEdge from "./components/modules/core/ShelfEdge/ShelfEdge";
import ItHome from "./components/modules/it/ItHome";
import TsCardMachine from "./components/modules/it/TsCardMachine";
import LogIt from "./components/modules/it/LogIt";
import TillSettings from "./components/modules/it/TillSettings";
import RaceTrackIncoive from "./components/modules/it/RaceTrackInvoice";
import ManualInvoice from "./components/modules/it/ManualInvoice";
import ManagementReconciliation from "./components/modules/manager/managementtasks/ManagementReconciliation";
import HoagiesHome from "./components/modules/hoagies/HoagiesHome";
import WetFillingWrap from "./components/modules/hoagies/WetFillingWrap";
import Prep from "./components/modules/hoagies/Prep";
import PizzaHalves from "./components/modules/hoagies/PizzaHalves";
import Baguette from "./components/modules/hoagies/Baguette";
import SmallRoll from "./components/modules/hoagies/SmallRoll";
import Ciabatta from "./components/modules/hoagies/Ciabatta";
import SaladChicken from "./components/modules/hoagies/SaladChichen";
import WetFillingSalad from "./components/modules/hoagies/WetFillingSalad";

function App() {

  Sentry.init({
    dsn: "https://e89bbc3dee204c5ca65b8b1146d3c3e2@sentry.rt-itservices.co.uk/18",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });

  const tracker = new Tracker({
    projectKey: "80Ms8jupDu6p8XkmWhuN",
    ingestPoint: "https://replay.nitrous.nitrosolutions.co.uk/ingest",
    __DISABLE_SECURE_MODE: true,
    respectDoNotTrack: false
  });
  tracker.start();

  const stop = () => {
    tracker.stop()
  }

  const id = (userId) => {
    let no = Math.random();
    tracker.setUserID(`${userId} - ${no}`)
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route exact path="/home" element={<Home id={id} stop={stop} />} />
          <Route path="/alcohol" element={<AlcoholTraining />} />
          <Route path="/alcohol-test" element={<Alcohol stop={stop }/>} />
          <Route path="/accounts" element={<Accounts stop={stop} />} />
          <Route path="/core" element={<CoreHome stop={stop} />} />
          <Route path="/core/hs" element={<HealthAndSafetyHome stop={stop} />} />
          <Route path="/core/hs/general-poplicy" element={<HealthAndSafety stop={stop} />} />
          <Route path="/core/hs/hot-surfaces" element={<HotSurface stop={stop} />} />
          <Route path="/core/hs/manual-handling" element={<ManualHandling stop={stop} />} />
          <Route path="/core/hs/eyewash" element={<Eyewash stop={stop} />} />
          <Route path="/core/hs/knife-policy" element={<Knife stop={stop} />} />
          <Route path="/core/hs/spillages" element={<Spillages stop={stop} />} />
          <Route path="/core/hs/allergens" element={<Allergens stop={stop} />} />
          <Route path="/core/hs/first-aid" element={<FirstAid stop={stop} />} />
          <Route path="/core/customer-service" element={<CsHome stop={stop} />} />
          <Route path="/core/customer-service/site-visitors" element={<Visitors stop={stop} />} />
          <Route path="/core/customer-service/reps" element={<Reps stop={stop} />} />
          <Route path="/core/customer-service/returns" element={<Returns stop={stop} />} />
          <Route path="/core/customer-service/dealing-customers" element={<CustomerService stop={stop} />} />
          <Route path="/core/delivery" element={<DeliveryHome stop={stop} />} />
          <Route path="/core/delivery/general-policy" element={<Delivery stop={stop} />} />
          <Route path="/core/delivery/slush" element={<SlushDelivery stop={stop} />} />
          <Route path="/core/delivery/ice-cream" element={<IceCreamDelivery />} />
          <Route path="/core/delivery/frozen" element={<FrozenDelivery stop={stop} />} />
          <Route path="/core/delivery/disposables" element={<DisposablesDelivery stop={stop} />} />
          <Route path="/core/delivery/ingredients" element={<IngredientsDelivery stop={stop} />} />
          <Route path="/core/delivery/cumbernauld-cakes" element={<CumbernauldCakes stop={stop} />} />
          <Route path="/core/delivery/londis" element={<LondisDelivery stop={stop} />} />
          <Route path="/core/delivery/booker" element={<BookerDelivery stop={stop} />} />
          <Route path="/core/maintenance" element={<MaintenanceHome stop={stop} />} />
          <Route path="/core/maintenance/knowing-menu" element={<Menu stop={stop} />} />
          <Route path="/core/maintenance/knowing-shop" element={<Shop stop={stop} />} />
          <Route path="/core/maintenance/music-on" element={<Music stop={stop} />} />
          <Route path="/core/maintenance/screens-on" element={<Screens stop={stop} />} />
          <Route path="/core/maintenance/match-days" element={<MatchDays stop={stop} />} />
          <Route path="/core/maintenance/delivery-tablets" element={<DeliveryTablets stop={stop} />} />
          <Route path="/core/maintenance/too-good-to-go" element={<TGTG stop={stop} />} />
          <Route path="/core/date-maintenance-food" element={<FoodMaintenance stop={stop} />} />
          <Route path="/core/date-maintenance-retail" element={<RetailMaintenance stop={stop} />} />
          <Route path="/core/tills" element={<TillsHome stop={stop} />} />
          <Route path="/core/tills/float" element={<Float stop={stop} />} />
          <Route path="/core/tills/open-till" element={<OpenTill stop={stop} />} />
          <Route path="/core/tills/no-scans" element={<NoScans stop={stop} />} />
          <Route path="/core/tills/refunds" element={<Refunds stop={stop} />} />
          <Route path="/core/tills/scanning" element={<Scanning stop={stop} />} />
          <Route path="/core/tills/closing-till" element={<ClosingTill stop={stop} />} />
          <Route path="/core/floor-maintenance" element={<FloorMaintenanceHome stop={stop} />} />
          <Route path="/core/floor-maintenance/brushing" element={<Brushing stop={stop} />} />
          <Route path="/core/floor-maintenance/mopping" element={<Mopping stop={stop} />} />
          <Route path="/core/floor-maintenance/wet-floor-signs" element={<WetFloor stop={stop} />} />
          <Route path="/core/floor-maintenance/spillages" element={<FloorSpillages stop={stop} />} />
          <Route path="/core/floor-maintenance/changing-mop-water" element={<MopWater stop={stop} />} />
          <Route path="/core/floor-maintenance/cleaning-glass" element={<CleaningGlass stop={stop} />} />
          <Route path="/core/floor-maintenance/cleaning-shelves" element={<CleaningShelves stop={stop} />} />
          <Route path="/core/floor-maintenance/customer-observation" element={<CustomerObs stop={stop} />} />
          <Route path="/core/floor-maintenance/fronting-stock" element={<FrontingStock stop={stop} />} />
          <Route path="/core/floor-maintenance/fridge-counter-display" element={<FridgeCounter stop={stop} />} />
          <Route path="/core/closing-up" element={<ClosingUpHome stop={stop} />} />
          <Route path="/core/closing-up/take-out-bin" element={<NormalBin stop={stop} />} />
          <Route path="/core/closing-up/food-bin" element={<FoodBin stop={stop} />} />
          <Route path="/core/closing-up/cleaning-tables" element={<CleaningTables stop={stop} />} />
          <Route path="/core/closing-up/cleaning-seats" element={<CleaningSeats stop={stop} />} />
          <Route path="/core/closing-up/barriers-down" element={<Barriers stop={stop} />} />
          <Route path="/core/shelf-edge" element={<ShelfEdge />} />
          <Route path="/fuel" element={<FuelHome stop={stop} />} />
          <Route path="/fuel/key-fuel" element={<KeyFuel stop={stop} />} />
          <Route path="/fuel/drive-off" element={<DriveOff stop={stop} />} />
          <Route path="/fuel/authorisation" element={<Auth stop={stop} />} />
          <Route path="/fuel/suspicious-transaction" element={<Suspicious stop={stop} />} />
          <Route path="/fuel/nmop" element={<NMOP stop={stop} />} />
          <Route path="/fuel/tanks" element={<Tank stop={stop} />} />
          <Route path="/fuel/ullage" element={<Capacity stop={stop} />} />
          <Route path="/fuel/pump-stop" element={<ElectricPump stop={stop} />} />
          <Route path="/fuel/spillages" element={<FuelSpillages stop={stop} />} />
          <Route path="/retail" element={<RetailHome stop={stop} />} />
          <Route path="/retail/date-maintenance" element={<DateMaintenance stop={stop} />} />
          <Route path="/retail/vape-cigars" element={<VapeCigars stop={stop} />} />
          <Route path="/retail/stock-filling" element={<StockFilling stop={stop} />} />
          <Route path="/retail/hoagies" element={<Hoagies stop={stop} />} />
          <Route path="/retail/paypoint" element={<Paypoint stop={stop} />} />
          <Route path="/retail/calor-flogas" element={<CalorFlogas stop={stop} />} />
          <Route path="/retail/calor-flogas/deposits" element={<Deposits stop={stop} />} />
          <Route path="/retail/calor-flogas/bottles" element={<Bottles stop={stop} />} />
          <Route path="/retail/electrical" element={<Electrical stop={stop} />} />
          <Route path="/retail/jetwashes" element={<JetWashes stop={stop} />} />
          <Route path="/retail/date-maintenance/rotation" element={<DateRotation stop={stop} />} />
          <Route path="/retail/date-maintenance/lists" element={<CriticalLists stop={stop} />} />
          <Route path="/retail/date-maintenance/wastage" element={<Wastage stop={stop} />} />
          <Route path="/retail/date-maintenance/reductions" element={<Reductions stop={stop} />} />
          <Route path="/retail/electrical/cabinet" element={<Cabinets stop={stop} />} />
          <Route path="/retail/electrical/lighting" element={<Switches stop={stop} />} />
          <Route path="/retail/hoagies/cleaning" element={<Cleaning stop={stop} />} />
          <Route path="/retail/hoagies/filling" element={<FillingBeans stop={stop} />} />
          <Route path="/retail/hoagies/maintenance" element={<DateMaintenanceHoagies stop={stop} />} />
          <Route path="/retail/hoagies/temp" element={<TempChecks stop={stop} />} />
          <Route path="/retail/jetwashes/empty" element={<Bins stop={stop} />} />
          <Route path="/retail/jetwashes/chemicals" element={<Chemicals stop={stop} />} />
          <Route path="/retail/jetwashes/damage" element={<Damage stop={stop} />} />
          <Route path="/retail/jetwashes/forecourt" element={<Forecourt stop={stop} />} />
          <Route path="/retail/jetwashes/recylce" element={<Recycle stop={stop} />} />
          <Route path="/retail/jetwashes/waste" element={<Waste stop={stop} />} />
          <Route path="/retail/paypoint/login" element={<PaypointLogin stop={stop} />} />
          <Route path="/retail/paypoint/process-payment" element={<ProcessPayment stop={stop} />} />
          <Route path="/retail/paypoint/payment-methods" element={<PaymentMethod stop={stop} />} />
          <Route path="/retail/paypoint/general-policy" element={<PaypointGeneralPolicy stop={stop} />} />
          <Route path="/retail/stock-filling/fridge" element={<Fridge stop={stop} />} />
          <Route path="/retail/stock-filling/containers" element={<Containers stop={stop} />} />
          <Route path="/retail/stock-filling/damages" element={<Damages stop={stop} />} />
          <Route path="/retail/vape-cigars/customerService" element={<VapeCustomerService stop={stop} />} />
          <Route path="/retail/vape-cigars/vape" element={<VapeTraining stop={stop} />} />
          <Route path="/retail/vape-cigars/returns" element={<VapeReturns stop={stop} />} />
          <Route path="/retail/vape-cigars/filling" element={<VapeStockFilling stop={stop} />} />
          <Route path="/retail/vape-cigars/cigars" element={<SellCigars stop={stop} />} />
          <Route path="/retail/vape-cigars/general-policy" element={<VapesPolicy stop={stop} />} />
          <Route path="/manager" element={<ManagerHome stop={stop} />} />
          <Route path="/manager/ordering" element={<Ordering stop={stop} />} />
          <Route path="/manager/critical" element={<Critical stop={stop} />} />
          <Route path="/manager/systems" element={<Systems stop={stop} />} />
          <Route path="/manager/keyholder" element={<KeyHolder stop={stop} />} />
          <Route path="/manager/subway" element={<Subway stop={stop} />} />
          <Route path="/manager/management-tasks" element={<ManagementTasks stop={stop} />} />
          <Route path="/manager/ordering/londis" element={<OrderLondis stop={stop} />} />
          <Route path="/manager/ordering/booker" element={<OrderBooker stop={stop} />} />
          <Route path="/manager/ordering/costwald" element={<OrderCostwald stop={stop} />} />
          <Route path="/manager/ordering/vapes" element={<OrderVapes stop={stop} />} />
          <Route path="/manager/ordering/cumbernauld-cakes" element={<OrderCumbernauld stop={stop} />} />
          <Route path="/manager/ordering/imported-goods" element={<OrderImported stop={stop} />} />
          <Route path="/manager/ordering/packaging" element={<OrderPackaging stop={stop} />} />
          <Route path="/manager/ordering/dessert-bar" element={<OrderDessertBar stop={stop} />} />
          <Route path="/manager/ordering/hoagies" element={<OrderHoagies stop={stop} />} />
          <Route path="/manager/ordering/internal" element={<Internal stop={stop} />} />
          <Route path="/manager/critical/lists" element={<CriticalLists2 stop={stop} />} />
          <Route path="/manager/critical/wastage" element={<ShopWastage stop={stop} />} />
          <Route path="/manager/critical/customers" element={<DealingCustomers stop={stop} />} />
          <Route path="/manager/critical/merchandising" element={<Merchandising stop={stop} />} />
          <Route path="/manager/critical/promotions" element={<RunningPromotions stop={stop} />} />
          <Route path="/manager/critical/back-office" element={<BackOffice stop={stop} />} />
          <Route path="/manager/critical/maintenance" element={<CriticalMaintenance stop={stop} />} />
          <Route path="/manager/critical/cleaning" element={<CleaningRotas stop={stop} />} />
          <Route path="/manager/critical/organisation" element={<CriticalOrganisation stop={stop} />} />
          <Route path="/manager/keyholder/opening" element={<Opening stop={stop} />} />
          <Route path="/manager/keyholder/closing" element={<Closing stop={stop} />} />
          <Route path="/manager/keyholder/alarm" element={<Alarm />} stop={stop} />
          <Route path="/manager/management-tasks/induction" element={<StaffInduction stop={stop} />} />
          <Route path="/manager/management-tasks/interviews" element={<StaffInterviews stop={stop} />} />
          <Route path="/manager/management-tasks/hig" element={<StaffHig stop={stop} />} />
          <Route path="/manager/management-tasks/procedures" element={<StaffProcedures stop={stop} />} />
          <Route path="/manager/management-tasks/shop" element={<ShopProcedures stop={stop} />} />
          <Route path="/manager/management-tasks/rotas" element={<ManagementRotas stop={stop} />} />
          <Route path="/manager/management-tasks/message" element={<MessageGroup stop={stop} />} />
          <Route path="/manager/management-tasks/reconciliation" element={<ManagementReconciliation stop={stop} />} />
          <Route path="/manager/subway/presentation" element={<SubwayPresentation stop={stop} />} />
          <Route path="/manager/subway/service" element={<SubwayService stop={stop} />} />
          <Route path="/manager/subway/stock" element={<SubwayOrdering stop={stop} />} />
          <Route path="/manager/subway/revs" element={<SubwayRev stop={stop} />} />
          <Route path="/manager/systems/cctv" element={<SystemCCTV stop={stop} />} />
          <Route path="/manager/systems/rota" element={<SystemRota stop={stop} />} />
          <Route path="/manager/systems/alcohol" element={<SystemAlcohol stop={stop} />} />
          <Route path="/manager/systems/next-cloud" element={<SystemNextCloud stop={stop} />} />
          <Route path="/manager/systems/staff-training" element={<SystemTraining stop={stop} />} />
          <Route path="/manager/systems/lottery" element={<SystemLottery stop={stop} />} />
          <Route path="/manager/systems/paypoint" element={<SystemPaypoint stop={stop} />} />
          <Route path="/tubbees" element={<TubbeesHome stop={stop} />} />
          <Route path="/tubbees/slush" element={<SlushHome stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies" element={<IceCreamHome stop={stop} />} />
          <Route path="/tubbees/kitchen" element={<KitchenHome stop={stop} />} />
          <Route path="/tubbees/date-maintenance" element={<DateMaintenanceTubbeesHome stop={stop} />} />
          <Route path="/tubbees/checks" element={<ChecksHome stop={stop} />} />
          <Route path="/tubbees/decorating" element={<DecoratingHome stop={stop} />} />
          <Route path="/tubbees/mixes" element={<MixesHome stop={stop} />} />
          <Route path="/tubbees/full-cleans" element={<CleansHome stop={stop} />} />
          <Route path="/tubbees/baking" element={<BakingHome stop={stop} />} />
          <Route path="/tubbees/stock-filling" element={<StockFillingHome stop={stop} />} />
          <Route path="/tubbees/paperwork" element={<PaperworkHome stop={stop} />} />
          <Route path="/tubbees/slush/machines" element={<Machines stop={stop} />} />
          <Route path="/tubbees/slush/making-mix" element={<Mix stop={stop} />} />
          <Route path="/tubbees/slush/drip-trays" element={<DripTrays stop={stop} />} />
          <Route path="/tubbees/slush/cleaning-slush" element={<SlushCleaning stop={stop} />} />
          <Route path="/tubbees/slush/top-up-slush" element={<SlushTopUp stop={stop} />} />
          <Route path="/tubbees/slush/cooling-machines" element={<CoolingMachines stop={stop} />} />
          <Route path="/tubbees/slush/de-icing" element={<DeIcing stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies/scooping" element={<Scooping stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies/sundaes" element={<Sundaes stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies/milkshakes" element={<Milkshakes stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies/smoothies" element={<Smoothies stop={stop} />} />
          <Route path="/tubbees/ice-cream-smoothies/freakshakes" element={<FreakShakes stop={stop} />} />
          <Route path="/tubbees/kitchen/dishwasher" element={<Dishwasher stop={stop} />} />
          <Route path="/tubbees/kitchen/grease-trap" element={<GreaseTrap stop={stop} />} />
          <Route path="/tubbees/kitchen/glass-washer" element={<GlassWasher stop={stop} />} />
          <Route path="/tubbees/kitchen/buffering-cutlery" element={<Cutlery stop={stop} />} />
          <Route path="/tubbees/date-maintenance/day-dots" element={<DayDots stop={stop} />} />
          <Route path="/tubbees/date-maintenance/filling-day-dots" element={<FillingDayDots stop={stop} />} />
          <Route path="/tubbees/date-maintenance/date-checking" element={<DateChecking stop={stop} />} />
          <Route path="/tubbees/date-maintenance/wastage-sheet" element={<WastageSheet stop={stop} />} />
          <Route path="/tubbees/date-maintenance/slush-syrup" element={<SlushSyrup stop={stop} />} />
          <Route path="/tubbees/checks/temp-checks" element={<TempChecksTubbees stop={stop} />} />
          <Route path="/tubbees/checks/whatsapp" element={<WhatsApp stop={stop} />} />
          <Route path="/tubbees/checks/freezer" element={<Freezer stop={stop} />} />
          <Route path="/tubbees/decorating/churros" element={<DecoChurros stop={stop} />} />
          <Route path="/tubbees/decorating/waffles" element={<DecoWaffles stop={stop} />} />
          <Route path="/tubbees/decorating/fudge-puppies" element={<DecoFudge stop={stop} />} />
          <Route path="/tubbees/decorating/pancakes" element={<DecoPancakes stop={stop} />} />
          <Route path="/tubbees/decorating/crepes" element={<DecoCrepes stop={stop} />} />
          <Route path="/tubbees/decorating/cookie-dough" element={<DecoCookie stop={stop} />} />
          <Route path="/tubbees/decorating/cake-slices" element={<DecoCake stop={stop} />} />
          <Route path="/tubbees/mixes/churro-mix" element={<ChurroMix stop={stop} />} />
          <Route path="/tubbees/mixes/creppe-mix" element={<CrepeMix stop={stop} />} />
          <Route path="/tubbees/mixes/pancake-mix" element={<Pancakemix stop={stop} />} />
          <Route path="/tubbees/mixes/nitro-mix" element={<NitroMix stop={stop} />} />
          <Route path="/tubbees/full-cleans/soft-serve" element={<SoftServe stop={stop} />} />
          <Route path="/tubbees/full-cleans/soft-slush-clean" element={<SoftSlush stop={stop} />} />
          <Route path="/tubbees/full-cleans/deep-slush-clean" element={<DeepSlush stop={stop} />} />
          <Route path="/tubbees/full-cleans/soft-milkshake-clean" element={<SoftMilkshake stop={stop} />} />
          <Route path="/tubbees/full-cleans/deep-milkshake-clean" element={<DeepMilkshake stop={stop} />} />
          <Route path="/tubbees/full-cleans/taking-bin-out" element={<TakeBinOut stop={stop} />} />
          <Route path="/tubbees/full-cleans/containers" element={<ContainersClean stop={stop} />} />
          <Route path="/tubbees/full-cleans/kitchen-clean" element={<KitchenClean stop={stop} />} />
          <Route path="/tubbees/full-cleans/toilet-clean" element={<ToiletClean stop={stop} />} />
          <Route path="/tubbees/full-cleans/toilet-mop" element={<ToiletMop stop={stop} />} />
          <Route path="/tubbees/full-cleans/grease-traps" element={<GreaseTrap stop={stop} />} />
          <Route path="/tubbees/baking/waffles" element={<Waffles stop={stop} />} />
          <Route path="/tubbees/baking/crepes" element={<Crepes stop={stop} />} />
          <Route path="/tubbees/baking/pancakes" element={<Pancakes stop={stop} />} />
          <Route path="/tubbees/baking/fudge-puppies" element={<FudgePuppies stop={stop}/>}/>
          <Route path="/tubbees/baking/hot-cakes" element={<HotCakes stop={stop} />} />
          <Route path="/tubbees/baking/hot-food" element={<HotFood stop={stop} />} />
          <Route path="/tubbees/baking/cookie-dough" element={<CookieDough stop={stop} />} />
          <Route path="/tubbees/baking/hot-drinks" element={<HotDrinks stop={stop} />} />
          <Route path="/tubbees/baking/iced-drinks" element={<IcedDrinks stop={stop} />} />
          <Route path="/tubbees/baking/frappuccino" element={<Frappuccino stop={stop} />} />
          <Route path="/tubbees/baking/candyfloss" element={<CandyFloss stop={stop} />} />
          <Route path="/tubbees/stock-filling/pick-&amp;-mix" element={<PickMix stop={stop} />} />
          <Route path="/tubbees/stock-filling/american-section" element={<AmericanSection stop={stop} />} />
          <Route path="/tubbees/stock-filling/ambient-rotation" element={<AmbientRotation stop={stop} />} />
          <Route path="/tubbees/stock-filling/fresh-rotation" element={<FreshRotation stop={stop} />} />
          <Route path="/tubbees/stock-filling/defrosting-correctly" element={<Defrosting stop={stop} />} />
          <Route path="/tubbees/stock-filling/stocking-up" element={<StockingUp stop={stop} />} />
          <Route path="/tubbees/stock-filling/sauce-top-up" element={<SauceTopUp stop={stop} />} />
          <Route path="/tubbees/stock-filling/damages" element={<TubbeesDamages stop={stop} />} />
          <Route path="/tubbees/paperwork/ice-cream-count" element={<IceCreamCount stop={stop} />} />
          <Route path="/tubbees/paperwork/slush-count" element={<SlushCount stop={stop} />} />
          <Route path="/tubbees/paperwork/allergens" element={<TubbeesAllergens stop={stop} />} />
          <Route path="/tubbees/paperwork/wastage" element={<TubbeesWastage stop={stop} />} />
          <Route path="/tubbees/paperwork/stock-ordering" element={<StockOrdering stop={stop} />} />
          <Route path="/nitro" element={<NitroHome stop={stop} />} />
          <Route path="/nitro/cctv" element={<NitroCctv stop={stop} />} />
          <Route path="/nitro/onsite" element={<NitroOnsite stop={stop} />} />
          <Route path="/nitro/cctv/data-handling" element={<CctvDataHandling stop={stop} />} />
          <Route path="/nitro/onsite/hand-tools" element={<HandTools stop={stop} />} />
          <Route path="/nitro/onsite/site-safety" element={<SiteSafety stop={stop} />} />
          <Route path="/nitro/onsite/soldering" element={<Soldering stop={stop} />} />
          <Route path="/it" element={<ItHome stop={stop}/>}/>
          <Route path="/it/ts-card-machine" element={<TsCardMachine stop={stop} />} />
          <Route path="/it/log-it" element={<LogIt stop={stop}/>}/>
          <Route path="it/till-settings" element={<TillSettings stop={stop}/>}/>
          <Route path="it/racetrack-ordering" element={<RaceTrackIncoive stop={stop}/>}/>
          <Route path="it/manual-invoice" element={<ManualInvoice stop={stop} />}/>
          <Route path="/hoagies" element={<HoagiesHome stop={stop}/>} />
          <Route path="/hoagies/wet-filling-wrap" element={<WetFillingWrap stop={stop}/>} />
          <Route path="/hoagies/prep" element={<Prep stop={stop}/>} />
          <Route path="/hoagies/pizza-halves" element={<PizzaHalves stop={stop}/>} />
          <Route path="/hoagies/baguette" element={<Baguette stop={stop}/>} />
          <Route path="/hoagies/small-sub-roll" element={<SmallRoll stop={stop} />} />
          <Route path="/hoagies/ciabatta" element={<Ciabatta stop={stop} />}/>
          <Route path="/hoagies/quiche-salad-box" element={<SaladChicken stop={stop}/>} />
          <Route path="/hoagies/chicken-salad" element={<SaladChicken stop={stop}/>} />
          <Route path="/hoagies/wet-filling-salad" element={<WetFillingSalad stop={stop} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
