import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";


export default function CriticalLists(props){
    const navigate = useNavigate();

    return(
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>Critical Lists</h3>
            <div className="unit">
            <p style={{color: "#8C8C8C", textAlign:"center"}}>This unit will be coming soon</p>
            <Button variant="outlined" color="success" onClick={()=> navigate("/retail/date-maintenance")} className="moveButton">Back</Button>
            </div>
            <Footer/>
        </section>
    )

}