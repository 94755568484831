import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import MasksIcon from '@mui/icons-material/Masks';
import QuizIcon from '@mui/icons-material/Quiz';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function FirstAid(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);
    const [fifth, setFifth] = useState(false);
    const [sixth, setSixth] = useState(false);
    const [seventh, setSeventh] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("H&S")
    const [child, setChild] = useState("FIRST AID")
    const [rightAnswer, setRightAnswer] = useState(false);
    const [wrongAnswer, setWrongAnswer] = useState(false);
    const [answered,setAnswered] = useState(false)

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
        });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }, []);

    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        } else if (first === true) {
            setFirst(false);
            setSecond(true);
        } else if (second === true) {
            setSecond(false);
            setThird(true);
        } else if (third === true) {
            setThird(false);
            setFourth(true);
        } else if (fourth === true) {
            setFourth(false);
            setFifth(true);
        } else if (fifth === true) {
            setFifth(false);
            setSixth(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        } else if (second === true) {
            setSecond(false);
            setFirst(true);
        } else if (third === true) {
            setThird(false);
            setSecond(true);
        } else if (fourth === true) {
            setFourth(false);
            setThird(true);
        } else if (fifth === true) {
            setFifth(false);
            setFourth(true);
        } else if (sixth === true) {
            setSixth(false);
            setFifth(true);
        }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Health And Safety First Aid Policy`,
            sampled: true,
        });
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }

    const question = [
        {
            question: "What are the 3 Ps?",
            answers: [
                {
                    answerText: "Preserve Worsening, Preserve Life, and Promote Recovery",
                    isCorrect: true,
                },
                {
                    answerText: "Preserve Worsening, Preserve Blood, and Promote Recovery",
                    isCorrect: false,
                },
                {
                    answerText: "Preserve Worsening, Protect Life, and Prevent Infection",
                    isCorrect: false,
                },
            ],
        },
    ];

    function handleAnswer(i) {
        if (question[0].answers[i].isCorrect == true) {
            setRightAnswer(true);
            setWrongAnswer(false);
        } else {
            setWrongAnswer(true);
            setRightAnswer(false)
        }
        setAnswered(true)
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop} />
            <h3>First Aid</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <h4>Introduction</h4>
                            <LocalHospitalIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>The care given before emergency professional medical help arrives can ultimately mean the difference between life
                                and death, but knowing the correct thing to do if someone has a nosebleed or cut is also very important.</p>
                            <br />
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <h4>Identify, Assess &amp; Treat</h4>
                            <HealthAndSafetyIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>There are three main focuses on first aid and these can be described as the 3 Ps.</p>
                            <br />
                            <p><b>Prevent the situation worsening</b> - e.g. apply pressure to a bleed – you must
                                take into account the environment in ensuring you are safe and danger has been removed.</p>
                            <p><b>Promote Recovery</b> - e.g. get to hospital – help the casualty to safety from their illness or injury.</p>
                            <p><b>Preserve Life</b> - e.g. give CPR – remember, you must make sure you preserve your own life as well as the casualty's.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {second ? (
                        <div className="unit">
                            <h4>DRABCD</h4>
                            <MedicalServicesIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>This is an assessment tool known as a Primary Survey. This is used in any first aid situation and it is
                                important to alwasy treat the casualt whose life is most at risk.</p>
                            <br />
                            <p><b>D</b> - are there any <b>Dangers</b> that could bring harm? Can these be removed safely?</p>
                            <p><b>R</b> - <b>Response</b> - check to see if the casualty is concious.</p>
                            <p><b>A</b> - <b>Airway</b> - identify and trat any life threatening airway problems</p>
                            <p><b>B</b> - <b>Breathing</b> - treat any life threatening breathing problems such as asthma or anaphylaxis.</p>
                            <p><b>C</b> - <b>CPR</b> - with the knowledge that the emergency services are on their way, we must begin CPR.</p>
                            <p><b>D</b> - use an external <b>Defibrillator</b>, if available, to improve the casualty's chances.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {third ? (
                        <div className="unit">
                            <h4>Safety</h4>
                            <MasksIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>Prior to dealing with any casualty that may have any bleeding, burns or loss of bodily fluid it is imperative to
                                consider the health and safety of yourself and of others.</p>
                            <p>Gloves must be worn when you come into contact with any visible wounds or loss of bodily fluid. After the incident
                                you must dispose of all of the soiled dressings and also clear the area of any bodily fluids, including clearing
                                sharps or other items.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fourth ? (
                        <div className="unit">
                            <h4>Secondary Survey</h4>
                            <AssignmentTurnedInIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>If your casualty has an airway and is breathing then you must perform a secondary survey to ascertain any other injuries.</p>
                            <br />
                            <p><b>Recovery Position</b> - Having conducted a secondary survey and found no further injuries, it is essential to place the
                                casualty into the recovery position. This allows the person to maintain their own airway and breathing. When you place a
                                person into the recovery position, you must constantly monitor them and be prepared to resuscitate should they stop breathing.</p>

                        </div>
                    ) : (
                        <></>
                    )}
                    {fifth ? (
                        <div className="unit">
                        <h4>Check Your Knowledge</h4>
                        <div className="quiz">
                        <QuizIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem", margin: "auto", width: "100%", }}
                            />
                        <h4>{question[0].question}</h4>
                        <span style={{textAlign:"center"}}>Select the correct answer:</span>
                        <div className="answer-section">
                          {question[0].answers.map((d, i) => (
                            <><button onClick={() => handleAnswer(i)}><b>{i+1}</b> - {d.answerText}</button></>
                          ))}
                          {rightAnswer ? (
                            <h4>
                              Well done! That's the right answer!
                            </h4>
                          ) : (
                            <></>
                          )}
                          {wrongAnswer ? (
                            <h4>
                              That's wrong, correct answer was <b>Answer 1</b></h4>
                          ) : (
                            <></>
                          )}
                        </div>
                        </div>
                      </div>
                    ) : (
                        <></>
                    )}
                    
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!fifth ? (
                                    <>
                                        <Button
                                            onClick={() => prev()}
                                            variant="outlined"
                                            color="error"
                                            className="moveButton"
                                        >
                                            Prev
                                        </Button>
                                        <Button
                                            className="moveButton"
                                            onClick={() => next()}
                                            variant="outlined"
                                            color="error"
                                        >
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            {answered?
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                            :<></>
                                            }
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/core/hs")}
                                                        variant="contained"
                                                        color="success"
                                                        sx={{alignSelf: "center"}}
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button
                                className="moveButton"
                                onClick={() => next()}
                                variant="outlined"
                                color="error"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/core/hs")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>
            )}
            <Footer />
        </section>
    )
}