import Header from "../../Header";
import "../module.scss";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function HoagiesHome(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop} />
      <h3>Hoagies</h3>
      <div className="modules">
        <div
          className="module"
            onClick={() => navigate("/hoagies/wet-filling-wrap")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/wet_filling_wraps.webp"
            alt="wet filling wraps"
          />
          <div className="completion">
            <h2>Wet filling wraps</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/prep")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/prep.webp"
            alt="Prep"
          />
          <div className="completion">
            <h2>Prep</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/pizza-halves")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/pizza_halves.webp"
            alt="Pizza Halves"
          />
          <div className="completion">
            <h2>Pizza Halves</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/baguette")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/baguette.webp"
            alt="Baguette"
          />
          <div className="completion">
            <h2>Baguette</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/small-sub-roll")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/small_roll.webp"
            alt="Small sub roll"
          />
          <div className="completion">
            <h2>Small Sub Roll</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/ciabatta")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/ciabatta.webp"
            alt="Ciabatta"
          />
          <div className="completion">
            <h2>Ciabatta</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/quiche-salad-box")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/quiche_salad_box.webp"
            alt="Quiche Salad Box"
          />
          <div className="completion">
            <h2>Quiche Salad Box</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/chicken-salad")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/marinated_chicken_salad.webp"
            alt="Salad Bowl: Marinated Chicken"
          />
          <div className="completion">
            <h2>Salad Bowl: Marinated Chicken</h2>
          </div>
        </div>
        <div
          className="module"
            onClick={() => navigate("/hoagies/wet-filling-salad")}
        >
          <img
            src="https://nitro-training.s3.eu-west-2.amazonaws.com/hoagies/wet_filling_salad_box.webp"
            alt="Salad Bowl: Wet Filling"
          />
          <div className="completion">
            <h2>Salad Bowl: Wet Filling</h2>
          </div>
        </div>

      </div>
      <Footer />
    </main>
  );
}
