import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};


export default function DeliveryTablets(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Maintainance");
  const [child, setChild] = useState("DELIVERY TABLETS");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `DELIVERY TABLETS`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } 
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } 
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `CHECKING DATE ROTATION`,
      sampled: true,
    });
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete",
        data
      )
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }


  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Delivery Tablets</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                This course will teach you how to Accept a Snappy Shopper order and
                asign this to a Stuart Driver for collection as well as answereing some "What If?" questions.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                When a Snappy Shopper Order comes in this will be dislayed on the Snappy Tablet, you should then accept the order as soon as possible.
              </p>
              <ul>
                <li>
                  Review the items in the order and then check that you have stock of the products
                </li>
                <li>
                  Next asign the <b>Driver</b> on the Snappy Tablet to be <b>RaceTrack Stuart</b>
                </li>
                <li>
                  In the <b>Stuart App</b> select request pick up
                </li>
                <li>
                  The pick up address should already be set, for the drop off address enter the same address marked on the <b>snappy device</b>
                </li>
                <li>
                  Once the address appears then double check the postcode and address
                </li>
                <li>
                  In the "Company Name" section enter the flat number if necessary
                </li>
                <li>
                  If the order contains any age restricted items, this then needs to be entered in the order details : <b>CHALLANGE 25</b>
                </li>
                <li>
                  Or if the order contains alcohol then insert : <b>CHALLANGE 25 (ALCOHOL)</b>
                </li>
                <li>
                  The customers full name and mobile number must also be inserted
                </li>
                <li>
                  The size of the parcel will always be <b>SMALL</b>
                </li>
                <li>
                  Before requesting a driver double check all details as <b>you will be liable for any errors</b>
                </li>
                <li>
                  Finally request for a driver
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>
                Once you have requested for a delivery driver you must keep an eye on the tablet for a delivery driver to accept the order.
              </p>
              <ul>
                <li>
                  Pick the items they must be scanned through the till before being placed into a bag.
                </li>
                <li>
                  Double check all items in the bag against the order as <b>you will be liable for any errors</b>
                </li>
                <li>
                  Once the items are bagged up select Snappy Payment on the till and keep the receipt
                </li>
                <li>
                  Tear the address details from the Snappy Receipt off and staple this to the bag ready for the driver
                </li>
                <li>
                  Staple the other receipts together and keep them in the folder
                </li>
                <li>
                  Once the driver arrives make sure the full order is handed over and get the drivers name so we know its gone to the correct person
                </li>
                <li>
                  Then open the Snappy App and click en route
                </li>
                <li>
                  Finally once the order has cleared from the Stuart App and click delivered in the snappy shopper app
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>What If questions?</p>
              <p>What if we dont have one of the items?</p>
              <ul>
                <li>Look at which items we could sub it for, call customer and offer the replacement. </li>
                <li>If there is no reply from the customer then ask your manager, if no response the item should be refunded.</li>
              </ul>
              <p>What if no driver is assigned?</p>
              <ul>
                <li>If 15 mins has passed without a driver being assigned, click the chat button on the tablet, one of the tech helpers will be able to assist. If no luck here then re request the delivery. Wait until a driver accepts one and then cancel the other.</li>
              </ul>
              <p>What if I leave something out of the order or give the wrong item?</p>
              <ul>
                <li>If you leave something behind, you will be responsible for the cost of redilvery. This is why its so important to double check the order</li>
              </ul>
              <p>What if the postcode on the order doesn’t come up on the stuart tablet?</p>
              <ul>
                <li>Its possible the customer has put the wrong postcode on the order, in this case post onto your whatsapp group for a manager to assist. </li>
              </ul>
              <p>What if no addresses come up on the stuart tablet?</p>
              <ul>
                <li>This means you need to log in and out of the tablet. All managers have access to the login.</li>
              </ul>

            </div>
          ) : (
            <></>
          )}

          <div className="moveButtons">
            {!intro ? (
              <>
                {!third ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/retail/date-maintenance")}
                            variant="contained"
                            color="success"
                            sx={{ alignSelf: "center" }}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/retail/date-maintenance")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  )

}