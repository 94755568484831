import Header from "../../Header";
import "../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Footer from '../../Footer'


export default function CoreHome(props) {
  const navigate = useNavigate();
  const [staffFound, setStaffFound] = useState(localStorage.getItem("staff"));
  const [role, setRole] = useState("")
  const [site, setSite] = useState("")

  useEffect(() => {
    if (staffFound) {
      var data = JSON.parse(staffFound)
      setRole(data.role)
      setSite(data.site)
    }
  }, [])

  return (
    <main className="moduleHome">
      <Header stop={props.stop} />
      <h3>CORE</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/h%26s.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>H&amp;S</h2>
          </div>
        </div>
        {site !== "Head Office" ?
          <>
            <div className="module healthAndSafety" onClick={() => navigate("/core/customer-service")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/customer_service.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Customer Service</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/delivery")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/delivery.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Deliveries</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/maintanance.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Maintanance</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/tills")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/tills/RETURNS.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Tills</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/floor_maintenance.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Floor Maintenance</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/closing.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Closing Up</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/shelf-edge")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/coreHome/maintanance.png" alt="health &amp; safety" />
              <div className="completion">
                <h2>Shelf Edge Labels</h2>
              </div>
            </div>
          </>
          :
          <></>
        }
      </div>
      <Footer />
    </main>
  );
}
