import "../quizes.scss";
import { useState } from "react";
import CheckIcon from "@mui/icons-material/Check";

export default function FuelAuthQuiz(props) {
  const [smoke, setSmoke] = useState(false);
  const [lights, setLights] = useState(false);
  const [smokeReason, setSmokeReason] = useState(false);
  const [lightReason, setLightReason] = useState(false);
  const [smokeCheck, setSmokeCheck] = useState(false);
  const [lightCheck, setLightCheck] = useState(false);

  const handleSmoke = () => {
    setSmokeReason(true);
    setSmoke(true);
    setSmokeCheck(true)
    setTimeout(() => {
      setSmokeReason(false);
    }, 3000);
  };

  const handleLights = () => {
    setLightReason(true);
    setLights(true);
    setLightCheck(true)
    setTimeout(() => {
      setLightReason(false);
    }, 3000);
  };

  if (smoke === true && lights === true) {
    props.changeAnswered();
  }

  return (
    <div className="img-quiz">
      <h4>Click on the TWO reasons why you would not authorise these pump</h4>
      <img
        src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/Fuel-smoke.png"
        alt="quiz image"
      />
      <div className="smoke" onClick={() => handleSmoke()}>
        {smokeCheck ? <CheckIcon sx={{ color: "#03A63C" }} fontsize="large"/> : <></>}
      </div>
      <div className="lights" onClick={() => handleLights()}>
        {lightCheck ? <CheckIcon sx={{ color: "#03A63C" }} fontsize="large"/> : <></>}
      </div>
      {smokeReason ? (
        <div className="reason">
          <p>
            Well done! A smoking customer could spark fuel vapours and cause a
            fire
          </p>
        </div>
      ) : (
        <></>
      )}
      {lightReason ? (
        <div className="reason">
          <p>
            Well done! Lights on could mean the engine is on, which could spark
            fuel vapours and cause a fire
          </p>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
