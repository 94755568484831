import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";

export default function Mix(props){
    const navigate = useNavigate();

    return(
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>Making Mix</h3>
            <div className="unit">
            <p style={{color: "#8C8C8C", textAlign:"center"}}>This unit will be coming soon</p>
            <Button variant="outlined" color="success" onClick={()=> navigate("/tubbees/slush")} className="moveButton">Back</Button>
            </div>
            <Footer/>
        </section>
    )

}