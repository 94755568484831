import Header from "../../Header";
import "../module.scss";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function RetailHome(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Retail</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/date-maintenance")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/date_maintenance.png" alt="date maintenance retail" />
          <div className="completion">
            <h2>Date Maintenance (Retail)</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/vape-cigars")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/vapesCigars.png" alt="vape &amp; cigars" />
          <div className="completion">
            <h2>Vape &amp; Cigars</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/stock-filling")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/restock.png" alt="stock filling" />
          <div className="completion">
            <h2>Stock Filling</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/hoagies")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/hoagies.png" alt="hoagies" />
          <div className="completion">
            <h2>Hoagies</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/paypoint")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/payPoint.png" alt="paypoint" />
          <div className="completion">
            <h2>PayPoint</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/calor-flogas")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/calorGas.png" alt="calor/flogas" />
          <div className="completion">
            <h2>Calor/Flogas</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/electrical")}>
            <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/electrical.png" alt="electrical" />
            <div className="completion">
              <h2>Electrical</h2>
            </div>
          </div>
          <div className="module" onClick={() => navigate("/retail/jetwashes")}>
            <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/jet_wash.png" alt="jet washes" />
            <div className="completion">
              <h2>Jet Washes</h2>
            </div>
          </div>
      </div>
      <Footer />
    </main>
  );
}
