import { useState } from "react";
import "../styles/alcohol.css";
import Button from "@mui/material/Button";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import party from "party-js";
import BlockIcon from "@mui/icons-material/Block";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import LiquorIcon from "@mui/icons-material/Liquor";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import LocalPoliceIcon from "@mui/icons-material/LocalPolice";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function AlcoholTraining() {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false);
  const [eighth, setEighth] = useState(false);
  const [ninth, setNinth] = useState(false);
  const [tenth, setTenth] = useState(false);
  const [eleven, setEleven] = useState(false);
  const [twelve, setTwelve] = useState(false);
  const [thirteen, setThirteen] = useState(false);
  const [fourteen, setFourteen] = useState(false);
  const [fifteen, setFifteen] = useState(false);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    } else if (sixth === true) {
      setSixth(false);
      setSeventh(true);
    } else if (seventh === true) {
      setSeventh(false);
      setEighth(true);
    } else if (eighth === true) {
      setEighth(false);
      setNinth(true);
    } else if (ninth === true) {
      setNinth(false);
      setTenth(true);
    } else if (tenth === true) {
      setTenth(false);
      setEleven(true);
    } else if (eleven === true) {
      setEleven(false);
      setTwelve(true);
    } else if (twelve === true) {
      setTwelve(false);
      setThirteen(true);
    } else if (thirteen === true) {
      setThirteen(false);
      setFourteen(true);
    } else if (fourteen === true) {
      setFourteen(false);
      setFifteen(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    } else if (seventh === true) {
      setSixth(true);
      setSeventh(false);
    } else if (eighth === true) {
      setSeventh(true);
      setEighth(false);
    } else if (ninth === true) {
      setEighth(true);
      setNinth(false);
    } else if (tenth === true) {
      setTenth(false);
      setNinth(true);
    } else if (eleven === true) {
      setEleven(false);
      setTenth(true);
    } else if (twelve === true) {
      setTwelve(false);
      setEleven(true);
    } else if (thirteen === true) {
      setThirteen(false);
      setTwelve(true);
    } else if (fourteen === true) {
      setFourteen(false);
      setThirteen(true);
    } else if (fifteen === true) {
      setFifteen(false);
      setFourteen(true);
    }
  }

  function handleSubmit() {
    navigate("/alcohol-test");
  }

  return (
    <section className="moduleHome">
      <Header />
      <h3>Alcohol Training</h3>
      {intro ? (
        <div className="unit">
          <p>
            It is a condition of the Licensing Act 2005 that all licensed
            premises have an age verification policy in relation to the sale and
            supply of alcohol.
          </p>
          <p>
            We comply with <i>Challenge 25.</i> During the following course you
            will learn how to safely sell alcohol, apply challenge 25 and
            ifentify acceptable proof of age and spot false identification.
          </p>
        </div>
      ) : (
        <></>
      )}
      {first ? (
        <div className="unit">
          <h4>Licensing objectives</h4>
          <p>
            All individuals involved in the licensing process, including anyone
            who sells alcohol, must carry out their functions with a view to
            promoting the licensing objectives.
          </p>
          <ol>
            <li>Prevention of crime and disorder</li>
            <li>Public Safety</li>
            <li>The prevention of public nuisance</li>
            <li>The protection and improvement of public health</li>
            <li>The protection of children from Harm</li>
          </ol>
          <p>
            If licensed premises are operated in such a way as to undermine the
            licensing objectives, then this can result in a Premises License
            Review being carried out by the Licensing Authority. Selling alcohol
            to underage persons is an example of the sort of conduct that could
            lead to premises having its license reviewed.
          </p>
        </div>
      ) : (
        <></>
      )}
      {second ? (
        <div className="unit">
          <h3>Age verification</h3>
          <BlockIcon
            sx={{ color: "#bf1a1a", alignSelf: "center", marginBottom: "2%" }}
            fontSize="large"
          />
          <p>
            Under the Licensing Act 2005, it is mandaroty that an Age
            Verification policy applies in relation to the sale or supply of
            alcohol. This condition applies to any premises that has been
            granted a premises licence or a club premises certificate.
          </p>
          <p>
            The policy requires that individuals who appear (to a "responsible
            person") to be under 25 must produce proof of age identification.
          </p>
          <p>
            Failure to have such a policy in place is an offece. The main
            consequence is that you may make illegal sales of alcohol to
            underage customers. These customers might have been sent in by the
            local police or trading standards to carry out a test purchase. This
            offence carries an unlimited fine and most likely the lost of the
            premises licence.
          </p>
        </div>
      ) : (
        <></>
      )}
      {third ? (
        <div className="unit">
          <h3>Proof of Age - Challenge 25</h3>
          <p>
            Judging a person’s age from their appearance or behaviour is not an
            exact science! That is why you should adopt a policy of ‘Challenge
            25’, because the difference between the appearance of an 18 and
            16-year-old, and a person of 25 and a 16-year-old, should make it
            easier for you to decide who to challenge for a proof of age.
            Whether you adopt Challenge 25 is a matter for your Designated
            Premises Supervisor/Manager to decide.
          </p>
          <p>
            Applying your Challenge 25 policy means that team members working on
            the bar, counter or checkout, or supervisors supporting them, will
            need to:
          </p>
          <div className="row">
            <VisibilityIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Look carefully at the customer and make friendly eye-contact as
              early as possible, and before selling the customer an alcoholic
              drink – this will give you time to judge their age.
            </p>
          </div>
          <div className="row">
            <CreditScoreIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              If the customer is attempting to buy alcohol, decide whether they
              look at least 25 years old. If in doubt, ask the customer for ID
              and a proof of age.
            </p>
          </div>
          <div className="row">
            <BlockIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              If the customer can't provice acceptable evidence, you must refuse
              the sale.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {fourth ? (
        <div className="unit">
          <h4>Importance of eye contact</h4>
          <p>
            Early eye-contact and a friendly exchange of words will help you
            engage with the customer. It is good practice and helps breaks the
            ice. What’s more, the earlier you make eye-contact with customers,
            the more time you will have to judge their age and decide whether to
            ask for ID.
          </p>
        </div>
      ) : (
        <></>
      )}
      {fifth ? (
        <div className="unit">
          <h4>Making the decision</h4>
          <p>
            When deciding to challenge a person for proof of age, always follow
            <i>THE LAW</i>:
          </p>
          <div className="row">
            <LiquorIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>T</i>ake notice of the product the customer puts on the
              counter, or requests at the bar.
            </p>
          </div>
          <div className="row">
            <SavedSearchIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>H</i>ave a good look at the customer and judge if they look
              under 25.
            </p>
          </div>
          <div className="row">
            <VisibilityIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>E</i>arly eye-contact will give you time to judge and decide.
            </p>
          </div>
          <div className="row">
            <BlockIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>L</i>egal minimum age for purchasing alcohol is 18 years old -
              be vigilant.
            </p>
          </div>
          <div className="row">
            <CreditScoreIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>A</i>sk for ID and proof of age if the customer looks under 25:{" "}
              <i>No ID = No Sale</i>.
            </p>
          </div>
          <div className="row">
            <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>
              <i>W</i>ell done! You've just applied your premises policy and
              stayed within THE LAW.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {sixth ? (
        <div className="unit">
          <h4>How to challenge for proof of Age</h4>
          <i>Asking politely</i>
          <p>
            “It’s not what you say, it’s the way that you say it” – this is the
            message to remember when dealing with underage purchasers.
          </p>
          <div className="row">
            <DoneOutlineIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>Do:</p>
          </div>
          <ul>
            <li>Ask politely</li>
            <li>Explain why you are asking for proof of age</li>
            <li>Use humour, where appropriate and establish empathy</li>
            <li>
              Clarify which forms of ID and proof of age are acceptable and
              which are not
            </li>
          </ul>
          <div className="row">
            <DoNotDisturbOnIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>Don't:</p>
          </div>
          <ul>
            <li>
              Make personal comments abour the customer's age or appearence
            </li>
            <li>Belittle or mock the customer</li>
            <li>Be aggressive or threatening</li>
            <li>Get angry</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
      {seventh ? (
        <div className="unit">
          <h4>Dealing with conflict</h4>
          <p>
            If the customer cannot produce an acceptable form of ID and proof of
            age, you must refuse the sale. Saying “no” to a young customer needs
            to be done firmly, politely and without causing embarrassment. It is
            helpful if you can refer the young customer to point of sale
            material such as:
          </p>
          <div className="row">
            <BlockIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>The Challenge 25 poster</p>
          </div>
          <div className="row">
            <ReportGmailerrorredIcon
              fontSize="medium"
              sx={{ color: "#bf1a1a" }}
            />
            <p>The "No ID - No Sale" valid poster</p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {eighth ? (
        <div className="unit">
          <h4>Identifying which Proof Of Age ID is acceptable</h4>
          <div className="row">
            <DoneOutlineIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
            <p>Acceptable identification and proof of age include:</p>
          </div>
          <ul>
            <li>Passport</li>
            <li>
              Recognised proof of age card incorporating the PASS logo hologram
            </li>
            <li>UK photo driving licence</li>
            <li>Military ID cards</li>
          </ul>
          <p>
            Cards that incorporate PASS (Proof of Age Standards Scheme) logo and
            hologram include:
          </p>
          <ul>
            <li>Citizen card</li>
            <li>ONEID4U card</li>
            <li>Young Scot card</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
      {ninth ? (
        <div className="unit">
          <h4>Penalties for selling alcohol</h4>
          <p>
            There are significant penalties for selling alcohol to a person
            under the age of 18 for both team members and retailers. These
            include:
          </p>
          <ul>
            <li>
              A potentially unlimited fine if a team member makes an underage
              sale unless they can show they have taken all measures including
              asking for identification that would convince a reasonable person
              that they were over 18
            </li>
            <li>
              An on the spot fine of up to £90 for any shop worker selling to a
              person underage
            </li>
            <li>
              A potentially unlimited fine for a premises that persistently
              sells to people underage, defined as happening on 2 or more
              occasions in a 3 month period, under section 147a of the Police
              and Social Reform Responsibility Act 2011
            </li>
            <li>
              Closure orders for a maximum of 14 days following persistent
              underage sales, under section 169A of the Police and Social Reform
              Responsibility Act 2011
            </li>
            <li>
              A potentially unlimited fine for a person who purchases alcohol on
              behalf of a minor
            </li>
            <li>
              Review of your alcohol licence with the potential to lose it and
              have to cease trading
            </li>
            <li>Removal of a Designated Premises Supervisor</li>
          </ul>
        </div>
      ) : (
        <></>
      )}
      {tenth ? (
        <div className="unit">
          <h4>Pass Hologram</h4>
          <p>
            There are plenty of fake cards around, but one thing they have in
            common is that they don't carry the PASS logo. The unique PASS
            hologram is forge-proof and is also trademark registered, which
            makes unauthorised use of the logo a criminal offence. Trading
            standards officers regularly carry out test purchases and, to date,
            have not found any evidence of fake PASS cards.
          </p>
          <ul>
            <li>
              Nobody has ever been prosecuted for accepting a card containing a
              PASS hologram
            </li>
            <li>
              Virtually every failed test purchase is a failure to ask for a
              proof of age, not an acceptance of a false card
            </li>
            <li>
              Asking for, and accepting a card with a PASS hologram with the
              correct photo and date of birth is due diligence
            </li>
            <li>
              One third of 18 and 19 years old don't have a passport or driving
              licence so a PASS card may be their only form of ID
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
      {eleven ? (
        <div className="unit">
          <h4>Checking ID and Proof of Age</h4>
          <p>
            When a photo-ID card is produced, ask to examine the card by taking
            hold of it yourself (then, the cardholder can’t use a thumb/finger
            to cover any incriminating detail), and complete the following
            five-point check:
          </p>
          <ol>
            <li>Is the photo a true likeness?</li>
            <li>Ask the customer to confirm name and date of birth</li>
            <li>Check age - or calculate from date of birth</li>
            <li>Check for genuine PASS hologram</li>
            <li>
              Check the card – has it been tampered with? All PASS cards are
              smooth to touch when running a finger over the front/rear surfaces
              – a newly attached photo would create a raised feature (on a
              passport, a cut/incision near the photograph) which constitutes
              tampering
            </li>
          </ol>
          <p>
            If, after having seen the ID and proof of age produced, you still
            doubt that the customer meets the minimum age requirement, then the
            sale must be refused.
          </p>
        </div>
      ) : (
        <></>
      )}
      {twelve ? (
        <div className="unit">
          <h4>Commonly used fake ID</h4>
          <div className="row">
            <ReportGmailerrorredIcon
              fontSize="medium"
              sx={{ color: "#bf1a1a" }}
            />
            <p>
              National Identification Card - Made to look like a driving
              licence.
            </p>
          </div>
          <div className="row">
            <ReportGmailerrorredIcon
              fontSize="medium"
              sx={{ color: "#bf1a1a" }}
            />
            <p>
              International / European Driving Permit - International and
              European Driving Permits do not exist.
            </p>
          </div>
          <div className="row">
            <ReportGmailerrorredIcon
              fontSize="medium"
              sx={{ color: "#bf1a1a" }}
            />
            <p>
              Provisional Motorcycle Permit - The Provisional Motorcycle Permit
              does not exist.
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      {thirteen ? (
        <div className="unit">
          <h4>Refusing a Proxy Sale of Alcohol</h4>
          <p>
            A "proxy purchase" involves an attempt by a person who is at least
            18 years old, and has an acceptable proof of age, to purchase
            alcoholic drinks on behalf of persons who are underage. What might
            alert you to the possibility of a proxy purchase?
          </p>
          <ul>
            <li>
              The customer signalling to young people outside the premises or
              across the bar or lounge
            </li>
            <li>
              Younger customers "hanging back" while the older person attempts
              to get served
            </li>
            <li>
              Conversation and remarks overheard from a mixed froup of young
              customers, one of whom is "fronting" the purchase for the others
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
      {fourteen ? (
        <div className="unit">
          <h4>Dealing with objections</h4>
          <p>
            The customer’s objections might continue but the team member must
            remain firm in their refusal.
          </p>
          <p>
            Whilst it is the case that in a proxy purchase the offence is being
            committed by the buyer, not the seller, nevertheless, if the proxy
            purchaser is successful the customer might set off fireworks that
            are age restricted and allowing that would be an offence for which a
            responsible person could be prosecuted.
          </p>
          <p>
            You must remain vigilant and inspect the premises for potential
            underage drinkers. Anyone who appears under the age of 25 and seems
            to be drinking alcohol on the premises, must be challenged for proof
            of age.
          </p>
        </div>
      ) : (
        <></>
      )}
      {fifteen ? (
        <div className="unit">
          <h4>Action retailers can take to prevent underage sales</h4>
          <div className="row">
            <CastForEducationIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Provide any member of staff that sells alcohol age restricted
              sales training, including references to the application of the
              Challenge 25 scheme and proxy purchasing where appropriate, and
              provide refresher training on at least a yearly basis
            </p>
          </div>
          <div className="row">
            <BlockIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Ensure that the age verification policy has a clear policy on
              which proof of age documents are acceptable in store and provide
              training to staff to support this
            </p>
          </div>
          <div className="row">
            <LocalPoliceIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              If in doubt, contact the local authority or Primary Authority who
              will be able to assist in the development of a robust ID policy
            </p>
          </div>
          <div className="row">
            <QuickreplyIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Have an underage sales escalation process that can be followed in
              the event of a failed test purchase for alcohol and consider
              internal test purchasing to test the robustness of the ID policy
              and training
            </p>
          </div>
          <div className="row">
            <DesktopMacIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Have an in-built prompt on computerised systems for purchases that
              contain alcohol, which alerts staff and requires active assent to
              the sale and ensure that no alcohol sales through self-service
              tills can be made without authorisation from staff in store
            </p>
          </div>
          <div className="row">
            <SensorOccupiedIcon fontSize="large" sx={{ color: "#bf1a1a" }} />
            <p>
              Where operationally possible, have the Designated Premises
              Supervisors (DPS) for any store be based in that premises
            </p>
          </div>
          <p>
            On pressing test you will be taken to a different page, this test
            has to be done by all members of staff and will allow you to sell
            alcohol at the till. Please advise your manager once you have finish
            and passed the test.
          </p>
          <p>
            You need 18/20 to pass the test, if you failed you will be able to
            retake the test after a review of your answers.
          </p>
        </div>
      ) : (
        <></>
      )}
      <div className="moveButtons">
        {!intro ? (
          <>
            {!fifteen ? (
              <>
                <Button
                  onClick={() => prev()}
                  variant="outlined"
                  color="error"
                  className="moveButton"
                >
                  Prev
                </Button>
                <Button
                  className="moveButton"
                  onClick={() => next()}
                  variant="outlined"
                  color="error"
                >
                  Next
                </Button>
              </>
            ) : (
              <div className="unit">
                <div className="moveButtons" id="confetti">
                  <Button
                    className="moveButton"
                    onClick={() => prev()}
                    variant="outlined"
                    color="error"
                  >
                    Prev
                  </Button>
                  <Button
                    className="moveButton"
                    onClick={() => handleSubmit()}
                    variant="outlined"
                    color="success"
                  >
                    Test
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <Button
            className="moveButton"
            onClick={() => next()}
            variant="outlined"
            color="error"
          >
            Next
          </Button>
        )}
      </div>

      <Footer />
    </section>
  );
}
