import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function StockFillingHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Stock Filling</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/pick-&amp;-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Pick &amp; Mix</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/american-section")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>American Section</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/ambient-rotation")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Ambient Rotation</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/fresh-rotation")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Fridge Fresh Rotation</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/defrosting-correctly")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Defrosting Correctly</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/stocking-up")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Stocking Up Ingredients</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/sauce-top-up")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Sauce Top up</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/stock-filling/damages")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Damages</h2>
            </div>
          </div>

        </div>
        <Footer />
      </main>
    );

}