import logo from "../images/RT_TRAINING.png";
import { useNavigate } from "react-router-dom";
import '../styles/header.scss'
import logOutIcon from '../images/LOGOUT.png'
import { useEffect, useState } from "react"

export default function Header(props) {
  const navigate = useNavigate();
  const [name,setName]=useState("")

  useEffect(()=>{
    var staffFound = localStorage.getItem("staff")
    if(staffFound){
      var staff = JSON.parse(staffFound)
      setName(staff.name)
    }else{
      setName("Guest")
    }
  },[])

  function logOut() {
    props.stop();
   localStorage.removeItem("staff")
    navigate("/")
  }

  return (
    <header>
      <img
        src={logo}
        alt="logo"
        className="logo"
        onClick={() => navigate("/home")}
      />
      <div className="headerIcons">
        <h1 onClick={() => navigate("/accounts")}>{name}</h1>
        {/* <h2  style={{color:"white", marginRight:"15px"}}>⚙</h2> */}
        <img src={logOutIcon} alt="log Out" onClick={() => logOut()}/>
      </div>
    </header>
  );
}
