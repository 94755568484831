import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function Bottles(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Calor/Flogas")
    const [child, setChild] = useState("HOW TO SELL GAS BOTTLES")

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
          });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
            transaction.finish();
    }, []);


    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Calor Bottles`,
            sampled: true,
          });
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
            transaction.finish();
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>How To Sell Gas Bottles</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <p>
                            The Company has a strict Calor Gas Policy in which all employees must adhere to at all times. This company
policy outlines what employees' responsibilities are when it comes to dealing with customer calor gas
sales/transactions.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <p>The following rules always apply:</p>
                            <ul>
                                <li>
                                The customer must have an empty calor container only. Employees are instructed to check this before
continuing with the sale. Non-calor containers are not permitted.
                                </li>
                                <li>
                                Check for stock levels before continuing with the sale. It is good working practice to have this
knowledge at the beginning of your shift and keep yourself updated as the day progresses. However,
always check first.
                                </li>
                                <li>
                                If stock isn't available then a deposit sheet has to be filled in. This sheet will show the deposit for the
bottle and the refill charge attached to this. Employees should then enter this on the till in a similar
fashion as the deposit and refill.
                                </li>
                                <li>
                                The receipt for the above task should be printed off and stapled into the sheet and placed into the
appropriate folder. If you are not sure where this is kept then please ask a fellow colleague or your line
manager
                                </li>
                                <li>
                                When a customer is finished with the container, they can claim this deposit back at 'Calor Southside'
not through us.
                                </li>
                                <li>The employee doing the sale is fully responsible for the sale. You must take payment and give out the
bottle. Do not delegate this task to another employee. If the incorrect bottle was to be given out the
employee who started the sale would be liable for this</li>
                                <li>
                                All cages must be locked correctly. Security checks should be performed throughout your shift. A final
check should be done at the end of the day.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!first ? (
                                    <>
                                        <Button onClick={() => prev()} variant="outlined" color="error" className="moveButton">
                                            Prev
                                        </Button>
                                        <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/retail/calor-flogas")}
                                                        variant="contained"
                                                        color="success"
                                                        sx={{alignSelf: "center"}}
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/retail/paypoint")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>

            )}

            <Footer />
        </section>
    );
}
