import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Till(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Tills")
  const [child, setChild] = useState("FLOAT COUNTING")

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex(x => x.name === parent)
          const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);



  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Till Float`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Float</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The Company has a strict Cash Float Policy in which all employees
                must adhere to at all times. This company policy outlines what
                employees’ responsibilities are when it comes to cash control.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>The following rules always apply:</p>
              <ul>
                <li>
                  All employees who are assigned a float are responsible for
                  checking the float amount before they start their shift. If the
                  till is short then you may be liable for this discrepancy.
                  Therefore, it is vital you check your float at the beginning as
                  this will eliminate the chance of a shortage being someone else’s
                  fault.
                </li>
                <li>
                  You will sign a Till Agreement before you first work on a till.
                  This can be found on the staff portal under ‘Till Agreement’. This
                  provides you with a Till Point number and PayPoint number (if
                  applicable). Within this agreement you will sign the following
                  clause “I confirm that I have been given the above numbers to
                  access the systems as part of my employment with RaceTrack. I also
                  confirm I will not allow anyone else to use my till and that all
                  recorded discrepancies linked with these numbers will be solely my
                  responsibility to resolve as per the Deductions from Pay I have
                  previously signed”.
                </li>
                <li>
                  You will sign a Deduction from Pay Agreement as part of your New
                  Starter Form which further outlines your responsibilities when it
                  comes to cash control.
                </li>
                <li>
                  Drops should not be greater than £200. If your site is higher then
                  you will be notified accordingly.
                </li>
                <li>No other member of staff should be on your till.</li>
                <li>
                  If large discrepancies are found this will be fully investigated.
                </li>
                <li>
                  If you steal from your till or any other money from the company
                  you will be dismissed.
                </li>
                <li>
                  You must cash up your till at the end of the day and display your
                  end of day report accordingly
                </li>
                <li>
                  If you feel you need further training then please ask your line
                  manager. Your colleagues are also there to help but not when it
                  comes to taking or counting money
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!first ? (
                  <>
                    <Button onClick={() => prev()} variant="outlined" color="error" className="moveButton">
                      Prev
                    </Button>
                    <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="moveButtons" id="confetti">
                    <Button className="moveButton" onClick={() => prev()} variant="outlined" color="error">
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => handleSubmit()}
                      style={{ backgroundColor: "#03A64A" }}
                      variant="contained" color="success"
                    >
                      Submit
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                Next
              </Button>
            )}
          </div>

        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/tills")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>

      )}

      <Footer />
    </section>
  );
}
