import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";



export default function FloorMaintenanceHome(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>FLOOR MAINTENANCE</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/brushing")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/BRUSHING.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Brushing</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/mopping")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/MOPPING.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Mopping</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/wet-floor-signs")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/wet_floor_sign.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Wet Floor Signs</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/spillages")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Spillages</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/changing-mop-water")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/change_mop_water.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Changing Mop Waters</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/cleaning-glass")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Cleaning Glass</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/cleaning-shelves")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/shelves.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Cleaning Shelves</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/customer-observation")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/floorMaintenance/customer_observation.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Customer Observation</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/fronting-stock")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Fronting Stock</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/floor-maintenance/fridge-counter-display")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Fridge &amp; Counter Display</h2>
          </div>
        </div>


      </div>
      <Footer />
    </main>
  );
}
