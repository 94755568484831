import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function ChecksHome(props){
        const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Checks</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/checks/temp-checks")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Temperature Checks</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/checks/whatsapp")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Posting on WhatsApp</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/checks/freezer")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Freezer Level</h2>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );

}