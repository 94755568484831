import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Eyewash(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("H&S");
  const [child, setChild] = useState("EYEWASH STATION");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } 
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } 
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Eyewash Station Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Eyewash Station</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>Legal Requirements</h4>
              <p>
                Eye injuries should always be taken seriously, no matter how
                minor they appear to be. The delicate surface of the eye and
                surrounding area is more sensitive than the rest of our skin,
                and a small cut or contact with a harsh chemical can lead to
                infection and even blindness.
              </p>
              <p>
                Emergency eyewash requirements don’t apply to all businesses.
                So, when is an eyewash station required? Under the Management of
                Health and Safety at Work Regulations 1999 all businesses must
                conduct a risk assessment to assess if an eyewash stattion is
                needed in the premises.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>What is an eyewash station used for?</h4>
              <p>
                Eyewash stations are designed to be easy for anyone to use, even
                if they’ve never used one before or have impaired sight at the
                time. They should be installed in a sensible, intuitive place
                that is easy to reach from all areas of eye injury risk.
              </p>
              <p>
                Eyewash solution that portable eyewash stations are equipped
                with can either be distilled, sterile water or sterile saline
                solution. Here are some commonly asked questions about what can
                be used to irrigate the eyes.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>How long does eyewash solution last?</h4>
              <p>
                You should consult the manufacturer’s guidance for the shelf
                life of your eyewash solution.
              </p>
              <p>
                Tap water can be used to wash out eyes but it’s not necessarily
                the best option. The chlorine and other chemicals that may be
                present in the water can cause irritation to the eye, as well as
                the water temperature, both of which can make eye washing more
                difficult, uncomfortable, and less effective.
              </p>
              <p>
                There is also less of a guarantee that tap water is hygienic.
                Whereas sealed eyewash solution in bottles is sterile until the
                seal is broken, taps on a plumbed eyewash station present more
                risk of contamination if not maintained or cleaned properly or
                regularly.
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>Is eyewash the same as contact solution?</h4>
              <p>
                Contact lens solution is designed to disinfect contact lenses
                and clean them. It contains saline solution along with cleaning
                agents. The cleaning agents are designed to break down and
                remove organic material on contact lenses so they are hygienic
                for their next use. If you wash your eyes with contact lens
                solution it can damage their delicate membrane layers.
              </p>
              <p>
                The tear film of the eye is made up of three layers: the outer
                oil layer, the middle watery layer and the inner mucus layer.
                The two inner layers keep the eye moist and hydrated while the
                outer layer protects the inner ones by preventing evaporation of
                moisture.
              </p>
              <p>
                Contact lens solution can break down the outer oil layer of the
                eye making the middle and inner layers dry out and prone to
                irritation, infection, and injury.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>When to use the eyewash station</h4>
              <p>
                Use the emergency eye wash immediately if your eyes are exposed
                to a hazardous chemical. The first few seconds after exposure to
                a hazardous chemical (especially a corrosive chemical) are
                critical. Delaying treatment, even for a few seconds, may result
                in irreparable eye damage.
              </p>
              <p>
                A minimum 5-minute flushing time is recommended for mildly
                irritating chemicals, at least 20 minutes for
                moderate-to-severe irritants, 20 minutes for non-penetrating
                corrosives, and •at least 60 minutes for penetrating corrosives.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!fourth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core/hs")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/hs")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
