import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function DeliveryHome(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Deliveries</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/general-policy")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/general.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>General Policy</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/slush")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/slush.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Slush Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/ice-cream")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Ice Cream Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/frozen")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/frozen.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Frozen Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/disposables")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Disposables Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/ingredients")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/ingredients.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Ingredients Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/cumbernauld-cakes")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/cumbernauld_cakes.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Cumbernauld Cakes</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/londis")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/londis.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Londis Delivery</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/delivery/booker")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/deliveries/booker.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Booker Delivery</h2>
          </div>
        </div>

      </div>
      <Footer />
    </main>
  );
}
