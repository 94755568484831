import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};


export default function Soldering(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);
    const [fifth, setFifth] = useState(false);
    const [sixth, setSixth] = useState(false);
    const [seventh, setSeventh] = useState(false);
    const [eighth, setEighth] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("On-Site")
    const [child, setChild] = useState("SOLDERING")

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
        });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }, []);

    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        } else if (first === true) {
            setFirst(false);
            setSecond(true);
        } else if (second === true) {
            setSecond(false);
            setThird(true);
        } else if (third === true) {
            setThird(false);
            setFourth(true);
        } else if (fourth === true) {
            setFourth(false);
            setFifth(true);
        } else if (fifth === true) {
            setFifth(false);
            setSixth(true);
        } else if (sixth === true) {
            setSixth(false);
            setSeventh(true);
        } else if (seventh === true) {
            setSeventh(false);
            setEighth(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        } else if (second === true) {
            setSecond(false);
            setFirst(true);
        } else if (third === true) {
            setThird(false);
            setSecond(true);
        } else if (fourth === true) {
            setFourth(false);
            setThird(true);
        } else if (fifth === true) {
            setFifth(false);
            setFourth(true);
        } else if (sixth === true) {
            setSixth(false);
            setFifth(true);
        } else if (seventh === true) {
            setSixth(true);
            setSeventh(false);
          } else if (eighth === true) {
            setSeventh(true);
            setEighth(false);
          }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Nitro On-Site Smoldering Policy`,
            sampled: true,
        });
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop} />
            <h3>Safe Soldering</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <p>Make sure you know what is in your solder, it is not always apparent on the reel label. If in doubt, ask.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <p><b>Soldering Iron</b></p>
                            <ul>
                                <li>Never touch the element of the soldering iron....400°C!</li>
                                <li>Hold wires to be heated with tweezers or clamps.</li>
                                <li>Keep the cleaning sponge wet during use.</li>
                                <li>Always return the soldering iron to its stand when not in use. Never put it down on the workbench.</li>
                                <li>Turn unit off and unplug when not in use.</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    {second ? (
                        <div className="unit">
                            <p><b>Solder, Flux and Cleaners</b></p>
                            <ul>
                                <li>Wear eye protection. Solder can “spit”.</li>
                                <li>Use rosin-free and lead-free solders wherever possible.</li>
                                <li>Keep cleaning solvents in dispensing bottles.</li>
                                <li>Always wash your hands with soap and water after soldering.</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    {third ? (
                        <div className="unit">
                            <p><b>Lead Exposure</b></p>
                            <p>Lead can give rise to serious chronic health effects. Exposure will primarily be through accidental ingestion from your skin, wear gloves if directly handling solder. Limited fumes may be generated by soldering.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fourth ? (
                        <div className="unit">
                            <p><b>Control Of Fumes</b></p>
                            <p>Always ensure you are soldering where fumes can be easily extracted. This means working in an open, well-ventilated area. If possible, the use of an extraction system is highly recommended.</p>
                            <p>Do not solder if extract is not working properly and report immediately or if the area is confined an unventilated.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fifth ? (
                        <div className="unit">
                            <p><b>Training And Supervision</b></p>
                            <ul>
                                <li>Supervisors/line managers should inform users of the risks from soldering.</li>
                                <li>Supervisors/line managers should ensure that controls are in place and working and that they are used correctly.</li>
                                <li>All those soldering should be trained and supervised appropriately.</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    {sixth ? (
                        <div className="unit">
                            <p><b>Electrical Safety</b></p>
                            <ul>
                                <li>Do not use soldering irons that have obvious damage to body, cable or plug.</li>
                                <li>All soldering irons should have had electrical safety (PAT) testing within the last twelve months.</li>
                                <li>Keep the soldering station free of electrical cables to prevent damage from the heated tip.</li>
                                <li>Use a grounded outlet and grounding prong if a short circuit is a possibility.</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    {seventh ? (
                        <div className="unit">
                            <p><b>Fire Prevention</b></p>
                            <ul>
                                <li>Work on a fire-proof or fire-resistant surface.</li>
                                <li>Wear fire resistant clothing (e.g., 100% cotton) that covers your arms and legs to prevent accidental burns.</li>
                                <li>Know where your nearest fire extinguisher is and how to use it.</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    {eighth ? (
                        <div className="unit">
                            <p><b>First Aid</b></p>
                            <ul>
                                <li>Immediate place any burns under cold water for 15 minutes.</li>
                                <li>Report to a first aider if deep or extensive otherwise protect with a plaster (band-aid).</li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!eighth ? (
                                    <>
                                        <Button
                                            onClick={() => prev()}
                                            variant="outlined"
                                            color="error"
                                            className="moveButton"
                                        >
                                            Prev
                                        </Button>
                                        <Button
                                            className="moveButton"
                                            onClick={() => next()}
                                            variant="outlined"
                                            color="error"
                                        >
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/nitro/onsite")}
                                                        variant="contained"
                                                        color="success"
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button
                                className="moveButton"
                                onClick={() => next()}
                                variant="outlined"
                                color="error"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/core/hs")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>
            )}
            <Footer />
        </section>
    )
}