import "../module.scss";
import Header from "../../Header";
import Footer from "../../Footer";
import comingSoon from "../../../images/health&safety.webp";
import {useNavigate} from 'react-router-dom'

export default function ItHome(props) {
    const navigate = useNavigate()
    return(
  <main className="moduleHome">
    <Header stop={props.stop} />
    <h3>IT</h3>
    <div className="modules">
      <div className="module" onClick={() => navigate("/it/ts-card-machine")}>
        <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/it/Trouble-Shoot-Card-Machine.png"/>
        <div className="completion">
          <h2>Trouble Shoot Card Machine</h2>
        </div>
      </div>
      <div className="module" onClick={() => navigate("/it/log-it")}>
        <img src={comingSoon} />
        <div className="completion">
          <h2>Log IT Issue</h2>
        </div>
      </div>
      <div className="module" onClick={() => navigate("/it/till-settings")}>
        <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/it/Till-settings.png" />
        <div className="completion">
          <h2>Till Settings</h2>
        </div>
      </div>
      <div className="module" onClick={() => navigate("/it/racetrack-ordering")}>
        <img src={comingSoon} />
        <div className="completion">
          <h2>Accept RaceTrack Ordering Invoice</h2>
        </div>
      </div>

      <div className="module" onClick={() => navigate("/it/manual-invoice")}>
        <img src={comingSoon} />
        <div className="completion">
          <h2>Add Manual Invoice</h2>
        </div>
      </div>

    </div>
    <Footer />
  </main>
    )
}
