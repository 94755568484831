import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function ClosingUpHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Closing Up</h3>
        <div className="modules">
          <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up/take-out-bin")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Take Out Normal Bin</h2>
            </div>
          </div>
          <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up/food-bin")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Take Out Food Bin</h2>
            </div>
          </div>
          <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up/cleaning-tables")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cleaning Tables</h2>
            </div>
          </div>
          <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up/cleaning-seats")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Cleaning Seats</h2>
            </div>
          </div>
          <div className="module healthAndSafety" onClick={() => navigate("/core/closing-up/barriers-down")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Taking Barriers Down</h2>
            </div>
          </div>  
        </div>
        <Footer />
      </main>
    );  
}