import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function MixesHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Mixes</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/mixes/churro-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Churro Mix</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/mixes/creppe-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Crepe Mix</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/mixes/pancake-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Pancake Mix</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/mixes/nitro-mix")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Nitro Mix</h2>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );

}