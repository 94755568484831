import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function ManagementTasks(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Management Tasks</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/induction")}>
          <img src={health} alt="staff induction" />
          <div className="completion">
            <h2>Staff Induction</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/interviews")}>
          <img src={health} alt="staff interviews" />
          <div className="completion">
            <h2>Staff Interviews</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/hig")}>
          <img src={health} alt="staff hig" />
          <div className="completion">
            <h2>Staff HIG</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/procedures")}>
          <img src={health} alt="staff procedures" />
          <div className="completion">
            <h2>Staff Procedures</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/shop")}>
          <img src={health} alt="shop procedures local" />
          <div className="completion">
            <h2>Shop Procedures - Local</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/rotas")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/management/ROTAS.png" alt="rotas" />
          <div className="completion">
            <h2>Rotas</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/message")}>
          <img src={health} alt="message group chats" />
          <div className="completion">
            <h2>Message Group Chats</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/management-tasks/reconciliation")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/management/RECONCILIATION.png" alt="reconciliation" />
          <div className="completion">
            <h2>Reconciliation</h2>
          </div>
        </div>

      </div>
      <Footer />
    </main>
  );
}
