import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import InventoryIcon from '@mui/icons-material/Inventory';
import GridViewIcon from '@mui/icons-material/GridView';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HailIcon from '@mui/icons-material/Hail';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function ManualHandling(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);
    const [fifth, setFifth] = useState(false);
    const [sixth, setSixth] = useState(false);
    const [seventh, setSeventh] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("H&S")
    const [child, setChild] = useState("MANUAL HANDLING")

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
        });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }, []);

    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        } else if (first === true) {
            setFirst(false);
            setSecond(true);
        } else if (second === true) {
            setSecond(false);
            setThird(true);
        } else if (third === true) {
            setThird(false);
            setFourth(true);
        } else if (fourth === true) {
            setFourth(false);
            setFifth(true);
        } else if (fifth === true) {
            setFifth(false);
            setSixth(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        } else if (second === true) {
            setSecond(false);
            setFirst(true);
        } else if (third === true) {
            setThird(false);
            setSecond(true);
        } else if (fourth === true) {
            setFourth(false);
            setThird(true);
        } else if (fifth === true) {
            setFifth(false);
            setFourth(true);
        } else if (sixth === true) {
            setSixth(false);
            setFifth(true);
        }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Health And Safety Manual Handling Policy`,
            sampled: true,
        });
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop} />
            <h3>Manual Handling</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <h4>
                                In this module we will cover:
                            </h4>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <ListAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                                <p>The Manual Handling Operations Regulations (MHORs) as amended in 2002.</p>
                            </div>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <ManageSearchIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                                <p>Your responsibilities under the MHOR's.</p>
                            </div>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <InventoryIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                                <p>Definitions of "injury", "load" and "manual handling"</p>
                            </div>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <GridViewIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                                <p>How to risk assess the TILE acronym</p>
                            </div>
                            <div className="row" style={{ marginBottom: "10px" }}>
                                <AccessibilityNewIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                                <p>The correct lifting procedure</p>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <h4>The Manual Handling Operations Regulations (MHRO)</h4>
                            <HowToRegIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>MHRO aims to reduce the number of musculoskeletal disorders (MSDs) caused from the manual handling of loads at work.
                                MHOR places a duty upon employers and the self-employed regarding all work-related activity that involves manual handling.
                                Although MHRO does not cover volunteers, these categories of person may be covered by the
                                <b>Health and Safety at Work Act 1974 (HASAW)</b></p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {second ? (
                        <div className="unit">
                            <h4>Your Responsibilities</h4>
                            <LocalDrinkIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p>As an employee, you have responsibility to follow the training and methods devised by the
                                employer and not to do anything that could put you or others at harm</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {third ? (
                        <div className="unit">
                            <h4>Key Terms</h4>
                            <PriorityHighIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p><b>"Injury"</b> - damage to the body by external force (e.g. sprained ligament)</p>
                            <p><b>"Load"</b> - an object or person to be moved, held or placed into position</p>
                            <p><b>"Manual Handling Operations"</b> - using the body to lift, carry, push or pull a load</p>
                            <br />
                            <p><b>Best Practice Is:</b> if the manual handling risk cannot be eliminated, then consideration
                                should be given to automate or mechanise the movement of loads.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fourth ? (
                        <div className="unit">
                            <h4>TILE</h4>
                            <AssignmentTurnedInIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p><b><u>T</u></b>ask - Consider the task – what does it involve? i.e. lifting, lowering, carrying, pushing or pulling.
                                How might it affect your health and safety? Does the task involve repetitive movements,
                                strenuous movements, long distances and/or uneven weight distribution?</p>
                            <p><b><u>I</u></b>ndividual - Consider the task – what does it involve? i.e. lifting, lowering, carrying, pushing or pulling.
                                How might it affect your health and safety? Does the task involve repetitive movements, strenuous movements,
                                long distances and/or uneven weight distribution?</p>
                            <p><b><u>L</u></b>oad - Is the load to be moved: - heavy, bulky or unwieldy, unstable or with contents likely to shift or difficult to get hold of/grasp?</p>
                            <p><b><u>E</u></b> - Consider the area in which the load is being moved. Are there: space constraints preventing good posture? Uneven, slippery or unstable
                                floors? Variations in levels of floors or work surfaces? Is there enough lighting? Are there any trip hazards?</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {fifth ? (
                        <div className="unit">
                            <h4>Lifting Procedure</h4>
                            <HailIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p><b>Stop &amp; Think</b> - Plan the lift. Where is the load going and how is it going to get there?</p>
                            <p><b>Position Feet</b> - Stand with feet apart and distribute weight evenly on both feet.</p>
                            <p><b>Firm Grip</b> - The load should be held closely to the body, giving you more control and reducing muscle fatigue.</p>
                            <p><b>Good Posture</b> - Knees bent and slight bending of the back. Do not stoop the back as this can cause injuries.</p>
                            <p><b>Avoid Twisting</b> - Keep shoulders level and facing the same direction as your hips. Turn by moving your feet not twisting. </p>
                            <p><b>Keep Load Close</b> - Load should be kept close at waist level. Keep the heaviest side of the load next to your body.</p>
                            <p><b>Moving Load</b> - Move your feet keeping the load close to your body, making sure you can see where you are going.</p>
                            <p><b>Lowering Load</b> - Do this by reversing the lifting the lifting procedure.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {sixth ? (
                        <div className="unit">
                            <h4>Other Key Information</h4>
                            <DirectionsWalkIcon
                                className="unit-icon"
                                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
                            />
                            <p><b>Two-Person Lift</b> - If a load is too heavy for one person consider getting help from a fellow colleague. 
                            All the principles involved in a one-person lift should be used. One person should give the instructions ensuring 
                            the other person understands what needs to be done and when.</p>
                            <hr/>
                            <p><b>Personal Protective Clothing</b> - Your employer should provide appropriate PPE (e.g. Feet And/Or Hand Protection) to be used with manual handling tasks.</p>
                            <hr/>
                            <p><b>Manual Handling Devices</b> - Use of manual devices (e.g. trolleys, pallet trucks, etc.) should be considered where the load is too heavy, or it is to be 
                            moved a distance from its original location.</p>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!sixth ? (
                                    <>
                                        <Button
                                            onClick={() => prev()}
                                            variant="outlined"
                                            color="error"
                                            className="moveButton"
                                        >
                                            Prev
                                        </Button>
                                        <Button
                                            className="moveButton"
                                            onClick={() => next()}
                                            variant="outlined"
                                            color="error"
                                        >
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/core/hs")}
                                                        variant="contained"
                                                        color="success"
                                                        sx={{alignSelf: "center"}}
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button
                                className="moveButton"
                                onClick={() => next()}
                                variant="outlined"
                                color="error"
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/core/hs")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>
            )}
            <Footer />
        </section>
    )
}