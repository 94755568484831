import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


export default function Visitors(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);

  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Customer Service Retail");
  const [child, setChild] = useState("SITE VISITORS");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Site Visitors Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }
  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Site Visitors</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The Company has a Site Visitors Policy in which all employees
                must adhere to at all times. This company policy outlines the
                steps required in which employees must take in the event a
                visitor appears at the site or any of the offices.
              </p>
              <p>
                This policy applies to all of our employees. Whether you work at
                a site or in an office the following policy applies.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                When a visitor arrives in store, they need to sign in on the
                online visitor form.
              </p>
              <p>
                It is important this is done for Health and Safety purposes: for
                example on the event of a fire on site.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>How to use the online form:</h4>
              <p>
                On the store tablet or phone go to:
                https://visitors.racetrackpitstop.co.uk/ and click on the sign
                in tab.
              </p>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/cs/visitorTwo.webp" alt="Visitor" className="unit-img" />
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>
                On the sign in tab please select the site and click: <i>NEXT</i>
              </p>
              <p>
                For the next stage please hand the mobile phone to the Visitor
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/cs/visitorThree.webp" alt="Visitor" className="unit-img" />
              <p>
                Ensure the visitor fills name and company, then clicks{" "}
                <i>NEXT</i>
              </p>
              <p>A reason for visit must be filled on the next screen</p>
              <p>
                Finally on the last page make sure the visitor signs and clicks
                on the <i>Check in</i> button
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <p>
                When the visitor is leaving please ensure to go back into the
                sistem. On the first tab (status) they can find their name and
                click on <i>Check Out</i>
              </p>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/cs/visitorOut.webp" alt="visitor" className="unit-img" />
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!fifth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        style={{ backgroundColor: "#03A64A" }}
                        variant="contained"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    <Button
                      onClick={handleOpen}
                      variant="outlined"
                      color="warning"
                      sx={{ marginTop: "2%", alignSelf: "center" }}
                    >
                      View Policy
                    </Button>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      style={{ overflow: "scroll" }}
                    >
                      <Box sx={style}>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Drive Off Policy
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                          <>
                            <p>The following rules always apply:</p>
                            <ul>
                              <li>
                                If someone asks to speak with the manager you
                                must take the persons full name and their reason
                                for visiting.
                              </li>
                              <li>
                                The visitor must sign the Visitors Book on
                                arrival and then sign out when they leave.
                              </li>
                              <li>
                                If the manager is not available to take the
                                visitors query there and then you must ask them
                                politely to wait and inform them that the
                                manager will be with them as soon as possible.
                              </li>
                              <li>
                                If the manager is not working that day you must
                                inform them that at this time all you can do is
                                take down their information and pass it on.
                                Please ensure you do this with respect and
                                highlight to the visitor that their enquiry will
                                the dealt with.
                              </li>
                              <li>
                                Do not allow someone to come behind the till
                                area or back area without prior authorisation.
                                Even if they present identification to you. Keep
                                yourself right and get the authorisation first.
                              </li>
                              <li>
                                If you are new to the business and are not aware
                                of the Head Office staff then politely ask them
                                to prove their identification. All Head Office
                                workers will carry some sort of pass on them
                                when at the site.
                              </li>
                              <li>
                                If a visitor comes to the office door please
                                follow the same steps as above. Do not allow
                                someone to enter without gaining proper
                                identification and clearance.
                              </li>
                              <li>
                                If someone visits our site and there is a
                                scheduled fire alarm drill please advise them of
                                all the details, they will be required to know
                                i.e. the time of the alarm, location of the meet
                                up point, fire exit points.
                              </li>
                              <li>
                                Do not give a visitor other employee
                                information. This include the managers telephone
                                numbers. If you need to provide a telephone
                                (which includes the Police) then you are
                                instructed to only give the number for Head
                                Office.
                              </li>
                              <li>
                                All visitors to our site who are here for metre
                                readings are not permitted unless this has been
                                prior booked with Head Office. If you are unsure
                                of this booking then call 0141 319 8451
                                immediately. Do not allow access unless you have
                                approval first.
                              </li>
                            </ul>
                            <Button
                              variant="outlined"
                              color="success"
                              onClick={handleClose}
                              style={{ margin: "auto" }}
                            >
                              Close Policy
                            </Button>
                          </>
                        </Typography>
                      </Box>
                    </Modal>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core/customer-service")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}

                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/customer-service")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
          <Button
            onClick={handleOpen}
            variant="outlined"
            color="warning"
            sx={{ marginTop: "2%" }}
          >
            View Policy
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ overflow: "scroll" }}
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Visitors Policy
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <>
                  <p>The following rules always apply:</p>
                  <ul>
                    <li>
                      If someone asks to speak with the manager you must take
                      the persons full name and their reason for visiting.
                    </li>
                    <li>
                      The visitor must sign the Visitors Book on arrival and
                      then sign out when they leave.
                    </li>
                    <li>
                      If the manager is not available to take the visitors query
                      there and then you must ask them politely to wait and
                      inform them that the manager will be with them as soon as
                      possible.
                    </li>
                    <li>
                      If the manager is not working that day you must inform
                      them that at this time all you can do is take down their
                      information and pass it on. Please ensure you do this with
                      respect and highlight to the visitor that their enquiry
                      will the dealt with.
                    </li>
                    <li>
                      Do not allow someone to come behind the till area or back
                      area without prior authorisation. Even if they present
                      identification to you. Keep yourself right and get the
                      authorisation first.
                    </li>
                    <li>
                      If you are new to the business and are not aware of the
                      Head Office staff then politely ask them to prove their
                      identification. All Head Office workers will carry some
                      sort of pass on them when at the site.
                    </li>
                    <li>
                      If a visitor comes to the office door please follow the
                      same steps as above. Do not allow someone to enter without
                      gaining proper identification and clearance.
                    </li>
                    <li>
                      If someone visits our site and there is a scheduled fire
                      alarm drill please advise them of all the details, they
                      will be required to know i.e. the time of the alarm,
                      location of the meet up point, fire exit points.
                    </li>
                    <li>
                      Do not give a visitor other employee information. This
                      include the managers telephone numbers. If you need to
                      provide a telephone (which includes the Police) then you
                      are instructed to only give the number for Head Office.
                    </li>
                    <li>
                      All visitors to our site who are here for metre readings
                      are not permitted unless this has been prior booked with
                      Head Office. If you are unsure of this booking then call
                      0141 319 8451 immediately. Do not allow access unless you
                      have approval first.
                    </li>
                  </ul>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleClose}
                    style={{ margin: "auto" }}
                  >
                    Close Policy
                  </Button>
                </>
              </Typography>
            </Box>
          </Modal>
        </div>
      )}

      <Footer />
    </section>
  );
}
