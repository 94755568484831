import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Delivery(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Deliveries")
  const [child, setChild] = useState("GENERAL POLICY")

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex(x => x.name === parent)
          const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);


  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Delivery General Policy`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Deliveries</h3>
      {!completed ? (
        <>
              {intro ? (
        <div className="unit">
          <p>
            The Company has a Site Delivery Policy in which all employees must
            adhere to at all times. This company policy outlines what employees’
            responsibilities are when it comes to the site receive a delivery of
            any kind. Due to the nature of our Business we do receive a lot of
            deliveries. Therefore, it is important that employees understand
            this policy.
          </p>
        </div>
      ) : (
        <></>
      )}
      {first ? (
        <div className="unit">
          <p>The following rules always apply:</p>
          <ul>
            <li>
              When signing for a delivery please ensure that you know what it
              is. Do not sign for something before checking.
            </li>
            <li>
              As we have multiple sites in the Glasgow area, please ensure that
              the delivery is for your site.
            </li>
            <li>
              Check that every item is accounted for i.e. the total amount
              stated on the invoice agrees with the amount you can see.
            </li>
            <li>
              Make sure the cones are out for the delivery driver and wear a
              high vis top if required.
            </li>
            <li>
              Do not leave a delivery on the forecourt for any longer than it
              needs to be. If you do then this will open up the opportunity for
              people to steal.
            </li>
            <li>Check for any empty spaces on shelves in the shop.</li>
            <li>
              Open the box and scan the item. Ensure that the amount it scans at
              agrees with price on invoice. When putting item on shelf ensure
              that the item description, bar code and price agrees with shelf
              edge label. Please advise Manager of any differences in any of
              these.
            </li>
            <li>All employees must take their time.</li>
            <li>
              Check high value items first and do not allow the driver to leave
              the site without doing so.
            </li>
            <li>
              Check for dates on products especially chilled and frozen items.
            </li>
            <li>
              Put away the chilled and frozen delivery first, then move onto
              ambient.
            </li>
            <li>Place any tobacco out of sight of customers.</li>
            <li>
              If you receive a delivery that is for the owners then please post
              this onto the Group WhatsApp so that they are aware its at the
              site and place this into the office in a safe and secure location.
              Do not open.
            </li>
          </ul>
        </div>
      ) : (
        <></>
      )}
            <div className="moveButtons">
        {!intro ? (
          <>
            {!first ? (
              <>
                <Button onClick={() => prev()} variant="outlined" color="error" className="moveButton">
                  Prev
                </Button>
                <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                  Next
                </Button>
              </>
            ) : (
              <div className="moveButtons" id="confetti">
                <Button className="moveButton" onClick={() => prev()} variant="outlined" color="error">
                  Prev
                </Button>
                <Button
                  className="moveButton"
                  onClick={() => handleSubmit()}
                  style={{ backgroundColor: "#03A64A" }}
                  variant="contained" color="success"
                >
                  Submit
                </Button>
              </div>
            )}
          </>
        ) : (
          <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
            Next
          </Button>
        )}
      </div>
        </>
      ):(
        <div className="completed">
        <img src={complete} />
        <h2>This unit has been completed.</h2>
        <p>Do you want to do it again?</p>
        <div className="moveButtons" id="confetti">
          <Button
            className="moveButton"
            onClick={() => setCompleted(false)}
            variant="outlined"
            color="error"
          >
            Yes
          </Button>
          <Button
            className="moveButton"
            onClick={() => navigate("/core/delivery")}
            variant="outlined"
            color="success"
          >
            No
          </Button>
        </div>
      </div>

      )}

      <Footer />
    </section>
  );
}
