import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Electrical(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Electrical</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/electrical/cabinet")}>
          <img src={health} alt="electrical cabinet" />
          <div className="completion">
            <h2>Electrical Cabinet</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/electrical/lighting")}>
          <img src={health} alt="lighting switches" />
          <div className="completion">
            <h2>Lighting Switches</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
