import React, { useState, useEffect } from "react";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import Droppable from "./Droppable";
import Item from "./Item";
import { arrayMove, insertAtIndex, removeAtIndex } from "./array";
import Button from "@mui/material/Button";

export default function AllergenQuiz(prop) {

  useEffect(() => {

  })

  const [allergenScore, setAllergenScore] = useState(0);
  const [nonScore, setNonScore] = useState(0);
  const [checkScore, setCheckScore] = useState(false);
  const [passed, setPassed] = useState(false);
  const [unsortedNotEmpty, setUnsortedNotEmpty] = useState(false);

  //ALLERGEN LIST "Milk", "Gluten", "Fish", "Crustaceans", "Soya"
  //NON ALLERGEN LIST "Paper", "Water", "Pepsi"

  const [itemGroups, setItemGroups] = useState({
    Unsorted: ["Milk", "Paper", "Gluten", "Fish","Pepsi", "Crustaceans", "Soya", "Water"],
    Allergens: [],
    NonAllergens: [],
  });

  const [activeId, setActiveId] = useState(null);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const handleDragStart = ({ active }) => {
    setActiveId(active.id);
  }

  const handleDragCancel = () => setActiveId(null);

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;

    if (!overId) {
      return;
    }

    const activeContainer = active.data.current.sortable.containerId;
    const overContainer = over.data.current?.sortable.containerId || over.id;

    if (activeContainer !== overContainer) {
      setItemGroups((itemGroups) => {
        const activeIndex = active.data.current.sortable.index;
        const overIndex =
          over.id in itemGroups
            ? itemGroups[overContainer].length + 1
            : over.data.current.sortable.index;

        return moveBetweenContainers(
          itemGroups,
          activeContainer,
          activeIndex,
          overContainer,
          overIndex,
          active.id
        );
      });
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) {
      setActiveId(null);
      return;
    }

    if (active.id !== over.id) {
      const activeContainer = active.data.current.sortable.containerId;
      const overContainer = over.data.current?.sortable.containerId || over.id;
      const activeIndex = active.data.current.sortable.index;
      const overIndex =
        over.id in itemGroups
          ? itemGroups[overContainer].length + 1
          : over.data.current.sortable.index;

      setItemGroups((itemGroups) => {
        let newItems;
        if (activeContainer === overContainer) {
          newItems = {
            ...itemGroups,
            [overContainer]: arrayMove(
              itemGroups[overContainer],
              activeIndex,
              overIndex
            )
          };
        } else {
          newItems = moveBetweenContainers(
            itemGroups,
            activeContainer,
            activeIndex,
            overContainer,
            overIndex,
            active.id
          );
        }

        return newItems;
      });
    }

    setActiveId(null);
  };

  const moveBetweenContainers = (
    items,
    activeContainer,
    activeIndex,
    overContainer,
    overIndex,
    item
  ) => {
    return {
      ...items,
      [activeContainer]: removeAtIndex(items[activeContainer], activeIndex),
      [overContainer]: insertAtIndex(items[overContainer], overIndex, item)
    };
  };

  //ALLERGEN LIST "Milk", "Gluten", "Fish", "Crustaceans", "Soya"
  //NON ALLERGEN LIST "Paper", "Water", "Pepsi"

  function handleClick() {
    var algnScore = 0;
    var nonScore = 0;

    if (itemGroups.Unsorted.length != 0) {
      setUnsortedNotEmpty(true);
      return;
    }
    else {
      setUnsortedNotEmpty(false);

      itemGroups.Allergens.forEach(item => {
        if (item === "Milk" || item === "Gluten" || item === "Fish" || item === "Crustaceans" || item === "Soya") {
          algnScore++
        }
      });

      itemGroups.NonAllergens.forEach(item => {
        if (item === "Paper" || item === "Water" || item === "Pepsi") {
          nonScore++
        }
      });
    }

    if (algnScore === 5 && nonScore === 3) {
      prop.handlePassed();
    }

    setCheckScore(true);
    setAllergenScore(algnScore);
    setNonScore(nonScore);

  }



  return (
    <div className="AllergenQuiz">

      <h1>Check Your Knowledge</h1>

      <p>Search through the Unsorted list and sort the items into Allergens and Non Allergens, you can do this by dragging and dropping</p>
      <p>To pass you need to correctly identify all <b>5 Allergens</b> and all <b>3 Non Allergens</b></p>

      <br/>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className="dropZones">
          {Object.keys(itemGroups).map((group) => (
            <Droppable
              id={group}
              items={itemGroups[group]}
              activeId={activeId}
              key={group}
            />
          ))}
        </div>
        <DragOverlay>
          {activeId ? <Item id={activeId} dragOverlay /> : null}
        </DragOverlay>
      </DndContext>

      <br/>

      <Button onClick={() => handleClick()} 
        variant="outlined"
        color="success"
        >
          Check Result
      </Button>
      <br/>
      {unsortedNotEmpty ? (
        <p>The Unsorted List still has items inside it</p>
      ) : (
        <>
          {checkScore ? (
            <>
              {allergenScore !== 5 || nonScore != 3 ? (
                <>
                  {/* <p>You Scored {allergenScore} / 5 Allergens</p>
                  <p>You Scored {nonScore} / 3 Non Allergens</p> */}

                  {itemGroups.Allergens.length !== 5 || itemGroups.NonAllergens.length !== 3 ? (
                    <p>You need to find all <b>5 Allergens</b> and <b>3 Non Allergens</b></p>
                  ) : (
                    <>
                      <p>You Scored {allergenScore} / 5 Allergens</p>
                      <p>You Scored {nonScore} / 3 Non Allergens</p>
                    </>
                  )}
                </>
              ) : (
                <p><b>Congrats</b>, you managed to identify all Allergens and Non Allergens</p>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      )}






    </div>
  );
}
