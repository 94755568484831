import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function Hoagies(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Hoagies</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/hoagies/filling")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/coffee.png" alt="filling beans coffee machine" />
          <div className="completion">
            <h2>Filling Beans Coffee Machine</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/hoagies/cleaning")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/coffeeMachineClean.png" alt="cleaning coffee machine" />
          <div className="completion">
            <h2>Cleaning Coffee Machine</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/hoagies/temp")}>
          <img src={health} alt="temp checks" />
          <div className="completion">
            <h2>Temp Checks</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/hoagies/maintenance")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/date_maintenance.png" alt="date maintenance food" />
          <div className="completion">
            <h2>Date Maintenance (Food)</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
