import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Paypoint(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Paypoint</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/paypoint/login")}>
          <img src={health} alt="login" />
          <div className="completion">
            <h2>Login</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/paypoint/process-payment")}>
          <img src={health} alt="process payment" />
          <div className="completion">
            <h2>Process Payment</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/paypoint/payment-methods")}>
          <img src={health} alt="payment methods" />
          <div className="completion">
            <h2>Payment Methods</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/paypoint/general-policy")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/general-policy.png" alt="payment methods" />
          <div className="completion">
            <h2>General Policy</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
