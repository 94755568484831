import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function HealthAndSafety(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("H&S")
  const [child, setChild] = useState("GENERAL POLICY")

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex(x => x.name === parent)
          const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Health And Safety General Policy`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Health &amp; Safety</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The aim of the Health and Safety policy is to be fully compliant
                with the requirements and regulations of the Health and Safety
                Work Act 1974 (and any other relevant statutory provisions). The
                purpose of the policy is to ensure as far as it is reasonably
                practical that all employees, customers and visitors to our
                sites are safe. It is the responsibility of every individual
                within the business to refer to this policy and to ensure that
                they are aware of its latest version. The full and updated
                publications of the Health and Safety Policy will be made
                available via the staff portal.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                The general focus of the Health and Safety Policy is to ensure
                that we actively try to minimise and reduce accidents in the
                workplace. Reasonable practical steps will be taken to protect
                the health, safety and welfare of the workforce. In order for
                the company to achieve this, effective health and safety
                management from all is required. Our commitments to health and
                safety are as follows:
              </p>
              <ul>
                <li>
                  To provide a safe and enjoyable work environment for all
                  employees, customers and visitors.
                </li>
                <li>Provide safe access and egress to the workplace. </li>
                <li>
                  To implement not only reactive maintenance to the sites, but
                  to actively plan improvements where necessary.
                </li>
                <li>
                  To ensure that all employees are fully trained, competent and
                  confident in carrying out their daily duties.
                </li>
                <li>
                  To consult with all employees with matters they feel could
                  affect their health and safety.
                </li>
              </ul>
              <p>
                The company would like to ensure that this policy reflects
                current business practice, therefore new legislative changes,
                updates to polices and new implementations may appear from time
                to time.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>
                To ensure the Health and Safety policy is upheld at all times,
                it is the responsibility of both the employer and employee to
                ensure they are both aware of their duties such as:
              </p>
              <i>Employer:</i>
              <ul>
                <li>
                  Identify what the risks are and what the reasonable
                  precautions are to take to prevent accidents occurring.
                </li>
                <li>
                  To clearly explain to all employees how risks will be
                  controlled and to tell you who is responsible for this.
                </li>
                <li>
                  Consult with the Health and Safety representative in order to
                  protect the work force.
                </li>
                <li>
                  To provide free of charge Health and Safety training to all
                  employees and provide the necessary PPE in order to perform
                  their duties.
                </li>
                <li>
                  To provide adequate facilities such as bathrooms, drinking
                  water, washing facilities and first aid facilities.
                </li>
              </ul>
              <i>Employee:</i>
              <ul>
                <li>Follow all training provided to them.</li>
                <li>
                  Take responsibility for your Health and Safety and for others
                  by cooperating with your employer regarding Health and Safety
                  matters.
                </li>
                <li>
                  Notify immediately your employer, Manager or Health and Safety
                  representative if you feel there are any Health and Safety
                  risks.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>
                Training will be provided to all staff in order for them to
                competently perform their duties. The expectation of the
                employee is to follow all rules and procedures. General training
                will be provided upon your induction to the company. Specialised
                training will be further provided throughout your employment
                such as being trained on how to safely use a new piece of
                equipment. Do not perform tasks whatever that may be unless you
                have been trained and feel you can safely do so. If you feel
                there are gaps in your knowledge or skills regarding Health and
                Safety, make this aware to either your employer, Manager or
                Health and Safety representative.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <p>
                Risk assessments will be completed for every site by your
                employer. The purpose of this is to reduce the risk of accidents
                occurring in the workplace. Risk assessments will be reviewed
                every three years as per legislation; however, changes and
                adaptions could appear from time to time as the nature of the
                business changes/develops.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <p>
                All sites are provided with an accident report book. All
                employees are responsible for accurately recording any accident
                or incidents which occur. Make sure you are aware of where this
                is kept at the site. Any incident big or small needs to be
                recorded and then followed up by informing Head Office.{" "}
              </p>
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <div className="unit">
              <p>
                Inspections of the sites will be conducted at a minimum of 6
                months. The purpose of this is to ensure that the sites are
                fully compliant with not only the companies Health and Safety
                procedures, but also that of the law. Findings of these audits
                will be published to the Manager and Head Office only. Anything
                identified throughout the audit will be actioned accordingly.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!sixth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core/hs")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core/hs")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
