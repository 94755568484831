import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import unicorn from "./images/pancake-unicorn.webp";
import mermaid from "./images/pancake-mermaid.webp";
import oreo from "./images/pancake-oreo.webp";
import brownie from "./images/pancake-brownie.webp";
import bueno from "./images/pancake-whiteBueno.webp";
import peanut from "./images/pancake-Poverload.webp";
import banoffee from "./images/pancake-banoffee.webp";
import ferrero from "./images/pancake-ferrero.webp";
import nutella from "./images/pancake-nutella.webp";
import heaven from "./images/pancake-heaven.webp";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function DecoPancakes(props){
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [fourth, setFourth] = useState(false);
    const [fifth, setFifth] = useState(false);
    const [sixth, setSixth] = useState(false);
    const [seventh, setSeventh] = useState(false);
    const [eigth, setEigth] = useState(false);
    const [nine, setNine] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Baking/Making Products");
    const [child, setChild] = useState("PANCAKE");
  
    useEffect(() => {
      var data = localStorage.getItem("staff");
      data = JSON.parse(data);
      const transaction = Sentry.startTransaction({
        name: `Get Staff Details`,
        sampled: true,
      });
      axios
        .get(
          `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
        )
        .then((res) => {
          if (res.data) {
            localStorage.removeItem("staff");
            localStorage.setItem("staff", JSON.stringify(res.data));
            // console.log(res.data);
            setEmployee(res.data);
            const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
            const childCat = res.data.tasks[parentCat].taskData.findIndex(
              (x) => x.task === child
            );
            if (res.data.tasks[parentCat].taskData[childCat].competent) {
              setCompleted(true);
            }
          }
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      transaction.finish();
    }, []);
  
    function next() {
      if (intro === true) {
        setIntro(false);
        setFirst(true);
      } else if (first === true) {
        setFirst(false);
        setSecond(true);
      } else if (second === true) {
        setSecond(false);
        setThird(true);
      } else if (third === true) {
        setThird(false);
        setFourth(true);
      } else if (fourth === true) {
        setFourth(false);
        setFifth(true);
      } else if (fifth === true) {
        setFifth(false);
        setSixth(true);
      } else if (sixth === true) {
        setSixth(false);
        setSeventh(true);
      } else if (seventh === true) {
        setSeventh(false);
        setEigth(true);
      } else if (eigth === true) {
        setEigth(false);
        setNine(true);
      }
    }
  
    function prev() {
      if (first === true) {
        setFirst(false);
        setIntro(true);
      } else if (second === true) {
        setSecond(false);
        setFirst(true);
      } else if (third === true) {
        setThird(false);
        setSecond(true);
      } else if (fourth === true) {
        setFourth(false);
        setThird(true);
      } else if (fifth === true) {
        setFifth(false);
        setFourth(true);
      } else if (sixth === true) {
        setSixth(false);
        setFifth(true);
      } else if (seventh === true) {
        setSeventh(false);
        setSixth(true);
      } else if (eigth === true) {
        setEigth(false);
        setSeventh(true);
      } else if (nine === true) {
        setNine(false);
        setEigth(false);
      }
    }
  
    function handleSubmit() {
      var data = {
        staffTrainingId: employee.staffTrainingId,
        parent: parent,
        child: child,
      };
      const transaction = Sentry.startTransaction({
        name: `Submit pancake making completion`,
        sampled: true,
      });
      axios
        .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
        .then((res) => {
          party.confetti(document.getElementById("confetti"), confettiSettings);
          setUnit(true);
        })
        .catch((err) => {
          Sentry.captureException(err);
        });
      transaction.finish();
    }
  
    return (
      <section className="moduleHome">
        <Header stop={props.stop} />
        <h3>Pancakes</h3>
        {!completed ? (
          <>
            {intro ? (
              <div className="unit">
                <h4>Unicorn</h4>
                <p>Ingredients</p>
                <ul>
                  <li>White Chocolate Sauce</li>
                  <li>Milk Chocolate Sauce</li>
                  <li>Fizzers</li>
                  <li>Parma Violets</li>
                  <li>Unicorn Sprinkles</li>
                  <li>Pink &amp; Blue Sherbet</li>
                  <li>Pink Candy Floss</li>
                  <li>Unicorn Horn</li>
                  <li>Whipped Cream</li>
                  <li>Wafer</li>
                </ul>
                <p>Method</p>
                <ol>
                  <li>Drizzle hot white chocolate sauce on grease proof paper</li>
                  <li>
                    Drizzle hot milk chocolate sauce on each layer of pancake
                  </li>
                  <li>
                    1 packet of fizzers and parma violets sprinkled along with
                    unicorn sprin- kles and pink &amp; blue sherbet
                  </li>
                  <li>Place a piece of pink candy floss in middle of crepe</li>
                  <li>Place unicorn horn in centre of candy floss</li>
                  <li>
                    Spray whipped cream to four sides of plate with pink &amp;
                    blue sherbet dusted on top of the cream
                  </li>
                  <li>
                    Serve with a scoop of cotton candy ice cream in white jar
                    topped with a wafer and unicorn sprinkles
                  </li>
                </ol>
                <img
                  src={unicorn}
                  alt="unicorn pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {first ? (
              <div className="unit">
                <h4>Mermaid</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Milk Chocolate Sauce</li>
                  <li>White Chocolate Sauce</li>
                  <li>Marshmallow</li>
                  <li>Smarties</li>
                  <li>Rainbow Sprinkles</li>
                  <li>Blue &amp; Pink Sherbet</li>
                  <li>Icing Sugar</li>
                  <li>Pink Candy Floss</li>
                  <li>Mermaid Tail</li>
                  <li>Whipped Cream</li>
                  <li>Cotton Candy Ice Cream</li>
                  <li>Wafer</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce on to grease proof paper
                  </li>
                  <li>
                    Drizzle hot white chocolate sauce on each layer of pancake
                  </li>
                  <li>
                    Sprinkle marshmallow, smarties, rainbow sprinkles and blue
                    &amp; pink sherbet over top of the stack
                  </li>
                  <li>Speckle icing sugar over top</li>
                  <li>Place pink candy floss in centre of pancake</li>
                  <li>Place mermaid tail in centre of candy floss</li>
                  <li>Put whipped cream on the side tub</li>
                  <li>
                    Served with a scoop of cotton candy ice cream in white jar
                    topped with a wafer
                  </li>
                </ol>
                <img
                  src={mermaid}
                  alt="mermaid pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {second ? (
              <div className="unit">
                <h4>Oreo</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>4 Oreos</li>
                  <li>Milk Chocolate Flakes</li>
                  <li>Icing Sugar</li>
                  <li>Whipped Cream</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce onto grease proof paper
                  </li>
                  <li>
                    Drizzle hot white chocolate sauce (x3) on each layer of
                    pancake
                  </li>
                  <li>Crush 4 oreos and sprinkle over the top of the stack</li>
                  <li>Sprinkle milk chocolate flakes over top</li>
                  <li>Speckle icing sugar</li>
                  <li>Spray whipped cream on to four sides of the plate</li>
                  <li>Sprinkle more crushed oreo over the top of the box</li>
                </ol>
                <img src={oreo} alt="Oreo pancakes" className="tubbbees-img" />
              </div>
            ) : (
              <></>
            )}
            {third ? (
              <div className="unit">
                <h4>Brownie</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Brownie</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce onto grease proof paper
                  </li>
                  <li>Drizzle hot milk chocolate on each layer of pancake</li>
                  <li>Cut one brownie in to 9 pieces</li>
                  <li>
                    Place 2 pieces in each quarter section and 1 in the centre on
                    top of the stack
                  </li>
                  <li>Speckle icing sugar over plate</li>
                </ol>
                <img
                  src={brownie}
                  alt="Brownie pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {fourth ? (
              <div className="unit">
                <h4>Chcoolate Heaven</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>White Chocolate Sauce</li>
                  <li>Smarties</li>
                  <li>White and Milk Chocolate Curls</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce onto grease proof paper
                  </li>
                  <li>
                    Drizzle hot milk chocolate sauce on bottom and top pancake
                    with white chocolate sauce for middle pancake
                  </li>
                  <li>Sprinkle smarties on top of stack</li>
                  <li>Sprinkle white and milk chocolate curls over stack</li>
                  <li>Speckle with icing sugar</li>
                </ol>
                <img
                  src={heaven}
                  alt="Chocolate Heaven pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {fifth ? (
              <div className="unit">
                <h4>White Chocolate Mess</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Hot White Chocolate Sauce</li>
                  <li>White Chocolate Bueno</li>
                  <li>White Chocolate Curls</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce onto grease proof paper
                  </li>
                  <li>Drizzle hot white chocolate on each pancake</li>
                  <li>Break up full Bueno sections and spread over stack</li>
                  <li>Sprinkle white chocolate curls</li>
                  <li>Speckle icing sugar over top</li>
                </ol>
                <img
                  src={bueno}
                  alt="White Chocolate Mess pancake"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {sixth ? (
              <div className="unit">
                <h4>Peanut Overload</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Peanut Butter</li>
                  <li>One Large Peanut Butter Cup</li>
                  <li>Four Small Peanut Butter Cup</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>
                    Drizzle hot milk chocolate sauce onto grease proof paper
                  </li>
                  <li>
                    Add a scoop of peanut butter to the centre each layer of
                    pancake
                  </li>
                  <li>Drizzle hot milk chocolate to each layer of pancake</li>
                  <li>
                    Place one large peanut butter cup on top layer using peanut
                    butter as adhesive
                  </li>
                  <li>Add 4 small peanut butter cups on top of stack</li>
                  <li>Drizzle hot milk chocolate sauce over top of stack</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={peanut}
                  alt="Peanut overload pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {seventh ? (
              <div className="unit">
                <h4>Banoffee</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Toffee Sauce</li>
                  <li>Banana</li>
                  <li>Toffee Pieces</li>
                  <li>Whipped Cream</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle toffee sauce on grease proof paper</li>
                  <li>Drizzle toffee sauce on each layer of pancake</li>
                  <li>Slice half banana and spread over top of stack</li>
                  <li>Sprinkle toffee pieces on top</li>
                  <li>Spray whipped cream on four sides of the plate</li>
                  <li>Drizzle toffee sauce over stack</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={banoffee}
                  alt="Banoffee pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {eigth ? (
              <div className="unit">
                <h4>Ferrero</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Hot Nutella</li>
                  <li>Four Crushed Ferrero</li>
                  <li>Nut Pieces</li>
                  <li>Whipped Cream</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>Drizzle hot Nutella on each layer of pancake</li>
                  <li>Place four crushed Ferrero on top of the stack</li>
                  <li>Sprinkle nut pieces over stack</li>
                  <li>Spray whipped cream on four sides of the plate</li>
                  <li>Drizzle Nutella over stack</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={ferrero}
                  alt="Ferrero pancakes"
                  className="tubbbees-img"
                />
              </div>
            ) : (
              <></>
            )}
            {nine ? (
              <div className="unit">
                <h4>Nutella Lover</h4>
                <p>Ingredients:</p>
                <ul>
                  <li>Hot Milk Chocolate Sauce</li>
                  <li>Hot Nutella</li>
                  <li>Four Strawberries</li>
                  <li>Icing Sugar</li>
                </ul>
                <p>Method:</p>
                <ol>
                  <li>Drizzle hot milk chocolate sauce on grease proof paper</li>
                  <li>Drizzle hot Nutella on each layer of pancake</li>
                  <li>Slice 4 strawberries and spread over stack</li>
                  <li>Drizzle hot Nutella over top</li>
                  <li>Speckle icing sugar</li>
                </ol>
                <img
                  src={nutella}
                  alt="Nutella pancake"
                  className="tubbbees-img"
                />
  
              </div>
            ):(
              <></>
            )}
            <div className="moveButtons">
              {!intro ? (
                <>
                  {!nine ? (
                    <>
                      <Button
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                        className="moveButton"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => next()}
                        variant="outlined"
                        color="error"
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    <div className="unit">
                      <div className="moveButtons" id="confetti">
                        <Button
                          className="moveButton"
                          onClick={() => prev()}
                          variant="outlined"
                          color="error"
                        >
                          Prev
                        </Button>
                        <Button
                          className="moveButton"
                          onClick={() => handleSubmit()}
                          variant="outlined"
                          color="success"
                        >
                          Submit
                        </Button>
                      </div>
                      {unit ? (
                        <>
                          <br />
                          <div className="unit">
                            <h6>Congratulations! you finished this module</h6>
                            <Button
                              onClick={() => navigate("/tubbees/baking")}
                              variant="contained"
                              color="success"
                              sx={{alignSelf: "center"}}
                            >
                              Go back to units
                            </Button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Button
                  className="moveButton"
                  onClick={() => next()}
                  variant="outlined"
                  color="error"
                >
                  Next
                </Button>
              )}
            </div>
          </>
        ) : (
          <div className="completed">
            <img src={complete} />
            <h2>This unit has been completed.</h2>
            <p>Do you want to do it again?</p>
            <div className="moveButtons" id="confetti">
              <Button
                className="moveButton"
                onClick={() => setCompleted(false)}
                variant="outlined"
                color="error"
              >
                Yes
              </Button>
              <Button
                className="moveButton"
                onClick={() => navigate("/tubbees/baking")}
                variant="outlined"
                color="success"
              >
                No
              </Button>
            </div>
          </div>
        )}
        <Footer />
      </section>
    );
  
}