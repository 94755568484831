import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";
import soldeiring from './images/soldering.png'

export default function NitroOnsite(props){
    const navigate = useNavigate();

    return(
        <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Nitro - On-Site</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/nitro/onsite/hand-tools")}>
          <img src={health} alt="nitro onsite hand tools" />
          <div className="completion">
            <h2>Hand Tools</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/nitro/onsite/site-safety")}>
          <img src={health} alt="nitro onsite site safety" />
          <div className="completion">
            <h2>Site Safety</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/nitro/onsite/soldering")}>
          <img src={soldeiring} alt="nitro onsite soldering" />
          <div className="completion">
            <h2>Soldering</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
    )
}