import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Critical(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Ordering</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/lists")}>
          <img src={health} alt="critical lists" />
          <div className="completion">
            <h2>Critical Lists</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/wastage")}>
          <img src={health} alt="shop wastage" />
          <div className="completion">
            <h2>Shop Wastage</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/customers")}>
          <img src={health} alt="dealing with customers" />
          <div className="completion">
            <h2>Dealing With Customers</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/merchandising")}>
          <img src={health} alt="merchandising" />
          <div className="completion">
            <h2>Merchandising</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/promotions")}>
          <img src={health} alt="running promotions" />
          <div className="completion">
            <h2>Running Promotions</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/back-office")}>
          <img src={health} alt="back office/reconcilliation" />
          <div className="completion">
            <h2>Back Office / Reconcilliation</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/maintenance")}>
          <img src={health} alt="maintenance" />
          <div className="completion">
            <h2>Maintenance</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/cleaning")}>
          <img src={health} alt="cleaning rotas" />
          <div className="completion">
            <h2>Cleaning Rotas</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/critical/organisation")}>
          <img src={health} alt="organisation of staff and day" />
          <div className="completion">
            <h2>Organisation Of Day And Staff</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
