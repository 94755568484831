import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function KeyHolder(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Key Holder</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/keyholder/opening")}>
          <img src={health} alt="opening procedure" />
          <div className="completion">
            <h2>Opening Procedures</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/keyholder/closing")}>
          <img src={health} alt="closing procedure" />
          <div className="completion">
            <h2>Closing Procedures</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/keyholder/alarm")}>
          <img src={health} alt="alarm" />
          <div className="completion">
            <h2>Alarm</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
