import Header from "../../Header";
import "../module.scss";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import * as Sentry from "@sentry/react";


export default function HealthAndSafety(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState('');
  const [background, setBackground] = useState("#fff")


  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          console.log(res.data);
          setEmployee(res.data);
        }
      })
      .catch((err) => {
        Sentry.captureException(err)
      });
    transaction.finish();
  }, []);



  return (
    <main className="moduleHome">
      <Header stop={props.stop} />
      <h3>Health &amp; Safety</h3>
      <div className="modules">
        {employee.site !== "Head Office" ?
          <>
            <div className="module healthAndSafety" onClick={() => navigate("/core/hs/hot-surfaces")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/hot_surfaces.png" alt="health &amp; safety" />
              <div className="completion" style={{ backgroundColor: `${background}` }}>
                <h2>Hot Surfaces</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/hs/spillages")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/spillages.png" alt="health &amp; safety" />
              <div className="completion" style={{ backgroundColor: `${background}` }}>
                <h2>Spillages &amp; Breakages</h2>
              </div>
            </div>
            <div className="module healthAndSafety" onClick={() => navigate("/core/hs/allergens")}>
              <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/spillages.png" alt="health &amp; safety" />
              <div className="completion" style={{ backgroundColor: `${background}` }}>
                <h2>Allergens</h2>
              </div>
            </div>
          </>
          :
          <></>
        }
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs/manual-handling")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/manual_handling.png" alt="health &amp; safety" />
          <div className="completion" style={{ backgroundColor: `${background}` }}>
            <h2>Manual Handling</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs/first-aid")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/first_aid.png" alt="health &amp; safety" />
          <div className="completion" style={{ backgroundColor: `${background}` }}>
            <h2>First Aid</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs/eyewash")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/eye_wash.png" alt="health &amp; safety" />
          <div className="completion" style={{ backgroundColor: `${background}` }}>
            <h2>Eyewash Station</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs/knife-policy")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/eye_wash.png" alt="health &amp; safety" />
          <div className="completion" style={{ backgroundColor: `${background}` }}>
            <h2>Knife Safety</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/hs/general-poplicy")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/h%26s/general-policy.png" alt="health &amp; safety" />
          <div className="completion" style={{ backgroundColor: `${background}` }}>
            <h2>General Policy</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
