import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import useBy from "./images/useBy.webp";
import axios from 'axios'

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function DayDots(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [first, setFirst] = useState(false);
  const [intro, setIntro] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Date Maintainance: Food");
  const [child, setChild] = useState("PUTTING ON DATE DOTS");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } 
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } 
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Day Dots Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Day Dots</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>What are they?</h4>
              <p>
                Day dots are stickers with the day of the week and a color code.
                That help to organise &amp; rotate stock as well as reducing
                food waste prevent foodborne illnesses.
              </p>
              <p>
                The use of day dots allows staff to quickly identify the day the
                item was prepared. Using the posted shelf life/expiry list,
                staff will know when the product will exceed the shelf life and
                be required to be discarded. All discarded items must be
                recorded on the wastage sheet.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                Containers should be labeled at eye level or near top of
                container next to an easily visible indicator such as bar code
                sticker or date label.
              </p>
              <p>
                Day dots should be applied when product first enters stock
                rotation system so staff know which containers need to be
                rotated first.
              </p>
              <p>
                Food rotation should always follow FIFO (First In First Out) and
                must be implemented from the moment of receiving a delivery.
                This will help to minimise watage and risk of foodborne
                illnesses to customers and staff.
              </p>
              <p>
                It is every member of staff responsibility to ensure that stock
                is rotated and day dot policy is followed.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>
                Day dots help to monitor products removed from the original
                packaging and to keep our customers safe we use day dots to
                visually indicate when an item must be discarded.
              </p>
              <p>
                Day dots should not be applied to product wrappers or covering a
                manufacturer’s use by date.
              </p>
              <p>
                It is good practice to day dot the cake outer box with the use
                by date. It makes it very clear and noticeable as to when the
                product expires.
              </p>
              <p>
                Creams, soya milk, fruit cooler bases, bread for toast, lemons,
                flakes, marshmallows, vanilla base, hot chocolate powder and
                creamy cooler syrups must also be day dotted.
              </p>
              <p>
                Any items which are not individually packaged and dated, day dot
                sheets sent out with the planograms should be used to place the
                day dots on the corresponding product to track use by dates
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>
                Fresh food has to be consume 72h from the moment of breaking the
                seal of a package. That moment counts as the first hour.
              </p>
              <p>
                Sauces, syrups and high content sugar items might have other
                times for consumption. As this will vary between products, it is
                important for all members of staff to check the labels of the
                product at the moment of opening.
              </p>
              <img
                src={useBy}
                alt="use by date example"
                className="tubbbees-img"
              />
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!third ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/tubbees/date-maintenance")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/tubbees/date-maintenance")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}

///tubbees/date-maintenance
