import { useState, useRef } from 'react'
import ReactToPrint from "react-to-print";
import Logo from '../images/rt-logo.png'
import Signature from '../images/shamlySig.PNG'

export default function Certificate(props) {
    const [showCertificate, setShowCertificate] = useState(false)
    const componentRef = useRef();
    const myRefname = useRef(null);

    const linkToPrint = () => {
        return (
            <button ref={myRefname} style={{ display: "none" }}>
                Print PDF
            </button>
        );
    };

    const handleClick = () => {
        myRefname.current.click();
    };

    return (
        <>
            <div style={{ backgroundColor: "#bf1a1a", color: "#ffff", border: "4px solid #000", margin: "2px" }}>
                <h1 onClick={() => { setShowCertificate(!showCertificate) }}>{props.data.name} 📩</h1>
            </div>
            {showCertificate ?
                <div style={{ padding: "5%" }}>
                    <hr />
                    <div style={{ padding: "5%", margin: "10px" }} ref={componentRef} >

                        <div id="certHeader" style={{ textAlign: "center" }}>
                            <img style={{ width: "300px", margin: "0 auto" }} src={Logo} alt="RaceTrack Logo" />
                            <h2>Training Record</h2>
                            <p style={{ marginTop: "5%", color: "black" }}><b>Module: </b> <u> {props.data.name} </u></p>
                        </div>

                        <div id="certContent">
                            <p style={{ marginTop: "12%", color: "black", textAlign: 'justify' }}>This certificate is to confirm that <b>{props.name}</b> employed at <b>{props.site}</b> as a <b>{props.role}</b> has passed their <b>{props.data.name}</b> training module.</p>
                            <br />
                            <div style={{ color: "black" }}>
                                <p>The following tasks were completed in this module:</p>
                                {props.data.taskData.map(function (d, i) {
                                    return (
                                        <li style={{ marginLeft: "5%" }}><b>{d.task}</b> - {d.dateTrained}</li>
                                    )
                                })}
                            </div>
                            <br />
                            <p>Please keep a copy of this certificate in your records.</p>
                        </div>

                        <div id="certFooter" style={{ marginTop: "6%", color: "#000", display: "flex", justifyContent: "space-between", position: "relative" }}>
                            <div>
                                <p style={{ marginBottom: "6%" }}>Signed on behalf of the company </p>
                                <img width="200px" src={Signature} />
                                <p><b>Shamly Sud</b></p>
                            </div>
                            <div style={{ position: "absolute", bottom: 0, right: 0 }}>
                                <p><b style={{ marginRight: "10px" }}>Date: </b> <u> {props.data.certificate}</u></p>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <button onClick={() => handleClick()} style={{ width: "100%", padding: "10px", color: "white", backgroundColor: "darkgreen", border: "3px solid black" }}>Print Document</button>
                    <ReactToPrint
                        documentTitle={`${props.data.name}`}
                        trigger={linkToPrint}
                        content={() => componentRef.current}
                    />
                </div>
                :
                <></>
            }
        </>
    )
}