import './driveOff.scss'
import {useState} from 'react';

export default function DriveOffQuiz (props){
    const [noDriveOff, setNoDriveOff] = useState(false)
    const [driveOff, setDriveOff] = useState(false)


    // if (smoke === true && lights === true) {
    //     props.changeAnswered();
    //   }

      return(
        <section className="img-quiz">
            <h4>Press on the buttons to see more</h4>
            <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/fuel-drive-off.png" alt="quiz image"/>
        </section>
      )
}