import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function KitchenHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Kitchen</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/kitchen/dishwasher")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Dishwasher</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/kitchen/grease-trap")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Grease Trap</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/kitchen/glass-washer")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Glass Washer</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/kitchen/buffering-cutlery")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Buffing Cutlery</h2>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );

}