import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function CleansHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Full Cleans</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/soft-serve")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Soft Serve</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/soft-slush-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Soft Slush Clean</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/deep-slush-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Deep Slush Clean</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/soft-milkshake-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Soft Milkshake Clean</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/deep-milkshake-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Deep Milkshake Clean</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/taking-bin-out")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Taking Out Bin</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/containers")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Containers</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/kitchen-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Kitchen Cleaning</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/toilet-clean")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Toilet Cleaning</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/toilet-mop")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Toilet Mop Bucket</h2>
            </div>
          </div> 
          <div className="module tills" onClick={() => navigate("/tubbees/full-cleans/grease-traps")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Grease Traps</h2>
            </div>
          </div> 

  
        </div>
        <Footer />
      </main>
    );

}