import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import axios from "axios";
import SlipQuiz from "../../../quizes/slipAndTrips/SlipQuiz";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Spillages(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("H&S");
  const [child, setChild] = useState("SPILLAGES AND BREAKAGES");
  const [rightAnswer, setRightAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);
  const [answered, setAnswered] = useState(false);

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Driveoff Completion`,
      sampled: true,
    });
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete",
        data
      )
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  function changeAnswered() {
    setAnswered(true)
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Spillages &amp; Breakages</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                It is important to recognise Slip, Trip, and Fall hazards in
                work when you see them so that you can take proper precautions
                to prevent accidents and protect yourself, co-workers and
                customers from injury.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>Identify risks</h4>
              <p>
                Begin the assessment by analysing previous slips to identify any
                common issues.
              </p>
              <ul>
                <li>
                  Identify areas where slips, trips and falls have occurred
                </li>
                <li>
                  Ask team members if and where they have slipped in the past
                </li>
              </ul>
              <p>
                Equipment using water/liquid, cleaning stores, toilets, loose
                fruit and vegetables, flowers and plants. Identify if the liquid
                used is viscous, as that can make the circumstance highly
                hazardous.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>Prevent</h4>
              <p>
                As part of a risk management strategy, there are several steps
                to consider in order to prevent slips, trips and falls caused by
                spills:
              </p>
              <ul>
                <li>Use floor mats near liquid containers</li>
                <li>Provide containers for customers to carry their goods</li>
                <li>
                  Maintain equipment and pipework in good working order with
                  proper seals and valves
                </li>
                <li>
                  Maintain equipment and pipework in good working order with
                  proper seals and valves
                </li>
                <li>
                  Display any liquids so they’re not likely to spill on
                  walkways. Don’t put containers of liquid too close to the
                  front of shelves
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>Contain</h4>
              <p>
                Containing spills when they occur is important. Some ways to
                contain a spill or hazardous liquid include:
              </p>
              <ul>
                <li>Using floor mats where appropriate</li>
                <li>Hang mops and other wet equipment over buckets</li>
                <li>Make sure caution is taken at entrances and exits</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>Detect</h4>
              <p>
                There should be regular monitoring of spill detection with
                frequent planned checks. Using a checklist is an easy way to
                ensure this monitoring is being carried out.
              </p>
              <p>
                Ideally spills would be immediately dealt with using absorbent
                materials, but in some cases, it may not be possible to remove
                the liquid right away. This may be because of large spills. The
                liquid may be viscous or hazardous and so cordoning off the
                spill using signs or markers will be needed.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <p>
                Being proactive and making sure cleaning materials are within
                easy reach in high risk areas will help deal with spills
                immediately. It’s important to ensure spills are thoroughly
                cleaned and dried, as it’s often the case that inefficient
                cleaning and drying can cause a greater risk of a slip and fall.
              </p>
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <div className="unit">
              <SlipQuiz changeAnswered={changeAnswered}/>
            </div>
          ) : (
            <></>
          )}

          <div className="moveButtons">
            {!intro ? (
              <>
                {!sixth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      {answered ? (
                        <Button
                          className="moveButton"
                          onClick={() => handleSubmit()}
                          variant="outlined"
                          color="success"
                        >
                          Submit
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/core")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/core")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
