import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function VapesPolicy(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [intro, setIntro] = useState(true);
    const [first, setFirst] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Vape & Cigar")
    const [child, setChild] = useState("GENERAL POLICY")

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex(x => x.name === parent)
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(x => x.task === child)
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);


    function next() {
        if (intro === true) {
            setIntro(false);
            setFirst(true);
        }
    }

    function prev() {
        if (first === true) {
            setFirst(false);
            setIntro(true);
        }
    }

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        axios
            .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
            .then((res) => {
                party.confetti(document.getElementById("confetti"), confettiSettings);
                setUnit(true);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>General Vape Policy</h3>
            {!completed ? (
                <>
                    {intro ? (
                        <div className="unit">
                            <p>
                                The Company has a strict Cigarette and Alcohol Sales Policy in which all employees must adhere to at all times.
                                This company policy outlines what employees' responsibilities are. The following policies adheres to UK and
                                Scottish law and therefore a breach of this policy can have further consequences if not followed. This policy will
                                be updated as the law changes and/or introduces new legislation. All employees will be notified of this.
                                However, it is the responsibility of the employee to ensure that they are up to date with these changes and
                                developments.
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                    {first ? (
                        <div className="unit">
                            <p>The following rules always apply:</p>
                            <ul>
                                <li>
                                    Challenge 25! If a customer looks younger than 25 you must ask for ID. If you ever have doubts then
                                    ask anyway there is no harm. If a customer does not have ID once asked then no sale should be made.
                                </li>
                                <li>
                                    Customers must be 18 and over to purchase alcohol
                                </li>
                                <li>
                                    Customers must be 18 and over to purchase cigarettes, cigarette paper or 'VAPED 4 U' products.
                                </li>
                                <li>
                                    Do not ask another member of staff for their advice on a customer's age. This is your responsibility. If
                                    an employee asks you for advice then politely remind them of this policy
                                </li>
                                <li>
                                    It is a criminal offense to break open a pack of cigarettes and sell them individually
                                </li>
                                <li>Any customer you ask for ID must be recorded in the Challenge 25 book (sometimes referred to as a
                                    Refusal Book'). If you do not know where this is at your site then ask a manager. At the beginning of
                                    your shift you should know where this is.</li>
                                <li>
                                    Acceptable ID – Passport, Driver's License, Provisional Driver's License and Student Cards (as long as
                                    this has a photograph and hologram). You can ask for advice when it comes to ID verification. Do not
                                    take the risk
                                </li>
                                <li>Customers who state they will come back to the site with ID should not be sold the product until
                                    verification is complete.</li>
                                <li>
                                    Customer who state they are a regular to the site do not get a free pass. Politely inform them that you
                                    are following company procedure and require ID.
                                </li>
                            </ul>
                        </div>
                    ) : (
                        <></>
                    )}
                    <div className="moveButtons">
                        {!intro ? (
                            <>
                                {!first ? (
                                    <>
                                        <Button onClick={() => prev()} variant="outlined" color="error" className="moveButton">
                                            Prev
                                        </Button>
                                        <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                            Next
                                        </Button>
                                    </>
                                ) : (
                                    <div className="unit">
                                        <div className="moveButtons" id="confetti">
                                            <Button
                                                className="moveButton"
                                                onClick={() => prev()}
                                                variant="outlined"
                                                color="error"
                                            >
                                                Prev
                                            </Button>
                                            <Button
                                                className="moveButton"
                                                onClick={() => handleSubmit()}
                                                variant="outlined"
                                                color="success"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                        {unit ? (
                                            <>
                                                <br />
                                                <div className="unit">
                                                    <h6>Congratulations! you finished this module</h6>
                                                    <Button
                                                        onClick={() => navigate("/retail/vape-cigars")}
                                                        variant="contained"
                                                        color="success"
                                                        sx={{alignSelf: "center"}}
                                                    >
                                                        Go back to units
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                )}
                            </>
                        ) : (
                            <Button className="moveButton" onClick={() => next()} variant="outlined" color="error">
                                Next
                            </Button>
                        )}
                    </div>
                </>
            ) : (
                <div className="completed">
                    <img src={complete} />
                    <h2>This unit has been completed.</h2>
                    <p>Do you want to do it again?</p>
                    <div className="moveButtons" id="confetti">
                        <Button
                            className="moveButton"
                            onClick={() => setCompleted(false)}
                            variant="outlined"
                            color="error"
                        >
                            Yes
                        </Button>
                        <Button
                            className="moveButton"
                            onClick={() => navigate("/retail/vape-cigars")}
                            variant="outlined"
                            color="success"
                        >
                            No
                        </Button>
                    </div>
                </div>

            )}

            <Footer />
        </section>
    );
}
