import { useState,useEffect } from "react";
import "../styles/alcohol.css";
import Button from "@mui/material/Button";
import axios from "axios";
import Header from "./Header";
import AlcoholReview from "./AlcoholReview";
import { useNavigate } from "react-router-dom";
import complete from '../images/COMPLETE.png'
import Footer from "./Footer";
import * as Sentry from "@sentry/react";
import party from "party-js";
party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF")
    ])
};

export default function Alcohol(props) {
  const [accessCode, setAccessCode] = useState("");
  const [showTraining, setShowTraining] = useState(true);
  const [login, setLogin] = useState(true);
  const [employee, setEmployee] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [restart, setRestart] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const navigate = useNavigate();
  const [managerAlert, setManagerAlert] = useState(false)
  const [reviewAlert, setReviewAlert] = useState(false)

  useEffect(() => {
    var data = localStorage.getItem("staff")
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Find Staff With ID`,
      sampled: true,
  });
    axios.get(`https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`).then((res) => {
      if (res.data) {
        localStorage.removeItem("staff");
        localStorage.setItem("staff", JSON.stringify(res.data));

        setEmployee(res.data)
        setAccessCode(res.data.staffTrainingId)
      }
    }).catch((err) => {
      Sentry.captureException(err)
    })
    transaction.finish();
    // var data = localStorage.getItem("staff")
    // data = JSON.parse(data);
    //     setEmployee(data)
    //     setAccessCode(data.staffTrainingId)
  }, [submit])


  const questions = [
    {
      questionText: "The role of the Licensing Standards Officer is to:",
      answerOptions: [
        {
          answerText: "Close Licensed Premises that break the law",
          isCorrect: false,
        },
        {
          answerText: "Offer support to shops in financial difficulty",
          isCorrect: false,
        },
        {
          answerText:
            "Provide a mediation service between licence holders and other parties, including member of the public",
          isCorrect: true,
        },
        {
          answerText: "Promote a dinking culture in the area",
          isCorrect: false,
        },
      ],
    },
    {
      questionText:
        "The recommended daily safe limits of alcohol for a male are:",
      answerOptions: [
        { answerText: "10 to 12 units", isCorrect: false },
        { answerText: "3 to 4 units", isCorrect: true },
        { answerText: "2 to 3 units", isCorrect: false },
        { answerText: "5 to 6 units", isCorrect: false },
      ],
    },
    {
      questionText: "By law you are not allow to sell alcohol to:",
      answerOptions: [
        { answerText: "A person who is drunk", isCorrect: true },
        { answerText: "A Licensing Standars Officer", isCorrect: false },
        { answerText: "A fireman", isCorrect: false },
        { answerText: "A woman on her own", isCorrect: false },
      ],
    },
    {
      questionText: "A young person is someone who:",
      answerOptions: [
        { answerText: "Looks young", isCorrect: false },
        { answerText: "Is 15 years old or younger", isCorrect: false },
        { answerText: "Acts young", isCorrect: false },
        { answerText: "is 16 or 17 years old", isCorrect: true },
      ],
    },
    {
      questionText: "Which of the following is not a licensing objective:",
      answerOptions: [
        { answerText: "Securing public safety", isCorrect: false },
        {
          answerText: "Advising Licensed Premises on best practice",
          isCorrect: true,
        },
        { answerText: "Preventing Crime and Disorder", isCorrect: false },
        { answerText: "Protecting Children from harm", isCorrect: false },
      ],
    },
    {
      questionText: "For a drink to be classed as alcohol it must have:",
      answerOptions: [
        { answerText: "No less than 0,05% ABV", isCorrect: false },
        { answerText: "No less than 5% ABV", isCorrect: false },
        { answerText: "No less than 1,2% ABV", isCorrect: false },
        { answerText: "No less than 0,5% ABV", isCorrect: true },
      ],
    },
    {
      questionText:
        "To be able to sell alcohol in your shop you must apply for a:",
      answerOptions: [
        { answerText: "Premises License", isCorrect: true },
        { answerText: "Marriage License", isCorrect: false },
        { answerText: "Building standards certificate", isCorrect: false },
        { answerText: "Food hygiene certificate", isCorrect: false },
      ],
    },
    {
      questionText:
        "The minimum legal age a person can legally be sold alcohol on licensed premises is:",
      answerOptions: [
        { answerText: "16", isCorrect: false },
        { answerText: "18", isCorrect: true },
        { answerText: "17", isCorrect: false },
        { answerText: "15", isCorrect: false },
      ],
    },
    {
      questionText:
        "Which of the following is not a reliable document for proof of age?:",
      answerOptions: [
        { answerText: "A passport", isCorrect: false },
        { answerText: "A photo driving licence", isCorrect: false },
        { answerText: "A PASS acredited proof of age card", isCorrect: false },
        { answerText: "A student union card", isCorrect: true },
      ],
    },
    {
      questionText: "Challenge 25 means:",
      answerOptions: [
        { answerText: "You must be 25 to purchase alcohol", isCorrect: false },
        {
          answerText:
            "You must be accompanied by someone who is 25 to purchase alcohol",
          isCorrect: false,
        },
        { answerText: "Consume 25 drinks in one evening", isCorrect: false },
        {
          answerText:
            "If you do not look 25 you must by way of approved ID prove you are at least 18",
          isCorrect: true,
        },
      ],
    },
    {
      questionText:
        "How old you must be to legally purchase liqueur chocolates:",
      answerOptions: [
        { answerText: "15", isCorrect: false },
        { answerText: "16", isCorrect: true },
        { answerText: "17", isCorrect: false },
        { answerText: "18", isCorrect: false },
      ],
    },
    {
      questionText: "If a customer is shouting at you, you must:",
      answerOptions: [
        { answerText: "Shout back", isCorrect: false },
        { answerText: "Shout louder than them", isCorrect: false },
        { answerText: "Run away", isCorrect: false },
        { answerText: "Stay calm", isCorrect: true },
      ],
    },
    {
      questionText: "The maximum permitted hours for off sales premises are:",
      answerOptions: [
        {
          answerText: "To be determined by the Licensing Board",
          isCorrect: false,
        },
        { answerText: "10.00am to 10.00pm", isCorrect: true },
        { answerText: "8.00am to 10.00pm", isCorrect: false },
        { answerText: "12.30pm to 10.00pm", isCorrect: false },
      ],
    },
    {
      questionText:
        "Which of the following is not a member of the Licensing Forum:",
      answerOptions: [
        { answerText: "Local License Holders", isCorrect: false },
        { answerText: "Chief Fire Officer", isCorrect: true },
        { answerText: "Young People", isCorrect: false },
        { answerText: "A Licensing Board Member", isCorrect: false },
      ],
    },
    {
      questionText: "Which of the following is a true statement about alcohol:",
      answerOptions: [
        { answerText: "Alcohol warms you up", isCorrect: false },
        {
          answerText: "Drinking lots of water will stop me getting drunk",
          isCorrect: false,
        },
        { answerText: "I`ll be fine in the morning", isCorrect: false },
        { answerText: "None of the above", isCorrect: true },
      ],
    },
    {
      questionText: "How old you need to be to apply for a personal licence:",
      answerOptions: [
        { answerText: "18", isCorrect: true },
        { answerText: "17", isCorrect: false },
        { answerText: "16", isCorrect: false },
        { answerText: "15", isCorrect: false },
      ],
    },
    {
      questionText: "To become a Premises Manager you need to be:",
      answerOptions: [
        { answerText: "A Manager", isCorrect: false },
        { answerText: "Over 21 years old", isCorrect: false },
        { answerText: "A personal Licence Holder", isCorrect: true },
        { answerText: "None of the above", isCorrect: false },
      ],
    },
    {
      questionText:
        "According to the mandaroty conditions on a premises licence, alcohol may not be sold when:",
      answerOptions: [
        { answerText: "Is dark", isCorrect: false },
        {
          answerText: "There is no Premises Manager attached to the Premise",
          isCorrect: true,
        },
        { answerText: "When the manager is on a day off", isCorrect: false },
        { answerText: "It is a Sunday", isCorrect: false },
      ],
    },
    {
      questionText:
        "When is it legal for a young person to purchase alcohol on licensed premises:",
      answerOptions: [
        { answerText: "When accompanied by their parents", isCorrect: false },
        {
          answerText:
            "When the drink is not for the consumption of the person buying it",
          isCorrect: false,
        },
        { answerText: "At a party", isCorrect: false },
        { answerText: "None of the above", isCorrect: true },
      ],
    },
    {
      questionText: "Every Premises Licence application must contain:",
      answerOptions: [
        { answerText: "Directions to the Premises", isCorrect: false },
        {
          answerText: "The names of the staff employed at the premises",
          isCorrect: false,
        },
        { answerText: "A price list", isCorrect: false },
        { answerText: "An Operating Plan", isCorrect: true },
      ],
    },
  ];

  const handleAnswerOptionClick = (answerOption, question) => {
    const findQuestion = (element) => element.questionText == question;
    var index = questions.findIndex(findQuestion);
    const found = questions[index].answerOptions.find(
      (element) => element.isCorrect == true
    );
    setAnswers([
      ...answers,
      {
        questionText: question,
        answerText: answerOption.answerText,
        isCorrect: answerOption.isCorrect,
        correctAnswer: found.answerText,
      },
    ]);
    if (answerOption.isCorrect) {
      setScore(score + 1);
    }
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
    } else {
      setShowScore(true);
      if (score  >= 17) {
        setSubmit(true);
      }else{
          setShowReviewModal(true)
      }
    }
  };

  function Submit() {
    var data = {
      code: accessCode,
      answers: answers,
    };
    const alcoholTransaction = Sentry.startTransaction({
      name: `Submit Alcohol Quiz`,
      sampled: true,
  });
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingAdmin/alcohol/answered",
        data
      )
      .then((res) => {
        party.confetti(document.getElementById("confetti"),confettiSettings)
        setManagerAlert(true);
      })
      .catch((err) => {
        Sentry.captureException(err)
      })
      alcoholTransaction.finish();
  }

  function reviewCompleted() {
    setReviewAlert(true)
  }

  return (
    <main className="training">
      <Header stop={props.stop}/>
      <h1>Welcome to alcohol training</h1>
      {!employee.answered ? (
        <div className="code">
          <h2>
            Hello <span>{employee.name}</span>
          </h2>
          <h2>Please read and answer the questions</h2>
          <br />
          {showScore ? (
            <div className="score-section">
              <p>
                You scored {score} out of {questions.length}
              </p>
              {showReviewModal ? (
                <div className="review">
                  <h4>You can review your answers before trying again</h4>

                  {answers.map(function (d, i) {
                    var bgColour;
                    d.isCorrect ? (bgColour = "green") : (bgColour = "red");
                    return (
                      <AlcoholReview
                        colour={bgColour}
                        question={d.questionText}
                        answer={d.answerText}
                        correct={d.correctAnswer}
                      />
                    );
                  })}
                  <Button variant="contained" color="error" style={{marginLeft: "35%"}} className="button" onClick={() => reviewCompleted()}>
                    Complete Review
                  </Button>
                  {reviewAlert ? (
                    <div className="managerAlert">
                      <b>You will be taken to the start of the quiz now</b>
                      <Button onClick={()=> window.location.reload()} sx={{alignSelf: "center"}} variant="outlined" color="error">Start</Button>
                    </div>
                  ):(
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}

              {submit ? (
                <div className="code" id="confetti">
                  <b>Congratulations you passed!</b>
                  <p>Please click on submit</p>
                  <Button
                    variant="contained"
                    color="success"
                    className="button"
                    onClick={() => Submit()}
                  >
                    Submit
                  </Button>
                  {managerAlert ? (
                    <div className="managerAlert">
                      <b>Answers have been submited to your manager</b>
                      <Button onClick={()=> navigate("/home")} sx={{alignSelf: "center"}} variant="outlined" color="error">Home</Button>
                    </div>
                  ): (
                    <></>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <>
              <div className="question-section">
                <div className="question-count">
                  <span>Question {currentQuestion + 1}</span>/{questions.length}
                </div>
                <div className="question-text">
                  {questions[currentQuestion].questionText}
                </div>
              </div>
              <div className="answer-section">
                {questions[currentQuestion].answerOptions.map(
                  (answerOption) => (
                    <button
                      onClick={() =>
                        handleAnswerOptionClick(
                          answerOption,
                          questions[currentQuestion].questionText
                        )
                      }
                    >
                      {answerOption.answerText}
                    </button>
                  )
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="completed">
          <img  src={complete} />
          <h2>The Quiz Has Already Been Completed</h2>
          <button onClick={()=>{navigate("/home")}}>Go Back</button>  
        </div>
      )}
      <Footer />
    </main>
  );
}
