import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function DateRotation(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Date Maintainance: Retail");
  const [child, setChild] = useState("CHECKING DATE ROTATION");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `CHECKING DATE ROTATION`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `CHECKING DATE ROTATION`,
      sampled: true,
    });
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete",
        data
      )
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Date Rotation</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                Maintaining fill fridges, that look appealing for the customer
                is an important part of your job. To fill a fridge you must
                follow FIFO. FIFO stands for First-In First-Out. It is a stock
                rotation system used for food storage, where the items with the
                soonest best before or use by date are placed at the front and
                those items with the furthest dates are at the back. By using
                FIFO you ensure that food with the nearest best before or use-by
                dates are used or sold first. In this way we can minimise
                wastage and maximise freshness for the customer.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                Using FIFO as a storage system is simple and efficient, and it
                helps you to know what is happening at all times, and helps
                minimise wastage. To follow FIFO is as simple as following the
                next steps
              </p>
              <ul>
                <li>
                  Know which products have the soonest best before or use-by
                  dates.
                </li>
                <li>
                  Remove products that are past these dates or are damaged.
                </li>
                <li>Place items with the soonest dates at the front.</li>
                <li>
                  Stock new items behind the front stock; the latest dates
                  should always be at the back.
                </li>
                <li>Use/sell stock at the front first.</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>
                FIFO is a continuous process. When new stock comes in, always
                use the FIFO procedure to fill shelves or fridges so food can be
                sold or used before it expires. Furthermore, be sure to stock
                refrigerated and frozen goods before room temperature items.
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>FIFO tips:</p>
              <ul>
                <li>Check products for damage or deterioration.</li>
                <li>
                  If a product is not out, make a note of why and let your team
                  know on the whatsapp group.
                </li>
                <li>Ensure all goods are faced up.</li>
                <li>
                  Do not overload regfrigerators, but don't let them look empty
                  either.
                </li>
                <li></li>
              </ul>
              <p>
                Even just one person neglecting to follow FIFO will disrupt
                everyone’s workflow and negatively affect customers’ perception
                of the business.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <p>Benefits of using FIFO:</p>
              <ul>
                <li>
                  Reduced wastage - this will make the business more
                  eco-friendly and waste less money.
                </li>
                <li>
                  Safer food storage - FIFO ensures better food hygiene
                  standards.
                </li>
                <li>
                  Helps comply with HACCP - and therefore with food safety law.
                </li>
                <li>
                  Customer satisfaction - food is easier to navigate and always
                  good quality.
                </li>
                <li>
                  Efficient workflow - It is easier to locate potentially
                  problematic food with ease.
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!fourth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/retail/date-maintenance")}
                            variant="contained"
                            color="success"
                            sx={{ alignSelf: "center" }}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/retail/date-maintenance")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
