import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import axios from "axios";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function ToiletClean(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [first, setFirst] = useState(false);
  const [intro, setIntro] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);

  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Full Cleans");
  const [child, setChild] = useState("TOILET CLEANING");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Day Dots Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Toilet Clean</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>Tools Needed</h4>
              <ul>
                <li>Cleaning cloth</li>
                <li>Approved sanitizer in a labelled bottle with spray head</li>
                <li>Approved glass and hard surface cleaner</li>
                <li>Approved toilet cleaner</li>
                <li>Toilet brush</li>
                <li>Paper towel</li>
                <li>Duster</li>
                <li>Rubber gloves</li>
                <li>Floor cleaner</li>
                <li>Clean mop and bucket</li>
                <li>Wet floor sign</li>
                <li>Broom</li>
                <li>Dustpan and brush</li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>How to clean the toilets</h4>
              <p>Sinks:</p>
              <ul>
                <li>
                  1-2 amounts of toilet cleaner on a cloth wipe over to remove
                  all dirt
                </li>
              </ul>
              <p>Pipes:</p>
              <ul>
                <li>
                  Dust then sanitize througout using 2-3 sprays of approved
                  sanitizer and a cloth
                </li>
              </ul>
              <p>Mirrors:</p>
              <ul>
                <li>
                  1-2 sprays of polish on to paper towel and polish mirror until
                  free of marks and smears
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>Ceiling vents and high ledges:</p>
              <ul>
                <li>
                  Using step-ladders dust and wipe over with approved sanitizer
                  and a separate cloth
                </li>
              </ul>
              <p>Baby changing unit:</p>
              <ul>
                <li>
                  1-2 amounts of toilet cleaner on a cloth, wipe over all areas
                  of the unit
                </li>
              </ul>
              <p>Bins:</p>
              <ul>
                <li>Empty any rubbish bins</li>
                <li>
                  Nappy/Sanitary bins must be emptied and disposed of by a
                  professional company
                </li>
              </ul>
              <p>Tiles, driers and paper dispensers:</p>
              <ul>
                <li>2-3 sprays of sanitizer on a cloth to remove all marks</li>
                <li>
                  Immediately followed by polishing dry with paper towel to
                  prevent smears
                </li>
              </ul>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <p>Toilet exterior and seat:</p>
              <ul>
                <li>Dust then clean with toilet cleaner and a cloth</li>
                <li>Polish with a paper towel</li>
              </ul>
              <p>Toilet bowl:</p>
              <ul>
                <li>
                  Use the brush to plunge the waterline and lower the water
                  level
                </li>
                <li>Apply toilet cleaner directly on to the bowl</li>
                <li>Brush around the bowl to remove visible soils</li>
                <li>
                  Leave for as long as possible before flushing to help remove
                  lime scale
                </li>
                <li>
                  Always flush before allowing customers to use the toilet
                </li>
              </ul>
              <p>Ceiling vents and high ledges:</p>
              <ul>
                <li>
                  Using step-ladders dust and wipe over with approved sanitizer
                  and a separate cloth
                </li>
                <li>Finally clean the floor using the acidic floor cleaner</li>
              </ul>
              <p>
                Always use rubber gloves when cleaning toilets. Any sharp
                objects e.g. needles or bodily fluids should be removed using a
                bio hazard kit and line manager must be informed. Toilet brushes
                need to be clean.
              </p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!third ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/tubbees/full-cleans")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/tubbees/full-cleans")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
