import Footer from "../../Footer";
import Header from "../../Header";
import Button from "@mui/material/Button";
import "../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import party from "party-js";
import complete from "../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import SmokingRoomsIcon from "@mui/icons-material/SmokingRooms";
import axios from "axios";
import BlockIcon from "@mui/icons-material/Block";
import MobileOffIcon from "@mui/icons-material/MobileOff";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LocalDrinkIcon from "@mui/icons-material/LocalDrink";
import FuelAuthQuiz from "../../quizes/FuelAuth/FuelAuthQuiz";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function Auth(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [first, setFirst] = useState(false);
  const [intro, setIntro] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [four, setFour] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Fuel");
  const [child, setChild] = useState("FUEL AUTHORISATION");
  const ref = useRef(null)
  const [quiz, setQuiz] = useState({})
  const [answered, setAnswered] = useState(false);


  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFour(true)
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (four === true) {
      setFour(false);
      setThird(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Driveoff Completion`,
      sampled: true,
    });
    axios
      .post(
        "https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete",
        data
      )
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  function changeAnswered() {
    setAnswered(true)
  }


  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Fuel Authorisation</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>
                When deciding to authorise the pump or not, always check to see:
              </h4>
              <div className="row">
                <SmokingRoomsIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>If the customer is smoking</p>
              </div>
              <div className="row">
                <BlockIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>If the customer is of age to dispense fuel</p>
              </div>
              <div className="row">
                <MobileOffIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>If the customer is using their mobile phone</p>
              </div>
              <div className="row">
                <LocalGasStationIcon
                  fontSize="medium"
                  sx={{ color: "#bf1a1a" }}
                />
                <p>If the customer is using the correct pump</p>
              </div>
              <div className="row">
                <CarCrashIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>If the customer has left the car running</p>
              </div>
              <div className="row">
                <VisibilityIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  If you have good visibility of the customer, the pump and the
                  dispensing area.
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>Age</h4>
              <BlockIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                It is illegal to authorise fuel to anyone under the age of 16.
                It is also illegal to let anyone under the age of 16 to fill up
                even if they are accompanied by an adult
              </p>
              <p>
                If you are not sure if the customer is of age to dispense fuel,
                you must politely ask over the tannoy if they can come to the
                till to show you their ID. A valid ID contains a photograph and
                date of birth, for example a driving licence, a passport or any
                ID card which is PASS approved.
              </p>
              <p>
                If you do ever ask a customer to show their ID at the till,
                explain to them why you have called them over and always check
                the date of birth.
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>Containers</h4>
              <LocalDrinkIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                An approved type is correctly labelled metal or plastic
                container complying with SI 1982/63D. You will recognise them
                because you will see them at service stations for sale.
              </p>
              <p>
                The container must also be removed from the vehicle and placed
                flat on the fround to prevent spillages.
              </p>
              <p>
                <b>
                  There is no legal restrictions on the amount that can be
                  carried in a container, instead the quantity you allow someone
                  to fill is as the discretion of the company.
                </b>
              </p>
              <p>
                Remember, if the customer is filling a motorcycle check that
                they have dismounted before they attempt to fill the vehicle.
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>Visibility</h4>
              <VisibilityIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                Make sure no vehicles are blocking your view before authorising
                the pump. If you have an unclear view of any of the 3 previous
                points, wait until the other vehicle has finished dispensing
                their fuel, or if they have not started asking them politely to
                move using the tannoy.
              </p>
              <p>
                The dispensing area is the <b>4.3m</b> radius around the pump.
              </p>
            </div>
          ) : (
            <></>
          )}
          {four ? (
            <div className="unit">
              <h4>Check Your Knowledge</h4>
              <FuelAuthQuiz changeAnswered={changeAnswered}/>
            </div>
          ) : (
            <></>
          )}

          <div className="moveButtons">
            {!intro ? (
              <>
                {!four ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      {answered ? (
                        <Button
                          className="moveButton"
                          onClick={() => handleSubmit()}
                          variant="outlined"
                          color="success"
                        >
                          Submit
                        </Button>
                      ) : (
                        <></>
                      )}
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/fuel")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/fuel")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
