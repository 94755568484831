import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useEffect, useState } from "react";
import axios from "axios";
import party from "party-js";
import { useNavigate } from "react-router-dom";
import ModulePDF from './pdfs/lottery.pdf'
import * as Sentry from "@sentry/react";
import complete from "../../../../images/COMPLETE.png";
import pdfImg from "../../../../images/PDF_icon.png";

party.settings.debug = false;
party.resolvableShapes[
    "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
    shapes: [
        "square",
        "rectangle",
        "roundedSquare",
        "circle",
        "roundedRectangle",
        "star",
        // "logo"
    ],
    // The amount of confetti particles that should be emitted.
    count: party.variation.range(80, 200),
    // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
    spread: 30,
    // The initial speed that the confetti particles are emitted with.
    speed: party.variation.range(50, 600),
    // The initial size that the confetti particles are emitted with.
    size: party.variation.skew(1, 0.8),
    // The initial rotation that the confetti particles are emitted with.
    rotation: () => party.random.randomUnitVector().scale(180),
    // The initial color that particles are emitted with.
    color: () =>
        party.random.pick([
            party.Color.fromHex("#238446"),
            party.Color.fromHex("#1D7DB2"),
            party.Color.fromHex("#FFC800"),
            party.Color.fromHex("#FFFFFF"),
        ]),
};

export default function SystemPaypoint(props) {
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Systems Training");
    const [child, setChild] = useState("PayPoint Training");

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
            name: `Get Staff Details`,
            sampled: true,
        });
        axios
            .get(
                `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
            )
            .then((res) => {
                if (res.data) {
                    localStorage.removeItem("staff");
                    localStorage.setItem("staff", JSON.stringify(res.data));
                    // console.log(res.data);
                    setEmployee(res.data);
                    const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
                    const childCat = res.data.tasks[parentCat].taskData.findIndex(
                        (x) => x.task === child
                    );
                    if (res.data.tasks[parentCat].taskData[childCat].competent) {
                        setCompleted(true);
                    }
                }
            })
            .catch((err) => {
                Sentry.captureException(err)
            });
        transaction.finish();
    }, []);

    function handleSubmit() {
        var data = {
            staffTrainingId: employee.staffTrainingId,
            parent: parent,
            child: child,
        };
        const transaction = Sentry.startTransaction({
            name: `Submit Manager Paypoint`,
            sampled: true,
        });
        fetch(ModulePDF).then(response => {
            response.blob().then(blob => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'paypoint.pdf';
                alink.click();
                axios.post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
                    .then((res) => {
                        party.confetti(document.getElementById("confetti"), confettiSettings);
                    })
                    .catch((err) => {
                        Sentry.captureException(err)
                    });
                transaction.finish();
            })
        })

    }

    return (
        <section className="moduleHome">
            <Header stop={props.stop} />
            <h3>PayPoint</h3>
            <div className="unit">
                {!completed ?
                    <></>
                    :
                    <>
                        <div className="completed">
                            <img src={complete} />
                            <h2>Congratulations, This Unit Has Been Completed!</h2>

                        </div>
                    </>
                }
                <div className="completed" onClick={() => { handleSubmit() }}>
                    <img src={pdfImg} style={{ textAlign: "center" }}  />
                    <span style={{textAlign:"center"}}>Download</span>
                </div>
                {/* <button style={{textAlign:"center"}} onClick={() => { handleSubmit() }}>
                    Download PDF
                </button> */}
                <br />
                <Button variant="outlined" color="success" onClick={() => navigate("/manager/systems")} className="moveButton">Back</Button>
            </div>
            <Footer />
        </section>
    )

}