import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function JetWashes(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Jet Washes</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/jetwashes/chemicals")}>
          <img src={health} alt="checking chemicals" />
          <div className="completion">
            <h2>Checking Chemicals</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/jetwashes/damage")}>
          <img src={health} alt="damage check" />
          <div className="completion">
            <h2>Damage Check</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/jetwashes/recycle")}>
          <img src={health} alt="recycle cardboard/plastic" />
          <div className="completion">
            <h2>Recycle Cardboard / Plastic</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/jetwashes/empty")}>
          <img src={health} alt="empty bins" />
          <div className="completion">
            <h2>Empty Bins</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/jetwashes/waste")}>
          <img src={health} alt="waste treatment" />
          <div className="completion">
            <h2>Waste Treatment</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/jetwashes/forecourt")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/fuel/fuel_spillages.png" alt="forecourt spillages" />
          <div className="completion">
            <h2>Forecourt Spillages</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
