import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function CalorFlogas(props) {
  const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Calor/Flogas</h3>
      <div className="modules">
        <div className="module" onClick={() => navigate("/retail/calor-flogas/deposits")}>
          <img src={health} alt="deposits" />
          <div className="completion">
            <h2>Deposits</h2>
          </div>
        </div>
        <div className="module" onClick={() => navigate("/retail/calor-flogas/bottles")}>
          <img src={health} alt="sale of gas bottles" />
          <div className="completion">
            <h2>Sale Of Gas Bottles</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
