import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function NitroHome(props){
    const navigate = useNavigate();
    return(
        <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>NITRO SOLUTIONS</h3>
      <div className="modules">
        <div className="module tills" onClick={() => navigate("/nitro/cctv")}>
          <img src={health} alt="nitro cctv" />
          <div className="completion">
            <h2>CCTV</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/nitro/onsite")}>
          <img src={health} alt="nitro onsite" />
          <div className="completion">
            <h2>On-Site</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
    )
}