import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function TubbeesHome(props){
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Tubbees</h3>
      <div className="modules">
        <div className="module tills" onClick={() => navigate("/tubbees/slush")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/tubbees/SLUSH.webp" alt="health &amp; safety" />
          <div className="completion">
            <h2>Slush</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/ice-cream-smoothies")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/tubbees/ice_cream.webp" alt="health &amp; safety" />
          <div className="completion">
            <h2>Ice Cream / Smoothies</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/kitchen")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/tubbees/KITCHEN.webp" alt="health &amp; safety" />
          <div className="completion">
            <h2>Kitchen</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/date-maintenance")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Date Maintenance</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/checks")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Checks</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/decorating")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Decorating</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/mixes")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Mixes</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/full-cleans")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Full Cleans</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/baking")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Baking</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/stock-filling")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Stock Filling</h2>
          </div>
        </div>
        <div className="module tills" onClick={() => navigate("/tubbees/paperwork")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Paperwork</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}