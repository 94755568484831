import Footer from "../../Footer";
import Header from "../../Header";
import Button from "@mui/material/Button";
import "../module.scss";
import { useNavigate } from "react-router-dom";




export default function MaintenanceHome(props) {
    const navigate = useNavigate();

    return(
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>Maintenance</h3>
            <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/knowing-menu")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/menu.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Knowing Menu</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/knowing-shop")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/shop.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Knowing Shop</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/music-on")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/music.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Music On</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/screens-on")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/SCREEN.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Screens On</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/match-days")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/match_day.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Match Days</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/delivery-tablets")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/tablet.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Delivery Tablets</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/maintenance/too-good-to-go")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/maintenance/tgtg.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Too Good to Go</h2>
          </div>
        </div>
      </div>
            <Footer/>
        </section>
    )
}