import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function CsHome(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Customer Services</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/core/customer-service/dealing-customers")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/cs/dealingCustomers.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Dealing with Customers</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/customer-service/reps")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Dealing with Reps</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/customer-service/site-visitors")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/core/cs/site-visitors.png" alt="health &amp; safety" />
          <div className="completion">
            <h2>Site Visitors</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/core/customer-service/returns")}>
          <img src={health} alt="health &amp; safety" />
          <div className="completion">
            <h2>Dealing with Returns</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
