import Footer from "../../../Footer";
import Header from "../../../Header";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import axios from "axios";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import MicrowaveIcon from "@mui/icons-material/Microwave";
import GavelIcon from "@mui/icons-material/Gavel";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import KebabDiningIcon from "@mui/icons-material/KebabDining";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function TempCheck(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [first, setFirst] = useState(false);
  const [intro, setIntro] = useState(true);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Checks");
  const [child, setChild] = useState("TEMPERATURE CHECKS");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } 
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } 
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Temperature Checks Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Temp Checks</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <h4>Food Handler Reponsibilities</h4>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Ensuring every individual follows the Food Safety Procedures
                </p>
              </div>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Cooperate with management regarding Food Safety</p>
              </div>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Maintaining the highest standards of personal hygene including
                  company policy on personal presentation
                </p>
              </div>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Participate in training as the job role necessitates</p>
              </div>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Report to management any issues that may compromise food
                  safety
                </p>
              </div>
              <div className="row">
                <TaskAltIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Report any infections, food poisoning symptoms and any other
                  condition that may compromise food safety
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <p>
                In adition to our grocery aisles, we have other types of food.
              </p>
              <p>
                Specific storage and temperature controls regarding these are in
                place.
              </p>
              <p>Different categories can be:</p>
              <div className="row">
                <MicrowaveIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Cooked meat and poultry</p>
              </div>
              <div className="row">
                <MicrowaveIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Cooked meat products including pate and meat pies</p>
              </div>
              <div className="row">
                <MicrowaveIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Dairy products - milk, cream, custards, ice-cream, etc</p>
              </div>
              <div className="row">
                <MicrowaveIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Cooked rice, pasta, cooked potato/vegetables</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>Food Safety legislation</h4>
              <p>
                Food safety law is applicable to all stages of the food
                production chain, production to end user, and any person
                involved in the food production process could be prosecuted for
                a breach of teh law.
              </p>
              <p>
                The Food Safety Act 1990 is mainly concerned with food standards
                and makes it an offence to:
              </p>
              <div className="row">
                <GavelIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Render food that is injurious to health with the intent that
                  the food will be sold in the same state
                </p>
              </div>
              <div className="row">
                <GavelIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Provide food that is not of the nature, substance or quality
                  demanded by the consumer
                </p>
              </div>
              <div className="row">
                <GavelIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>To falsely or mislead describing of food</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div classname="unit">
              <p>
                It is important to check all deliveries, before you accept them.
                If you don't you could be accepting goods that have been
                contaminated, or could be unfit for human consumption.
              </p>
              <p>Control through receipt</p>
              <div className="row">
                <ThermostatIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Correct temperature of the vehicle for both frozen (between
                  -18 to -20 degrees) and chilled (between 1 and 5 degrees)
                </p>
              </div>
              <div className="row">
                <ThermostatIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Check date codes and shelf life</p>
              </div>
              <div className="row">
                <ThermostatIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Chemical separate from food</p>
              </div>
              <div className="row">
                <ThermostatIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>No evidence of pests</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>Correct storage of food</h4>
              <div className="row">
                <KebabDiningIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Pasties to be sold hot must be cooked or reheated to 75°C. Hot
                  holding temperature must be at least 63°C.
                </p>
              </div>
              <div className="row">
                <KebabDiningIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>
                  Bake off products, breads and pastries have to be stored at
                  ambient temperature.
                </p>
              </div>
              <div className="row">
                <KebabDiningIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Fresh milk and cream stored under 5°C</p>
              </div>
              <div className="row">
                <KebabDiningIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Frozen Foods must be stored at -18°C</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <h4>Taking temperatures</h4>
              <p>
                The most common temperature monitoring device is a probe. They
                must be test regularly to make sure they are working properly
              </p>
              <ul>
                <li>Place them in boiling water between 99°C and 100°C</li>
                <li>
                  Test it again in iced water, where temperature should be
                  between -1°C and 1°C
                </li>
              </ul>
              <p>
                Remember probes can be a source of cross contamination if not
                cleaned and desinfected between uses
              </p>
              <p>
                Refrigerators and freezers must be checked regularly, at leas
                once a day in the morning to ensure it has been working through
                the night.
              </p>
              <ul>
                <li>
                  Place a probe inside one of the shelves, leave it for a couple
                  of minutes and write the readings on the monitoring sheet.
                </li>
              </ul>
              <p>Faulty refrigerators or freezers should be reported straight away.</p>
              <p>Best practice is to ensuure you check the doors and temperatures every time you pass by refrigerators and freezers.</p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!fifth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/retail/hoagies")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/retail/hoagies")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
