import "../quizes.scss";
import { useState } from "react";

export default function SlipQuiz(props) {
  const [score, setScore] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [correctAnswer, setCorrectAnswer] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState(false);

  const questions = [
    {
      questionText: "You see a drinks spillage at work, what do you do?",
      answerOptions: [
        {
          answerText: "Ignore it because it's not your job to clear it up",
          isCorrect: false,
        },
        {
          answerText: "Clean it up yourself and leave the floor dry",
          isCorrect: true,
        },
        {
          answerText:
            "Put a barrier around the spill and report it to a cleaner or supervisor",
          isCorrect: false,
        },
      ],
    },
    {
      questionText:
        "You don't have to worry about trips and slips if you are on level ground",
      answerOptions: [
        {
          answerText: "True",
          isCorrect: false,
        },
        {
          answerText: "False",
          isCorrect: true,
        },
      ],
    },
  ];

  const handleAnswerOptionClick = (answerOption, question) => {
    const findQuestion = (element) => element.questionText == question;
    var index = questions.findIndex(findQuestion);
    const found = questions[index].answerOptions.find(
      (element) => element.isCorrect == true
    );
    if (answerOption.isCorrect) {
      setScore(score + 1);
      setCorrectAnswer(true)
      setTimeout(() => {
        setCorrectAnswer(false)
        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        } else {
            props.changeAnswered()
        }
      },2500)
    } else {
        setWrongAnswer(true)
        setTimeout(() => {
            setWrongAnswer(false)
        },2500)
    }
  };

  return (
    <div className="question-quiz">
      <h4>{questions[currentQuestion].questionText}</h4>
      <div className="possibleAnswers">
        {questions[currentQuestion].answerOptions.map((answerOption) => (
          <button
            onClick={() =>
              handleAnswerOptionClick(
                answerOption,
                questions[currentQuestion].questionText
              )
            }
            style={{textAlign: "center"}}
          >
            {answerOption.answerText}
          </button>
        ))}
        {correctAnswer ? (
          <div className="correct">Well done! That's correct</div>
        ) : (
          <></>
        )}
        {wrongAnswer ? (
          <div className="incorrect">That's not right, try again</div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
