import Footer from "../../Footer";
import Header from "../../Header";
import Button from "@mui/material/Button";
import "../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import DriveOffQuiz from "../../quizes/driveOff/DriveOffQuiz";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function DriveOff(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [seventh, setSeventh] = useState(false)
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Fuel");
  const [child, setChild] = useState("DRIVE OFF PREVENTION");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [answered, setAnswered] = useState(false);


  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    } else if (sixth === true){
      setSixth(false)
      setSeventh(true)
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    } else if (seventh === true){
      setSeventh(false)
      setSixth(true)
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Driveoff Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  function changeAnswered() {
    setAnswered(true)
  }


  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Drive Off Procedure</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                The Company has a strict Drive Off Policy in which all employees
                must adhere to at all times. This company policy outlines the
                steps in which employees must follow in the event of a drive
                off. The term ‘Drive Off’ refers to a when customer/ visitor to
                the site steals fuel from the site. Theft of fuel typically
                happens in one of two ways; 1. Customer puts fuel into the
                vehicle and does not follow up with payment or, 2. Customer puts
                fuel into a container and does not follow up with payment. When
                a customer takes fuel from the dispenser and enters the shop to
                pay but does not, this in certain circumstances will not be
                classed as a drive off. An explanation of this will be outlines
                within the Policy Elements.
              </p>
              <p>
                Drive Offs to the Business cause financial loss and if not dealt
                with correctly can result in the site to gain a bad reputation.
                If the site is known for receiving Drive Offs regularly it can
                be detrimental to the Business. Customers may choose not to use
                our services if there is public knowledge that this criminal
                activity happens. Alternatively, it the site gets this
                reputation (in our local community) then we could fall into the
                category of being a service provider that allows this and
                therefore attracts thieves.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>Drive Off Avareness</h4>
              <p>
                Drive offs do occur and you must be vigilant when performing
                your duty of fuel dispenser.
              </p>
              <p>
                Someone driving off without paying for fuel, costs the company
                and can impact on the prices. CUSTOMERS MUST PAY.
              </p>
              <p>
                For example, each time a person drives off with fifty pounds
                worth of fuel we would have to sell approximately 2,000 litres
                worth of fuel to break even.
              </p>
              <p>
                Almost all drive offs are avoidable if you keep alert and aware
                of what is happening always. Vigilance is key
              </p>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <h4>Spotting potential drive off</h4>
              <p>
                We are going to look at the main things to look out for before
                authorising a pump, to prevent drive offs.
              </p>
              <p>
                This drive off trait makes for a quick getaway. If you also
                notice the engine is running, it should make you cautious of the
                driver
              </p>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>No Number Plate</h4>
              <p>
                Always check if the vehicle has a number plate on or not. Not
                having a plate on is a key trait for a potential drive off.
                Furthermore not having a number plate is illegal and makes
                impossible getting police support following a crime.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>If the driver or passenger has left their door open</h4>
              <p>
                Another way to spot a potential drive off is to check whether the
                driver or the passenger has left their door open.
              </p>
              <p>Leaving doors open helps for a quick getaway.</p>
              <h4>If the passenger is the one filling up</h4>
              <p>
                If the passenger is the one filling up, whilst the driver is
                still in their seat will help for a quick getaway.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <h4>Vehicle near the entrance or exit pumps</h4>
              <p>
                Pay close attention to vehicles that park near the entrance or
                exit pumps.
              </p>
              <p>
                Speak to your manager or colleagues to find out which pumps and
                hours of the day drive offs have happened.
              </p>
              <h4>Vehicle facing the right way</h4>
              <p>Pay attention to the position on the car in the pumps.</p>
              <h4>Is the car a known car?</h4>
              <p>
                i.e. this car has been caught before doing a drive off in the
                past.
              </p>
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <div className="unit">
              <h4>What to do if a drive off happens?</h4>
              <p>You must take all details of the car:</p>
              <ul>
                <li>Registration Number</li>
                <li>Date and Time</li>
                <li>Pump Number</li>
                <li>Fuel Type</li>
                <li>Amount</li>
                <li>Volume</li>
              </ul>
              <p>
                Go to drive off system on the store phone or tablet and ensure
                to fill all details needed.
              </p>
            </div>
          ) : (
            <></>
          )}
          {seventh ? (
            <div className="unit">
              <h4>Test your Knowledge</h4>
              <DriveOffQuiz changeAnswered={changeAnswered} />
            </div>
          ):(
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!seventh ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <>
                      <div className="moveButtons" id="confetti">
                        <Button
                          className="moveButton"
                          onClick={() => prev()}
                          variant="outlined"
                          color="error"
                        >
                          Prev
                        </Button>
                        {answered ? (
                        <Button
                          className="moveButton"
                          onClick={() => handleSubmit()}
                          variant="outlined"
                          color="success"
                        >
                          Submit
                        </Button>
                        ):(
                          <></>
                        )}
                      </div>
                      <Button
                        onClick={handleOpen}
                        variant="outlined"
                        color="warning"
                        sx={{ marginTop: "2%" }}
                      >
                        View Policy
                      </Button>
                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ overflow: "scroll" }}
                      >
                        <Box sx={style}>
                          <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h2"
                          >
                            Drive Off Policy
                          </Typography>
                          <Typography
                            id="modal-modal-description"
                            sx={{ mt: 2 }}
                          >
                            <>
                              <p>The following rules always apply:</p>
                              <ul>
                                <li>
                                  In order to prevent a drive off from happening
                                  all employees must ensure they are paying
                                  attention to the forecourt. Random spot checks
                                  should be completed by all employees
                                  throughout the day. Customers will not steal
                                  from a service provider if they know they are
                                  being watched/ monitored.
                                </li>
                                <li>
                                  All customers must be asked if they have any
                                  fuel today. No exceptions.
                                </li>
                                <li>
                                  If a customer comes into the shop after
                                  fuelling up and has not been asked the above
                                  question it is not the customers fault if we
                                  do not receive payment. Therefore, it is vital
                                  that employees keep themselves right and ask
                                  the question. Even if you suspect they haven't
                                  taken fuel/ aren't in a vehicle there is no
                                  harm in asking this question. Employees will
                                  be liable for this if this step is not taken.
                                </li>
                                <li>
                                  Also remember you will have difficulty in
                                  watching the forecourt if you have a queue.
                                  Two people is a queue, so ask your colleagues
                                  for help. If you have help you will be able to
                                  keep an eye on the forecourt for suspicious
                                  behaviour.
                                </li>
                                <li>
                                  Some of the key indicators to arouse suspicion
                                  are: car lights on. Driver not refuelling but
                                  allowing a passenger to do it. Purposefully
                                  avoiding eye contact. Strange looking
                                  registration plates.
                                </li>
                              </ul>
                              <p>
                                If the customer does steal fuel the following
                                steps must be taken:
                              </p>
                              <ol>
                                <li>
                                  Record as much details of the car and person
                                  as possible. The registration is what you
                                  should try andnote down first. The colour of
                                  the car and possible make/ model of the car
                                  should be recorded. If possible, try and
                                  record a description of the person. This will
                                  help Head Office and the police with
                                  theirenquiries.
                                </li>
                                <li>
                                  Report the offence to the police. Where
                                  possible pass all this information to your
                                  line manager or supervisor so that we can
                                  alert the authorities at the earliest stage.
                                  If you are in a single manned site please call
                                  the police on ‘101’ yourself and say you wish
                                  to report a drive off. This term is known to
                                  local police and will understand what you are
                                  talking about. If you do speak with the
                                  police, thenrecord as much detail as possible
                                  i.e. when are they coming to the site to
                                  collect CCTV footage, who is officer assign to
                                  our case. Your line manager will speak with
                                  our IT Department in order to burn the CCTV
                                  and will organise this to be given to the
                                  site. All this information must be posted onto
                                  your WorkGroup WhatsApp immediately.
                                </li>
                                <li>
                                  Process the transaction. You should process
                                  the amount stolen through your till as a drive
                                  off. Print off three receipts and file
                                  accordingly; 1st one into the cash sheet, 2nd
                                  into the folder and 3rd one at the cash point
                                  in case the driver returns to the site to pay.
                                </li>
                              </ol>
                              <Button
                                variant="outlined"
                                color="success"
                                onClick={handleClose}
                                style={{ margin: "auto" }}
                              >
                                Close Policy
                              </Button>
                            </>
                          </Typography>
                        </Box>
                      </Modal>
                    </>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/fuel")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/fuel")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
          <Button
            onClick={handleOpen}
            variant="outlined"
            color="warning"
            sx={{ marginTop: "2%" }}
          >
            View Policy
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ overflow: "scroll" }}
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Drive Off Policy
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <>
                  <p>The following rules always apply:</p>
                  <ul>
                    <li>
                      In order to prevent a drive off from happening all
                      employees must ensure they are paying attention to the
                      forecourt. Random spot checks should be completed by all
                      employees throughout the day. Customers will not steal
                      from a service provider if they know they are being
                      watched/ monitored.
                    </li>
                    <li>
                      All customers must be asked if they have any fuel today.
                      No exceptions.
                    </li>
                    <li>
                      If a customer comes into the shop after fuelling up and
                      has not been asked the above question it is not the
                      customers fault if we do not receive payment. Therefore,
                      it is vital that employees keep themselves right and ask
                      the question. Even if you suspect they haven't taken fuel/
                      aren't in a vehicle there is no harm in asking this
                      question. Employees will be liable for this if this step
                      is not taken.
                    </li>
                    <li>
                      Also remember you will have difficulty in watching the
                      forecourt if you have a queue. Two people is a queue, so
                      ask your colleagues for help. If you have help you will be
                      able to keep an eye on the forecourt for suspicious
                      behaviour.
                    </li>
                    <li>
                      Some of the key indicators to arouse suspicion are: car
                      lights on. Driver not refuelling but allowing a passenger
                      to do it. Purposefully avoiding eye contact. Strange
                      looking registration plates.
                    </li>
                  </ul>
                  <p>
                    If the customer does steal fuel the following steps must be
                    taken:
                  </p>
                  <ol>
                    <li>
                      Record as much details of the car and person as possible.
                      The registration is what you should try andnote down
                      first. The colour of the car and possible make/ model of
                      the car should be recorded. If possible, try and record a
                      description of the person. This will help Head Office and
                      the police with theirenquiries.
                    </li>
                    <li>
                      Report the offence to the police. Where possible pass all
                      this information to your line manager or supervisor so
                      that we can alert the authorities at the earliest stage.
                      If you are in a single manned site please call the police
                      on ‘101’ yourself and say you wish to report a drive off.
                      This term is known to local police and will understand
                      what you are talking about. If you do speak with the
                      police, thenrecord as much detail as possible i.e. when
                      are they coming to the site to collect CCTV footage, who
                      is officer assign to our case. Your line manager will
                      speak with our IT Department in order to burn the CCTV and
                      will organise this to be given to the site. All this
                      information must be posted onto your WorkGroup WhatsApp
                      immediately.
                    </li>
                    <li>
                      Process the transaction. You should process the amount
                      stolen through your till as a drive off. Print off three
                      receipts and file accordingly; 1st one into the cash
                      sheet, 2nd into the folder and 3rd one at the cash point
                      in case the driver returns to the site to pay.
                    </li>
                  </ol>
                  <Button
                    variant="outlined"
                    color="success"
                    onClick={handleClose}
                    style={{ margin: "auto" }}
                  >
                    Close Policy
                  </Button>
                </>
              </Typography>
            </Box>
          </Modal>
        </div>
      )}
      <Footer />
    </section>
  );
}
