import Header from "../../TubbeesHeader";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function PaperworkHome(props){
    const navigate = useNavigate();

    return (
      <main className="moduleHome">
        <Header stop={props.stop}/>
        <h3>Paperwork</h3>
        <div className="modules">
          <div className="module tills" onClick={() => navigate("/tubbees/paperwork/ice-cream-count")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Ice Cream Count</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/paperwork/slush-count")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Slush Count</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/paperwork/allergens")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Allergens</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/paperwork/wastage")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Wastage</h2>
            </div>
          </div>
          <div className="module tills" onClick={() => navigate("/tubbees/paperwork/stock-ordering")}>
            <img src={health} alt="health &amp; safety" />
            <div className="completion">
              <h2>Stock Ordering</h2>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    );

}