import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";

export default function NitroCctv(props){
    const navigate = useNavigate();

    return(
        <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Nitro - CCTV</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/nitro/cctv/data-handling")}>
          <img src={health} alt="nitro cctv data handling" />
          <div className="completion">
            <h2>Data Handling</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
    )
}