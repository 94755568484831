import Footer from "../../../Footer";
import Header from "../../../TubbeesHeader";
import Button from "@mui/material/Button";
import "../../module.scss";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import party from "party-js";
import complete from "../../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import axios from "axios";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};


export default function CookieDough(props){
    const navigate = useNavigate();
    const [employee, setEmployee] = useState([]);
    const [first, setFirst] = useState(false);
    const [intro, setIntro] = useState(true);
    const [second, setSecond] = useState(false);
    const [third, setThird] = useState(false);
    const [unit, setUnit] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [parent, setParent] = useState("Baking/Making Products");
    const [child, setChild] = useState("COOKIE DOUGH");

    useEffect(() => {
        var data = localStorage.getItem("staff");
        data = JSON.parse(data);
        const transaction = Sentry.startTransaction({
          name: `Get Staff Details`,
          sampled: true,
        });
        axios
          .get(
            `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
          )
          .then((res) => {
            if (res.data) {
              localStorage.removeItem("staff");
              localStorage.setItem("staff", JSON.stringify(res.data));
              // console.log(res.data);
              setEmployee(res.data);
              const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
              const childCat = res.data.tasks[parentCat].taskData.findIndex(
                (x) => x.task === child
              );
              if (res.data.tasks[parentCat].taskData[childCat].competent) {
                setCompleted(true);
              }
            }
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
        transaction.finish();
      }, []);
    
      function next() {
        if (intro === true) {
          setIntro(false);
          setFirst(true);
        } else if (first === true) {
          setFirst(false);
          setSecond(true);
        } else if (second === true) {
          setSecond(false);
          setThird(true);
        }
      }
    
      function prev() {
        if (first === true) {
          setFirst(false);
          setIntro(true);
        } else if (second === true) {
          setSecond(false);
          setFirst(true);
        } else if (third === true) {
          setThird(false);
          setSecond(true);
        }
      }
    
      function handleSubmit() {
        var data = {
          staffTrainingId: employee.staffTrainingId,
          parent: parent,
          child: child,
        };
        const transaction = Sentry.startTransaction({
          name: `Submit cookie dough Completion`,
          sampled: true,
        });
        axios
          .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
          .then((res) => {
            party.confetti(document.getElementById("confetti"), confettiSettings);
            setUnit(true);
          })
          .catch((err) => {
            Sentry.captureException(err);
          });
        transaction.finish();
      }
    
  

    return(
        <section className="moduleHome">
            <Header stop={props.stop}/>
            <h3>Cookie Dough</h3>
            <div className="unit">
            <p style={{color: "#8C8C8C", textAlign:"center"}}>This unit will be coming soon</p>
            <Button variant="outlined" color="success" onClick={()=> navigate("/tubbees/baking")} className="moveButton">Back</Button>
            </div>
            <Footer/>
        </section>
    )

}