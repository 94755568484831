import Footer from "../../Footer";
import Header from "../../Header";
import Button from "@mui/material/Button";
import "../module.scss";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import party from "party-js";
import complete from "../../../images/COMPLETE.png";
import * as Sentry from "@sentry/react";
import SportsMmaIcon from "@mui/icons-material/SportsMma";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import ShowerIcon from "@mui/icons-material/Shower";
import WaterIcon from "@mui/icons-material/Water";
import BlurOnIcon from "@mui/icons-material/BlurOn";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import DangerousIcon from "@mui/icons-material/Dangerous";

party.settings.debug = false;
party.resolvableShapes[
  "logo"
] = `<img width="32px" src="https://i.pinimg.com/originals/eb/e4/9c/ebe49c9609b15a98b84de9f2b057b16f.png"/>`;

const confettiSettings = {
  shapes: [
    "square",
    "rectangle",
    "roundedSquare",
    "circle",
    "roundedRectangle",
    "star",
    // "logo"
  ],
  // The amount of confetti particles that should be emitted.
  count: party.variation.range(80, 200),
  // The amount of spread that is applied to the emission angle. Note that the default angle points upwards.
  spread: 30,
  // The initial speed that the confetti particles are emitted with.
  speed: party.variation.range(50, 600),
  // The initial size that the confetti particles are emitted with.
  size: party.variation.skew(1, 0.8),
  // The initial rotation that the confetti particles are emitted with.
  rotation: () => party.random.randomUnitVector().scale(180),
  // The initial color that particles are emitted with.
  color: () =>
    party.random.pick([
      party.Color.fromHex("#238446"),
      party.Color.fromHex("#1D7DB2"),
      party.Color.fromHex("#FFC800"),
      party.Color.fromHex("#FFFFFF"),
    ]),
};

export default function FuelSpillages(props) {
  const navigate = useNavigate();
  const [employee, setEmployee] = useState([]);
  const [intro, setIntro] = useState(true);
  const [first, setFirst] = useState(false);
  const [second, setSecond] = useState(false);
  const [third, setThird] = useState(false);
  const [fourth, setFourth] = useState(false);
  const [fifth, setFifth] = useState(false);
  const [sixth, setSixth] = useState(false);
  const [unit, setUnit] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [parent, setParent] = useState("Fuel");
  const [child, setChild] = useState("FUEL SPILLAGES");

  useEffect(() => {
    var data = localStorage.getItem("staff");
    data = JSON.parse(data);
    const transaction = Sentry.startTransaction({
      name: `Get Staff Details`,
      sampled: true,
    });
    axios
      .get(
        `https://backend.training.racetrackpitstop.co.uk/trainingAdmin/staffId?id=${data.staffTrainingId}`
      )
      .then((res) => {
        if (res.data) {
          localStorage.removeItem("staff");
          localStorage.setItem("staff", JSON.stringify(res.data));
          // console.log(res.data);
          setEmployee(res.data);
          const parentCat = res.data.tasks.findIndex((x) => x.name === parent);
          const childCat = res.data.tasks[parentCat].taskData.findIndex(
            (x) => x.task === child
          );
          if (res.data.tasks[parentCat].taskData[childCat].competent) {
            setCompleted(true);
          }
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }, []);

  function next() {
    if (intro === true) {
      setIntro(false);
      setFirst(true);
    } else if (first === true) {
      setFirst(false);
      setSecond(true);
    } else if (second === true) {
      setSecond(false);
      setThird(true);
    } else if (third === true) {
      setThird(false);
      setFourth(true);
    } else if (fourth === true) {
      setFourth(false);
      setFifth(true);
    } else if (fifth === true) {
      setFifth(false);
      setSixth(true);
    }
  }

  function prev() {
    if (first === true) {
      setFirst(false);
      setIntro(true);
    } else if (second === true) {
      setSecond(false);
      setFirst(true);
    } else if (third === true) {
      setThird(false);
      setSecond(true);
    } else if (fourth === true) {
      setFourth(false);
      setThird(true);
    } else if (fifth === true) {
      setFifth(false);
      setFourth(true);
    } else if (sixth === true) {
      setSixth(false);
      setFifth(true);
    }
  }

  function handleSubmit() {
    var data = {
      staffTrainingId: employee.staffTrainingId,
      parent: parent,
      child: child,
    };
    const transaction = Sentry.startTransaction({
      name: `Submit Fuel Spillages Completion`,
      sampled: true,
    });
    axios
      .post("https://backend.training.racetrackpitstop.co.uk/trainingStaff/staffComplete", data)
      .then((res) => {
        party.confetti(document.getElementById("confetti"), confettiSettings);
        setUnit(true);
      })
      .catch((err) => {
        Sentry.captureException(err);
      });
    transaction.finish();
  }

  return (
    <section className="moduleHome">
      <Header stop={props.stop} />
      <h3>Fuel Spillages</h3>
      {!completed ? (
        <>
          {intro ? (
            <div className="unit">
              <p>
                You have reduced the risk by carefully monitoring every pump but
                there will times that fuel spills on the forecourt.
              </p>
              <p>
                You need to know what to do to keep everyone safe, clear the
                spillage and get the pumps working again.
              </p>
            </div>
          ) : (
            <></>
          )}
          {first ? (
            <div className="unit">
              <h4>
                You will need the following items to clear a fuel spillage
              </h4>
              <div className="row">
                <FormatColorFillIcon
                  fontSize="medium"
                  sx={{ color: "#bf1a1a" }}
                />
                <p>A forecourt bucket</p>
              </div>
              <div className="row">
                <BlurOnIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Dry sand and spreader</p>
              </div>
              <div className="row">
                <SportsMmaIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Rubber gloves</p>
              </div>
              <div className="row">
                <ShowerIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>A hose pipe (large spillages only)</p>
              </div>
              <div className="row">
                <WaterIcon fontSize="medium" sx={{ color: "#bf1a1a" }} />
                <p>Cold water (large spillages only)</p>
              </div>
            </div>
          ) : (
            <></>
          )}
          {second ? (
            <div className="unit">
              <p>
                Fuel and fuel vapour are highly explosive, meaning they must be
                dealt with immediately. If a large spill occurs, switch off all
                the pumps until the problem has been dealt with and do not
                authorise any new customers. You must ensure that you inform
                customeres that they are not to start their vehicles until you
                have announced that it is safe to do so.
              </p>
              <p>
                We are now going to look at some of the different scenarios that
                may occur as the result of a spillage and how the different ways
                in which you would clean each of there.
              </p>
              <h4>
                Remember: you must never compromise the safety of customers or
                staff.
              </h4>
            </div>
          ) : (
            <></>
          )}
          {third ? (
            <div className="unit">
              <h4>Fuel blow back</h4>
              <LocalGasStationIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                Most fuel spillages are a result of a fuel blow back, which is
                caused when the nozzle spashes back.
              </p>
              <p>
                IF A SMALL OR LARGE SPILLAGE OCCURS AROUND THE PUMP YOU SHOULD:
              </p>
              <ul>
                <li>Get everyone out of the car.</li>
                <li>Prevent the fuel spreading over by using dry sand.</li>
                <li>
                  Ask the driver to push the car away from the spillage area
                  before starting the engine.
                </li>
                <li>
                  Brush up the sand and put it in the designated area for
                  contaminated sand.
                </li>
              </ul>
              <p>
                Once you have dealt with the spillage, remember to check if the
                customer is ok.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fourth ? (
            <div className="unit">
              <h4>Shop Spillages</h4>
              <WarningAmberIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                You can never tell how much fuel has leaked or been spilt just
                by smell. If you smell any vapour never ignore it - always act.
              </p>
              <p>
                If you smell fuel in the shop, open all windows and try to find
                out where the smell is coming from. Do not switch on any lights
                as a light switch could cause a spark, which could ignite the
                vapour and cause an explosion.
              </p>
              <p>
                You must inform the manager of a spillage in the shop and you
                should refer to your emergency procedures.
              </p>
              <p>
                Identify the pump that is leaking and label it "out of use"
                until has been repaired.
              </p>
              <p>
                You need to know what to do to keep everyone safe, clear the
                spillage, and get the pumps working again. When a spillage
                occurs on the forecourt inform your manager once you have
                cleared up, so that they can arrange for a forecourt clean to
                take place.
              </p>
              <p>
                Fuel is a skin irritant so you will always need to use heavy
                duty rubber when clearing up any petrol spillages.
              </p>
              <p>
                You must ensure you refill the sand to the correct level after
                use, to be ready for further fuel spillages.
              </p>
            </div>
          ) : (
            <></>
          )}
          {fifth ? (
            <div className="unit">
              <h4>Large Spillages - Tankers</h4>
              <LocalShippingIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                Large spillages may happen during a fuel delivery. In this event
                the site's Forecourt Competent Person and the tanker driver
                should be on hand to deal with the situation. The correct
                actions are:
              </p>
              <ul>
                <li>Activate the emergency shutdown switch.</li>
                <li>
                  Evacuate the forecourt area and explain why you are doing so
                  to customers using the tannoy.
                </li>
                <li>Call the fire service.</li>
                <li>Prevent the fuel from spreading using sand.</li>
                <li>
                  If necessary, wash the spillage into the interceptors (the
                  special drainage system that surrounds the forecourt area: they
                  are designed to ensure that no contaminated water enters the
                  public drainage system)
                </li>
                <li>Get fire extinguishers ready for use.</li>
              </ul>
              <p>
                As with any incident, you should inform your manager
                immediately. With large spillages, you also need to inform the
                PEA Officer (Petroleum Enforcement Authority).
              </p>
            </div>
          ) : (
            <></>
          )}
          {sixth ? (
            <div className="unit">
              <h4>Contact with skin</h4>
              <DangerousIcon
                className="unit-icon"
                sx={{ color: "#bf1a1a", fontSize: "3rem" }}
              />
              <p>
                If any of the fuel gets in contact with your skin or the
                customer's skin, you must wash the skin thoroughly with warm
                soapy water immediately.
              </p>
              <p>
                First Aiders will be trained in what to do if a person is to
                come into contact with fuel. However it is imperative that you read
                the COSHH safety data sheets for both petrol and diesel to
                familiarise yourself with the measures that must be taken.
              </p>
              <p>Remember: as with any incident, you should always inform your manager immediately.</p>
            </div>
          ) : (
            <></>
          )}
          <div className="moveButtons">
            {!intro ? (
              <>
                {!sixth ? (
                  <>
                    <Button
                      onClick={() => prev()}
                      variant="outlined"
                      color="error"
                      className="moveButton"
                    >
                      Prev
                    </Button>
                    <Button
                      className="moveButton"
                      onClick={() => next()}
                      variant="outlined"
                      color="error"
                    >
                      Next
                    </Button>
                  </>
                ) : (
                  <div className="unit">
                    <div className="moveButtons" id="confetti">
                      <Button
                        className="moveButton"
                        onClick={() => prev()}
                        variant="outlined"
                        color="error"
                      >
                        Prev
                      </Button>
                      <Button
                        className="moveButton"
                        onClick={() => handleSubmit()}
                        variant="outlined"
                        color="success"
                      >
                        Submit
                      </Button>
                    </div>
                    {unit ? (
                      <>
                        <br />
                        <div className="unit">
                          <h6>Congratulations! you finished this module</h6>
                          <Button
                            onClick={() => navigate("/fuel")}
                            variant="contained"
                            color="success"
                            sx={{alignSelf: "center"}}
                          >
                            Go back to units
                          </Button>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
              </>
            ) : (
              <Button
                className="moveButton"
                onClick={() => next()}
                variant="outlined"
                color="error"
              >
                Next
              </Button>
            )}
          </div>
        </>
      ) : (
        <div className="completed">
          <img src={complete} />
          <h2>This unit has been completed.</h2>
          <p>Do you want to do it again?</p>
          <div className="moveButtons" id="confetti">
            <Button
              className="moveButton"
              onClick={() => setCompleted(false)}
              variant="outlined"
              color="error"
            >
              Yes
            </Button>
            <Button
              className="moveButton"
              onClick={() => navigate("/fuel")}
              variant="outlined"
              color="success"
            >
              No
            </Button>
          </div>
        </div>
      )}
      <Footer />
    </section>
  );
}
