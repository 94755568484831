import React from 'react';
import { useEffect, useState, useRef } from "react";

export default function AllergenTypes(prop) {

  const style = {
    cursor: prop.dragOverlay ? "grabbing" : "grab",
  };
  
  return (
    <div style={style} className="item">
      <p>{prop.id}</p>
    </div>
  );

}