import {useState} from 'react'
import './quizes.scss'

export default function NMOPQuiz(props){
    const [score, setScore] = useState(0);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [correctAnswer, setCorrectAnswer] = useState(false);
    const [wrongAnswer, setWrongAnswer] = useState(false);
  

    const questions = [
        {
          questionText: "When are you allow to fill the form?",
          answerOptions: [
            {
              answerText: "Anytime I want",
              isCorrect: false,
            },
            {
              answerText: "When the customer doesn't have a mean of payment and has already put fuel",
              isCorrect: true,
            },
            {
              answerText:
                "Anytime there is a drive off",
              isCorrect: false,
            },
          ],
        },
        {
          questionText:
            "What do you do if the DVLA details don't match the car?",
          answerOptions: [
            {
              answerText: "Continue, it doesn't matter if they don't match",
              isCorrect: false,
            },
            {
              answerText: "Stop the customer and if they try to leave the premises call the police as a drive off",
              isCorrect: true,
            },
            {
                answerText: "Call my manager, I don't know how to deal with this",
                isCorrect: false,
              },
            ],
        },
        {
            questionText:
              "What do you do if the customer cannot verify their email address?",
            answerOptions: [
              {
                answerText: "Nothing, they don't need to verify the email address",
                isCorrect: false,
              },
              {
                answerText: "My manager will know, they are very clever",
                isCorrect: false,
              },
              {
                  answerText: "I will click on the question mark to get more help",
                  isCorrect: true,
                },
              ],
          },
          {
            questionText:
              "Is it obligatory for the customer to sign the contract?",
            answerOptions: [
              {
                answerText: "No, it doesn't have any important information",
                isCorrect: false,
              },
              {
                answerText: "Only if they want",
                isCorrect: false,
              },
              {
                  answerText: "Yes, this will explain to them the procedure if they don't pay on time",
                  isCorrect: true,
                },
              ],
          },
      ];

      const handleAnswerOptionClick = (answerOption, question) => {
        const findQuestion = (element) => element.questionText == question;
        var index = questions.findIndex(findQuestion);
        const found = questions[index].answerOptions.find(
          (element) => element.isCorrect == true
        );
        if (answerOption.isCorrect) {
          setScore(score + 1);
          setCorrectAnswer(true)
          setTimeout(() => {
            setCorrectAnswer(false)
            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < questions.length) {
              setCurrentQuestion(nextQuestion);
            } else {
                props.changeAnswered()
            }
          },2500)
        } else {
            setWrongAnswer(true)
            setTimeout(() => {
                setWrongAnswer(false)
            },2500)
        }
      };
    
    

    return(
        <div className="question-quiz">
        <h4>{questions[currentQuestion].questionText}</h4>
        <div className="possibleAnswers">
          {questions[currentQuestion].answerOptions.map((answerOption) => (
            <button
              onClick={() =>
                handleAnswerOptionClick(
                  answerOption,
                  questions[currentQuestion].questionText
                )
              }
              style={{textAlign: "center"}}
            >
              {answerOption.answerText}
            </button>
          ))}
          {correctAnswer ? (
            <div className="correct">Well done! That's correct</div>
          ) : (
            <></>
          )}
          {wrongAnswer ? (
            <div className="incorrect">That's not right, try again</div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
}