import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function ManagerHome(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>CORE</h3>
      <div className="modules">
        <div className="module healthAndSafety">
          <img src={health} alt="ordering" onClick={() => navigate("/manager/ordering")}/>
          <div className="completion">
            <h2>Ordering</h2>
          </div>
        </div>
        <div className="module healthAndSafety">
          <img src={health} alt="critical" onClick={() => navigate("/manager/critical")}/>
          <div className="completion">
            <h2>Critical</h2>
          </div>
        </div>
        <div className="module healthAndSafety">
          <img src={health} alt="systems" onClick={() => navigate("/manager/systems")}/>
          <div className="completion">
            <h2>Systems</h2>
          </div>
        </div>
        <div className="module healthAndSafety">
          <img src={health} alt="key holder" onClick={() => navigate("/manager/keyholder")}/>
          <div className="completion">
            <h2>Key Holder</h2>
          </div>
        </div>
        <div className="module healthAndSafety">
          <img src={health} alt="subway" onClick={() => navigate("/manager/subway")}/>
          <div className="completion">
            <h2>Subway</h2>
          </div>
        </div>
        <div className="module healthAndSafety">
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/management/MANAGEMENT_TASKS.png" alt="management tasks" onClick={() => navigate("/manager/management-tasks")}/>
          <div className="completion">
            <h2>Management Tasks</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
