import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function StockFilling(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Stock Filling</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/retail/stock-filling/fridge")}>
          <img src="https://nitro-training.s3.eu-west-2.amazonaws.com/retail/home/restock.png" alt="fridge fresh rotation" />
          <div className="completion">
            <h2>Fridge Fresh Rotation</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/stock-filling/containers")}>
          <img src={health} alt="shown all containers" />
          <div className="completion">
            <h2>Shown All Containers</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/retail/stock-filling/damages")}>
          <img src={health} alt="damages" />
          <div className="completion">
            <h2>Damages</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
