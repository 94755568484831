import Header from "../../Header";
import "../module.scss";
import health from "../../../images/health&safety.webp";
import Footer from "../../Footer";
import { useNavigate } from "react-router-dom";


export default function Subway(props) {
    const navigate = useNavigate();

  return (
    <main className="moduleHome">
      <Header stop={props.stop}/>
      <h3>Subway</h3>
      <div className="modules">
        <div className="module healthAndSafety" onClick={() => navigate("/manager/subway/presentation")}>
          <img src={health} alt="presentation" />
          <div className="completion">
            <h2>Presentation</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/subway/service")}>
          <img src={health} alt="service" />
          <div className="completion">
            <h2>Service</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/subway/stock")}>
          <img src={health} alt="ordering stock" />
          <div className="completion">
            <h2>Ordering Stock</h2>
          </div>
        </div>
        <div className="module healthAndSafety" onClick={() => navigate("/manager/subway/revs")}>
          <img src={health} alt="revs" />
          <div className="completion">
            <h2>REVs</h2>
          </div>
        </div>
      </div>
      <Footer />
    </main>
  );
}
