import { useEffect } from "react";
import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";

const Droppable = ({ id, items }) => {

  useEffect(() => {

    // console.log(id + " " + items)

  }, [items])

  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy} >
      <ul className="droppable" ref={setNodeRef}>
        <h2>{id === "NonAllergens" ? "Non Allergens" : id}</h2>

        {items.map((item) => (
          <SortableItem key={item} id={item} />
        ))}
      </ul>

    </SortableContext>
  );
};

export default Droppable;
